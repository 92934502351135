import {Agreements as AgreementsTemplate} from "../../templates/Agreements"
import {AgreementCreateAndEdit} from "../../templates/Agreements/CreateAndEdit";
export function Agreements() {
    const pathname = window.location.pathname
    if (pathname.includes("/convenios/") && pathname !== "/convenios") {
        return <AgreementCreateAndEdit />
    }
    return (
        <AgreementsTemplate />
    )
}