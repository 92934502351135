import {Flex, SimpleGrid, Spinner} from "@chakra-ui/react";
import {Input} from "../../Input";
import {Control} from "react-hook-form";
import {Select} from "../../Select";
import {Button} from "../../Button";
import React, {useEffect, useState} from "react";
import {listProfiles} from "../../../services/users";

type SearchBoxProps = {
    control: Control<any>
    isEditing?: boolean
    isLoading?: boolean
    isReadOnly?: boolean
    isClient?: boolean
}

export function UserBox({control, isEditing, isLoading, isReadOnly = false}: SearchBoxProps) {
    const [profiles, setProfiles] = useState<any[]>([])
    const getProfiles = async () => {

        const response = await listProfiles()

        setProfiles(response?.data?.map((item: any) => {
            return {
                label: item?.name,
                value: item?.id
            }
        }))
    }

    useEffect(() => {
        getProfiles()
    }, [])
    return (
        <SimpleGrid columns={2} className={"flex-1 gap-2"}>
            <Select rules={{required: true}} options={profiles} control={control} name={'profile_id'} labelClassName={"flex-1 col-span-2"}
                   label={"Perfil *"}
            />
            <Input rules={{required: true}} isReadOnly={isReadOnly} control={control} name={'first_name'} labelClassName={"flex-1"}
                   label={"Nome *"}
            />
            <Input rules={{required: true}} isReadOnly={isReadOnly} control={control} name={'last_name'} labelClassName={"flex-1"}
                   label={"Sobrenome *"}
            />
            <Input rules={{required: true}} isReadOnly={isReadOnly} control={control} name={'email'} labelClassName={"flex-1"}
                   label={"E-mail *"}
            />
            <Input rules={{required: true}} isReadOnly={isReadOnly} control={control} name={'phone'} labelClassName={"flex-1"}
                   label={"Telefone *"}
            />
        </SimpleGrid>
    )
}