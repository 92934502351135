import cloneDeep from "clone-deep";

export const {format : currencyFormat} = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export const datetimeFormat = (datetime: string) => {
    return new Date(datetime).toLocaleString('pt-br')
}

export const formatSelectsToPayload = (payload: any) => {
    const formattedPayload = cloneDeep(payload)
    Object.keys(formattedPayload)?.map((key) => {
        if (formattedPayload[key]?.hasOwnProperty(`value`)) {
            formattedPayload[key] = formattedPayload[key]?.value
        }
        if (formattedPayload[key]?.hasOwnProperty(`file`)) {
            formattedPayload[key] = formattedPayload[key]?.file
        }
    })

    return formattedPayload
}
