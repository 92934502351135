import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
    MenuProps
} from '@chakra-ui/react'
import {FiMoreVertical} from "react-icons/fi"
import React, {ReactNode} from "react";

type DropdownMenuProps = {
    options: ({
        onClick?: () => void
        label: ReactNode
    } | undefined)[]
    className?: string
    buttonClassName?: string
    iconClassName?: string
    listClassName?: string
    color?: "white"
}

export function DropdownMenu({options, className, listClassName, buttonClassName, iconClassName, color}: DropdownMenuProps) {
    return (
        <>
            <Menu>
                <MenuButton className={`cursor-pointer ${buttonClassName ? buttonClassName : ``} ${color ? `fill-white text-white` : ``}`} onClick={(e) => e.stopPropagation()}>
                    <FiMoreVertical className={`${iconClassName ? iconClassName : ``}  `}/>
                </MenuButton>
                <MenuList className={`dark:!bg-neutral-900 border-none ${listClassName} z-50`} onClick={(e) => e.stopPropagation()}>
                    {options?.filter((option) => (option !== undefined))?.map((option) => {
                        return (
                            <MenuItem className={`
                                active:dark:!bg-neutral-800 
                                active:!bg-gray-200 
                                focus:dark:!bg-neutral-800 
                                focus:!bg-gray-200 
                                hover:dark:!bg-neutral-800 
                                hover:!bg-gray-200 
                                dark:!text-white 
                                !text-black 
                                border-none
                                text-sm
                                font-light
                                `}
                            onClick={option && option.onClick}
                            >{option && option.label}</MenuItem>
                        )
                    })}
                </MenuList>
            </Menu>
        </>
    )
}