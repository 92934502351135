import {SimpleGrid} from "@chakra-ui/react";
import {Input} from "../../components/Input";
import {Control, UseFormResetField} from "react-hook-form";
import {Select} from "../../components/Select";
type SearchBoxProps = {
    control: Control<any>
}
export function SearchBox({control}: SearchBoxProps) {
    const years = ["2010", "2011", "2012", "2013", "2014", "2015", "2016", "2017", "2018", "2019", "2020", "2021", "2022", "2023"]
    return (
        <SimpleGrid columns={2} className={"flex-1 gap-2"}>
            <Select control={control} labelClassName={"flex-1 col-span-2"} name={"year"} label={"Selecione um ano"}
                    placeholder={"Selecione um ano"} options={years?.reverse()?.map((item) => {
                return {
                    value: item,
                    label: item
                }
            })} />
            <Input control={control} name={'search_term'} labelClassName={"flex-1 col-span-2"}
                   placeholder={"Busque por alguma coisa"} label={"Busque por alguma coisa"}
            />
        </SimpleGrid>
    )
}