import {Accordion} from "../../../components/Accordion";
import {PaginationInfoProps, BalanceSheetProps} from "../../../types";
import {deleteBalanceSheet, getBalanceSheets} from "../../../services/balance-sheets";
import React, {useEffect, useState} from "react";
import {Control, FieldValues, useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {Badge, Flex, Stack, useDisclosure, useMediaQuery, useToast} from "@chakra-ui/react";
import {Text} from "../../../components/Text";
import {Link} from "../../../components/Link";
import {SearchBox} from "./searchBox";
import {storageURL} from "../../../services/axios";
import ReactPaginate from "react-paginate";
import {GrFormNext, GrFormPrevious, GrMore} from "react-icons/gr";

export function BalanceSheets() {
    const {onOpen: deleteDialogOnOpen, isOpen: deleteDialogIsOpen, onClose: deleteDialogOnClose} = useDisclosure()

    const [isMobile] = useMediaQuery('(max-width: 768px)')
    const [deleteId, setDeleteId] = useState(0)
    const toast = useToast()
    const navigate = useNavigate()
    const {
        control,
        watch
    } = useForm()
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfoProps>({
        per_page: 10,
        page: 1,
        last_page: 0,
        total: 0,
        to: 0
    })

    const [data, setData] = useState<BalanceSheetProps[]>([] as BalanceSheetProps[])

    useEffect(() => {
    }, [data])

    const getData = async (page?: number) => {
        const response = await getBalanceSheets({
            per_page: paginationInfo.per_page,
            page: page ? page : paginationInfo.page,
            active: 1,
            date: watch()?.year?.value ?? undefined
        })
        if (response.isAxiosError) {
            return
        }
        setData(response.data.data.map((balanceSheet: BalanceSheetProps) => {
            balanceSheet.created_at = new Date(balanceSheet.created_at).toLocaleString('pt-br')
            if (balanceSheet.updated_at) {
                balanceSheet.updated_at = new Date(balanceSheet.updated_at).toLocaleString('pt-br')
            }
            return balanceSheet
        }))
        setPaginationInfo(oldValue => {
            return {
                ...oldValue,
                last_page: response.data.last_page,
                total: response.data.total,
                to: response.data.to,
                page: response.data.current_page
            }
        })
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        watch((data, {name}) => {
            console.log({data, name})
            getData(1)
        })
    }, [])
    const onPageChange = (page: number) => {
        getData(page + 1)
    }
    return (
        <>
            <Accordion title={"Digite sua busca"} defaultExpanded={false} className={""}>
                <SearchBox control={control} />
            </Accordion>
            <Accordion title={"Prestação de contas"} panelClassName={`overflow-auto max-h-screen`}>
                <Stack spacing={0} className={`gap-2`}>
                    {data?.length < 1 && (
                        <Flex className={`bg-gray-100 dark:bg-neutral-800 p-2 gap-2 rounded-md `}>
                            <Text>Nenhuma prestação de contas na data selecionada</Text>
                        </Flex>
                    )}
                    {data?.map((balanceSheet, key) => {
                        return (
                            <Link onClick={(e) => {
                                e.preventDefault()
                                window.open(`${balanceSheet?.source}`)
                            }}>
                                <Flex
                                    className={`
                                    transition-all 
                                    bg-gray-100 
                                    dark:bg-neutral-800 
                                    p-2 
                                    gap-2 
                                    rounded-md 
                                    items-center 
                                    cursor-pointer 
                                    dark:hover:!bg-neutral-900 
                                    hover:!bg-gray-200
                                `}
                                >
                                    <Badge
                                        className={`!bg-blue-500 !text-white`}>{balanceSheet?.date}</Badge>
                                    <Text className={`!text-sm`}><b>{balanceSheet?.title}</b></Text>
                                </Flex>
                            </Link>
                        )
                    })}

                    {(!!paginationInfo?.last_page && paginationInfo?.last_page > 1) && (
                        <ReactPaginate
                            className={`flex justify-between items-center w-max p-2 !m-auto gap-2`}
                            pageLinkClassName={'h-8 w-8 min-w-max flex justify-center items-center rounded-md bg-gray-200 dark:bg-neutral-800 p-2'}
                            breakLabel={!isMobile && <GrMore/>}
                            nextLabel={<GrFormNext/>}
                            onPageChange={({selected}) => {
                                onPageChange(selected)
                            }}
                            pageRangeDisplayed={isMobile ? 3 : 5}
                            marginPagesDisplayed={isMobile ? 0 : 3}
                            pageCount={paginationInfo?.last_page}
                            previousLabel={<GrFormPrevious/>}
                            activeLinkClassName={`!bg-primary text-white`}
                        />
                    )}
                </Stack>
            </Accordion>
        </>
    )
}
