import {api} from "./axios";
import {LoginProps} from "../types";

export const userLogin = async (payload?: LoginProps) => {
    return await api.post(`/user-login`, payload).catch(({response, ...rest}) => {
        console.log({rest})
        return response
    })
}
export const forgotPasswordCode = async (payload?: LoginProps) => {
    return await api.post(`/user-forgot-password-code`, payload).catch(({response, ...rest}) => {
        console.log({rest})
        return response
    })
}
export const forgotPasswordChange = async (payload?: LoginProps) => {
    return await api.post(`/user-forgot-password-change`, payload).catch(({response, ...rest}) => {
        console.log({rest})
        return response
    })
}