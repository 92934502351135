import {ColumnProps} from "../../types/table";
import {Badge, Flex} from "@chakra-ui/react";
import {AiFillCloseCircle, AiFillDelete, AiFillEdit} from "react-icons/ai";
import React from "react";
import {DropdownMenu} from "../../components/DropdownMenu";
import {Switch} from "../../components/Switch";
type TableColumnsProps = {
    onDelete: (userId: number) => void
    onEdit: (userId: number) => void
    onToggleActive: (user: any, active: boolean) => void
}
export const tableColumns = ({onDelete, onEdit, onToggleActive}: TableColumnsProps): ColumnProps[] => {
    return [
        {
            header: "AÇÕES",
            cell: (info) => {
                return (
                        <DropdownMenu options={[
                            {
                                label: <Flex className={`cursor-pointer gap-2`} onClick={() => onEdit(info.row.original.id)} ><AiFillEdit /> Editar</Flex>
                            },
                            {
                                label: <Flex className={`cursor-pointer gap-2`} onClick={() => onDelete(info.row.original.id)} ><AiFillDelete /> Excluir</Flex>
                            }
                        ]} />


                )
            }
        },
        {
            id: "active",
            header: "ATIVO",
            cell: (info) => {
                const active = (info.row.original.status_id === 1)
                console.log({active})
                return (
                    <Switch name={'active'} labelClassName={"flex-1"} defaultChecked={active} onChange={(e) => {
                        onToggleActive(info.row.original, e?.target?.checked)
                    }}
                    />
                )
            }
        },
        {
            id: "id",
            header: "ID"
        },
        {
            id: "profile_id",
            header: "PERFIL",
            cell: (info) => {
                return (
                    <Badge className={`!bg-primary !text-white `}>{(info.row.original?.profile?.name!)}</Badge>
                )
            }
        },
        {
            id: "first_name",
            header: "NOME",
            cell: (info) => {
                return `${info.renderValue()} ${info.row.original.last_name}`
            }
        },
        {
            id: "email",
            header: "E-MAIL"
        },
        {
            id: "phone",
            header: "TELEFONE"
        },
        {
            id: "created_at",
            header: "CRIADO EM"
        },
        {
            id: "updated_at",
            header: "ATUALIZADO EM"
        }
    ]
}