import {ColumnProps} from "../../types/table";
import {Badge, Flex} from "@chakra-ui/react";
import {AiFillCloseCircle, AiFillDelete, AiFillEdit} from "react-icons/ai";
import React from "react";
import {DropdownMenu} from "../../components/DropdownMenu";
import {Text} from "../../components/Text";
import {LineClamp} from "../../components/LineClamp";
import {Switch} from "../../components/Switch";
import {Link} from "../../components/Link";
import {datetimeFormat} from "../../helpers";
type TableColumnsProps = {
    onDelete: (assemblyId: number) => void
    onEdit: (assemblyId: number) => void
    onToggleLive: (assembly: any, live: boolean) => void
    onToggleActive: (assembly: any, active: boolean) => void
}
export const tableColumns = ({onDelete, onEdit, onToggleLive, onToggleActive}: TableColumnsProps): ColumnProps[] => {
    return [
        {
            header: "AÇÕES",
            cell: (info) => {
                return (
                        <DropdownMenu options={[
                            {
                                label: <Flex className={`cursor-pointer gap-2`} onClick={() => onEdit(info.row.original.id)} ><AiFillEdit /> Editar</Flex>
                            },
                            {
                                label: <Flex className={`cursor-pointer gap-2`} onClick={() => onDelete(info.row.original.id)} ><AiFillDelete /> Excluir</Flex>
                            }
                        ]} />


                )
            }
        },
        {
            id: "live",
            header: "AO VIVO",
            cell: (info) => {
                const live = !!info.row.original.live
                console.log({live})
                return (
                    <Switch name={'live'} labelClassName={"flex-1"} defaultChecked={live} onChange={(e) => {
                        onToggleLive(info.row.original, e?.target?.checked)
                    }}
                    />
                )
            }
        },
        {
            id: "active",
            header: "ATIVO",
            cell: (info) => {
                const active = !!info.row.original.active
                console.log({active})
                return (
                    <Switch name={'active'} labelClassName={"flex-1"} defaultChecked={active} onChange={(e) => {
                        onToggleActive(info.row.original, e?.target?.checked)
                    }}
                    />
                )
            }
        },
        {
            id: "id",
            header: "ID"
        },
        {
            id: "chat_id",
            header: "CHAT",
            cell: (info) => {
                const chatId = info.row.original?.chat_id
                return (
                    <Badge className={`!bg-primary !text-white cursor-pointer`}>{chatId}</Badge>
                )
            }
        },
        {
            id: "title",
            header: "TÍTULO",
            cell: (info) => (
                <LineClamp>{info.row.original.title}</LineClamp>
            )
        },
        {
            id: "slug",
            header: "SLUG"
        },
        {
            id: "description",
            header: "DESCRIÇÃO",
            cell: (info) => (
                <LineClamp>{info.row.original?.description?.replace( /(<([^>]+)>)/ig, '')}</LineClamp>
            )
        },
        {
            id: "url_video",
            header: "VÍDEO",
            cell: (info) => (
                <Link href={info.row.original.url_video} target={`_blank`}><LineClamp>{info.row.original.url_video}</LineClamp></Link>
            )
        },
        {
            id: "date",
            header: "DATA",
            cell: info => datetimeFormat(info.renderValue() as string)
        },
        {
            id: "created_at",
            header: "CRIADO EM",
            cell: info => datetimeFormat(info.renderValue() as string)
        },
        {
            id: "updated_at",
            header: "ATUALIZADO EM",
            cell: info => datetimeFormat(info.renderValue() as string)
        }
    ]
}