import YouTube from 'react-youtube';
import {Flex} from "@chakra-ui/react";
type VideoProps = {
    url: string
}
export function Video({url}: VideoProps) {
    const videoId = (new URLSearchParams(url?.split(`?`)[1])).get("v")
    console.log({videoId})

    return (
        <Flex className={`flex-1 rounded-md overflow-hidden p-2`}>
            {videoId && (
                <YouTube
                    videoId={videoId}                  // defaults -> ''
                    className={`min-w-full min-h-full max-w-full`}                // defaults -> ''
                    iframeClassName={`min-w-full min-h-full max-w-full rounded-md`}
                />
            )}
            {!videoId && (
                <video className={`min-w-full min-h-full max-w-full`} controls width="320" height="240" >
                    <source src={url} type="video/mp4"/>
                </video>
            )}
        </Flex>
    )
}