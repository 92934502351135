import {ColumnProps} from "../../types/table";
import {Badge, Flex} from "@chakra-ui/react";
import {AiFillCloseCircle, AiFillDelete, AiFillEdit} from "react-icons/ai";
import React from "react";
import {DropdownMenu} from "../../components/DropdownMenu";
import {Switch} from "../../components/Switch";
import {storageURL} from "../../services/axios";
import {Link} from "../../components/Link";
import {Text} from "../../components/Text";
type TableColumnsProps = {
    onDelete: (agreementId: number) => void
}
export const tableColumns = ({onDelete}: TableColumnsProps): ColumnProps[] => {
    return [
        {
            header: "AÇÕES",
            cell: (info) => {
                return (
                    <DropdownMenu options={[
                        {
                            label: <Flex className={`cursor-pointer gap-2`} onClick={() => onDelete(info.row.original.id)} ><AiFillDelete /> Excluir</Flex>
                        }
                    ]} />


                )
            }
        },
        {
            id: "id",
            header: "ID"
        },
        {
            id: "title",
            header: "TÍTULO"
        },
        {
            id: "date",
            header: "ANO",
            cell: (info) => {
                return (
                    <Badge className={`!bg-primary !text-white`}>{`${info?.renderValue()}`}</Badge>
                )
            }
        },
        {
            id: "source",
            header: "DOCUMENTO",
            cell: (info) => {
                return (
                    <Text className={`cursor-pointer`} onClick={() => window.open(`${info?.renderValue()}`)}>{`${storageURL}/${info?.renderValue()}`}</Text>
                )
            }
        },
        {
            id: "created_at",
            header: "CRIADO EM"
        },
        {
            id: "updated_at",
            header: "ATUALIZADO EM"
        }
    ]
}
