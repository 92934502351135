import {
    Input as ChakraUiInput,
    InputGroup,
    InputLeftAddon,
    InputProps as ChakraUiInputProps
} from '@chakra-ui/react'
import {Control, useController, UseControllerProps, useForm} from "react-hook-form";
import {useEffect} from "react";
import {Text} from "../Text"
type InputProps = (ChakraUiInputProps & Partial<UseControllerProps>) & {
    label?: string
    labelClassName?: string
    name?: string
    mask?: "BRL" | "USD" | "%"
    inputRef?: any
}
export function Input({label, labelClassName, inputRef, name = "", mask,  ...input}: InputProps) {
    const {control: controlDefault} = useForm()
    const {fieldState, field} = useController({
        control: input.control || controlDefault,
        rules: input.rules,
        name: name,
        defaultValue: input.defaultValue
    })

    // const maskAsBRL = (value: string) => {
    //
    //     value = value.replaceAll((/!?(\D)/g), '').replaceAll(".", "").replaceAll(",", ".")
    //     console.log({value})
    //     const formattedValue = Number(value).toLocaleString("pt-BR", {
    //         minimumFractionDigits: 2 , currency: 'BRL'
    //     })
    //
    //     return formattedValue
    // }

    return (
        <>
            <label className={`flex flex-col ${labelClassName ? labelClassName : ``}`}>
                {label && (
                    <Text className={"mb-2 line-clamp-1"}>{label}</Text>
                )}
                <InputGroup>
                    {mask && (
                        <InputLeftAddon className={`dark:bg-neutral-800 border !border-gray-300`} children={mask} />
                    )}
                <ChakraUiInput
                    {...input}
                    {...field}
                    ref={inputRef}
                    type={mask ? "number" : input.type}
                    className={`!border-gray-300 p-1 ${!!input.className && input.className} placeholder:text-black dark:placeholder:text-white ${fieldState.error && `!border-primary`}`}
                    onChange={(e) => {
                        // console.log({selection: window?.getSelection()?.toString()})
                        // if (e.target.value && e.target.value !== "") {
                        //     if (mask === "BRL") {
                        //         e.target.value = maskAsBRL(e.target.value)
                        //     }
                        // }

                        if (input.onChange) {
                            input.onChange(e)
                        }
                        field.onChange(e)
                    }}
                    _active={{borderColor: `#0B71B4 !important`}}
                    _focus={{borderColor: `#0B71B4 !important`}}
                    _focusVisible={{borderColor: `#0B71B4 !important`}}
                    _invalid={{borderColor: `#E53E3E !important`}}
                    {...(fieldState?.error ? {isInvalid: true} : {})}
                />
                </InputGroup>
                {fieldState?.error?.message && (
                    <Text className={"mt-1 line-clamp-1 !text-[#E53E3E] text-sm"}>{fieldState?.error?.message}</Text>
                )}
            </label>
        </>
    )
}