import {Accordion} from "../../components/Accordion";
import {PaginationInfoProps, AssemblyProps} from "../../types";
import { Table } from "../../components/Table";
import {tableColumns} from "./tableColumns"
import {deleteAssembly, getAssemblies, updateAssembly} from "../../services/assemblies";
import React, {useEffect, useState} from "react";
import {Control, FieldValues, set, useForm} from "react-hook-form";
import {SearchBox} from "./searchBox";
import {NewItemBox} from "../../components/NewItemBox";
import {useNavigate} from "react-router-dom";
import {useDisclosure, useToast} from "@chakra-ui/react";
import {Dialog} from "../../components/Dialog";
import {debounce} from "debounce";

export function Assemblies() {
    const {onOpen: deleteDialogOnOpen, isOpen: deleteDialogIsOpen, onClose: deleteDialogOnClose} = useDisclosure()
    const [deleteId, setDeleteId] = useState(0)
    const toast = useToast()
    const navigate = useNavigate()
    const {
        control,
        watch
    } = useForm()
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfoProps>({
        per_page: 10,
        page: 1,
        last_page: 0,
        total: 0,
        to: 0
    })

    const [data, setData] = useState<AssemblyProps[]>([] as AssemblyProps[])

    useEffect(() => {
    }, [data])

    const getData = debounce(async (page?: number) => {
        const response = await getAssemblies({
            per_page: paginationInfo.per_page,
            page: page ? page : paginationInfo.page,
            ...watch()
        })
        if (response.isAxiosError) {
            return
        }
        setData(response.data.data.map((assembly: AssemblyProps) => {
            return assembly
        }))
        setPaginationInfo(oldValue => {
            return {
                ...oldValue,
                last_page: response.data.last_page,
                total: response.data.total,
                to: response.data.to,
                page: response.data.current_page
            }
        })
    }, 750)
    const onPageChange = (page: number) => {
        getData(page + 1)
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        watch((data, {name}) => {
            getData(1)
        })
    }, [])
    const handleEdit = (assemblyId: number) => {
        return navigate(`/assembleias/${assemblyId}`)
    }

    const handleToggleOptions = async (assembly: any, value: boolean, option: string) => {
        const payload: any = {
            ...assembly,
            create_chat: false,
            chat: undefined
        }
        payload[option] = value
        if (option === "active" && !value) {
            payload["live"] = 0
            setData(prev => {
                return prev.map((item) => {
                    if (item?.id === assembly.id) {
                        item.live = 0
                        item.active = 0
                    }
                    return item
                })
            })
        }
        if (option === "live" && value) {
            payload["active"] = 1
            setData(prev => {
                return prev.map((item) => {
                    if (item?.id === assembly.id) {
                        item.active = 1
                        item.live = 1
                    }
                    return item
                })
            })
        }
        const response = await updateAssembly(assembly.id, payload)

        if (response?.status !== 200) {
            return toast({
          position: 'top-right',
                status: "error",
                title: "Erro ao atualizar assembleia",
                description: response.data.message || "Houve um erro ao atualizar assembleia, por favor tente novamente",
                isClosable: true
            })
        }
        return toast({
          position: 'top-right',
            status: "success",
            title: "Assembleia atualizada com sucesso",
            isClosable: true
        })
    }
    const handleDelete = async () => {
        const response = await deleteAssembly(deleteId)
        if (response?.status !== 204) {
            return toast({
          position: 'top-right',
                status: "error",
                title: "Erro ao deletar assembleia",
                description: response.data.message || "Houve um erro deletar assembleia, por favor tente novamente",
                isClosable: true
            })
        }
        getData()
        return toast({
          position: 'top-right',
            status: "success",
            title: "Assembleia deletada com sucesso",
            description: response.data.message || "A assembleia foi deletada com sucesso",
            isClosable: true
        })
    }
    return (
        <>
            <Dialog
                onAccept={handleDelete}
                isOpen={deleteDialogIsOpen}
                onClose={deleteDialogOnClose}
                title={"Deletar assembleia"}
                description={"Ao confirmar, a assembleia será deletada"}
            />
            <NewItemBox title={"Nova assembleia"} className={""} onClick={() => navigate(`/assembleias/cadastro`)} />
            <Accordion title={"Digite sua busca"} defaultExpanded={false} className={""}>
                <SearchBox control={control} />
            </Accordion>
            <Accordion title={"Assembleias"} panelClassName={`overflow-auto max-h-screen`}>
                <Table data={data} columns={tableColumns({
                    onEdit: handleEdit,
                    onDelete:(assemblyId) => {
                        setDeleteId(assemblyId)
                        deleteDialogOnOpen()
                    },
                    onToggleLive: (assembly, live) => handleToggleOptions(assembly, live, 'live'),
                    onToggleActive: (assembly, live) => handleToggleOptions(assembly, live, 'active')
                })} onPageChange={onPageChange} pageCount={paginationInfo.last_page} />
            </Accordion>
        </>
    )
}