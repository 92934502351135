import {ColumnProps} from "../../types/table";
import {Badge, Flex} from "@chakra-ui/react";
import {AiFillCloseCircle, AiFillDelete, AiFillEdit} from "react-icons/ai";
import React from "react";
import {DropdownMenu} from "../../components/DropdownMenu";
import {Switch} from "../../components/Switch";
type TableColumnsProps = {
    onDelete: (affiliateId: number) => void
    onEdit: (affiliateId: number) => void
    onToggleActive: (affiliate: any, active: boolean) => void
}
export const tableColumns = ({onDelete, onEdit, onToggleActive}: TableColumnsProps): ColumnProps[] => {
    return [
        {
            header: "AÇÕES",
            cell: (info) => {
                return (
                        <DropdownMenu options={[
                            {
                                label: <Flex className={`cursor-pointer gap-2`} onClick={() => onEdit(info.row.original.id)} ><AiFillEdit /> Editar</Flex>
                            },
                            {
                                label: <Flex className={`cursor-pointer gap-2`} onClick={() => onDelete(info.row.original.id)} ><AiFillDelete /> Excluir</Flex>
                            }
                        ]} />


                )
            }
        },
        {
            id: "active",
            header: "ATIVO",
            cell: (info) => {
                const active = info.row.original.status_id === 1
                return (
                    <Switch name={'active'} labelClassName={"flex-1"} defaultChecked={active} onChange={(e) => {
                        onToggleActive(info.row.original, e?.target?.checked)
                    }}
                    />
                )
            }
        },
        {
            id: "id",
            header: "ID"
        },
        {
            id: "affiliated",
            header: "FILIADO",
            cell: (info) => {
                const affiliated = info.row.original.affiliated === 1
                return (
                    <Badge className={`${affiliated ? `!bg-primary !text-white`: `!bg-red-500 !text-white`}`}>{affiliated ? `SIM` : `NÃO`}</Badge>
                )
            }
        },
        {
            id: "registration",
            header: "MATRÍCULA"
        },
        {
            id: "first_name",
            header: "NOME",
            cell: (info) => {
                return `${info.renderValue()} ${info.row.original.last_name}`
            }
        },
        {
            id: "email",
            header: "E-MAIL"
        },
        {
            id: "phone",
            header: "TELEFONE"
        },
        {
            id: "cpf",
            header: "CPF"
        },
        {
            id: "rg",
            header: "RG"
        },
        {
            id: "created_at",
            header: "CRIADO EM"
        },
        {
            id: "updated_at",
            header: "ATUALIZADO EM"
        }
    ]
}