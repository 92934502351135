import {api} from "./axios";

export const createChatMessage = async (chatId: number, payload: any) => {
    return await api.post(`/chat-message-register/${chatId}`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const getChatMessages = async (chatId: number, params: any) => {
    return await api.get(`/chat-message-list/${chatId}`, {
        params
    }).catch(({response}) => {
        return response
    })
}
export const deleteMessage = async (chatId: number, messageId: number) => {
    return await api.delete(`/chat-message-delete/${chatId}/${messageId}`).catch(({response}) => {
        return response
    })
}