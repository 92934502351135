import {Boards as BoardsTemplate} from "../../templates/Boards"
import {BoardCreateAndEdit} from "../../templates/Boards/CreateAndEdit";
export function Boards() {
    const pathname = window.location.pathname
    if (pathname.includes("/diretoria/") && pathname !== "/diretoria") {
        return <BoardCreateAndEdit />
    }
    return (
        <BoardsTemplate />
    )
}