import {api} from "./axios";

export const createAddress = async (payload?: any) => {
    return await api.post(`/address`, payload, {
    }).catch(({data}) => {
        return data
    })
}
export const getAddressById = async (addressId?: number) => {
    return await api.get(`/address/${addressId}`, {
    }).catch(({data}) => {
        return data
    })
}
export const getAddressByCoordinates = async (coordinates: {latitude: number, longitude: number}) => {
    return await api.get(`/address/coord`, {
        params: coordinates
    }).catch(({data}) => {
        return data
    })
}
export const listCountries = async () => {
    return await api.get(`/address-country-list`).catch(({data}) => {
        return data
    })
}
export const listStates = async (countryId = 31) => {
    return await api.get(`/address-state-list/${countryId}`).catch(({data}) => {
        return data
    })
}
export const listCities = async (stateId: number) => {
    return await api.get(`/address-city-list/${stateId}`).catch(({data}) => {
        return data
    })
}