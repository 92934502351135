import {Notices as NoticesTemplate} from "../../templates/Notices"
import {NoticeCreateAndEdit} from "../../templates/Notices/CreateAndEdit";
export function Notices() {
    const pathname = window.location.pathname
    if (pathname.includes("/noticias/") && pathname !== "/noticias") {
        return <NoticeCreateAndEdit />
    }
    return (
        <NoticesTemplate />
    )
}