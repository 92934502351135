import {Box, BoxProps, Spinner, Text} from "@chakra-ui/react";
import {BsCheckLg} from "react-icons/bs"
import React from "react";

type ItemProps = BoxProps & {
    icon: React.ReactNode
    title?: string
    active?: boolean
    loading?: boolean
    disabled?: boolean
    selected?: boolean
}

export function Item({icon, title, active, loading, disabled, selected, ...rest}: ItemProps) {
    return (
        <Box {...rest} className={`flex flex-row items-center ${title ? `gap-2` : `justify-center`} p-2 rounded-md hover:cursor-pointer border hover:border-primary dark:hover:text-white ${active && `border-primary bg-primary text-white`} ${disabled && `bg-gray-200 dark:bg-neutral-800`}`}>
            <span className={`flex flex-row `}>
                {loading ? (
                    <Spinner size={"sm"}/>
                ) : icon}
            </span>
            {title && (
                <Text className={`whitespace-nowrap overflow-hidden overflow-ellipsis text-ellipsis`}>
                    {title}
                </Text>
            )}
            <span className={`flex flex-row gap-2`}>
                {selected && (
                    <BsCheckLg />
                )}
            </span>
        </Box>
    )
}