import {Affiliates as AffiliatesTemplate} from "../../templates/Affiliates"
import {AffiliateCreateAndEdit} from "../../templates/Affiliates/CreateAndEdit";
export function Affiliates() {
    const pathname = window.location.pathname
    if (pathname.includes("/afiliados/") && pathname !== "/afiliados") {
        return <AffiliateCreateAndEdit />
    }
    return (
        <AffiliatesTemplate />
    )
}