import {Assemblies as AssembliesTemplate} from "../../templates/Assemblies"
import {AssemblyCreateAndEdit} from "../../templates/Assemblies/CreateAndEdit";
import {Assemblies as ClientAssemblies} from "../../templates/Client/Assemblies"
import {AssemblySlug} from "../../templates/Client/Assemblies/Slug";
export function Assemblies() {
    const pathname = window.location.pathname
    console.log({pathname})
    if (pathname === "/cliente/assembleias" || pathname === "/cliente/assembleias/") {
        return <ClientAssemblies />
    }
    if (pathname.includes("/cliente/assembleias") && pathname !== "/cliente/assembleias/") {
        return <AssemblySlug />
    }
    if (pathname.includes("/assembleias/") && pathname !== "/assembleias") {
        return <AssemblyCreateAndEdit />
    }
    return (
        <AssembliesTemplate />
    )
}