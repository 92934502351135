import {useParams} from "react-router-dom";
import {getAssemblyBySlug} from "../../../../services/assemblies";
import React, {useEffect, useState} from "react";
import {Accordion} from "../../../../components/Accordion";
import {Badge, Box, Flex, Stack} from "@chakra-ui/react";
import {Text} from "../../../../components/Text";
import {Link} from "../../../../components/Link";
import {Chat} from "../../../../components/Chat";
import {Video} from "../../../../components/Video";
import {FaFileAlt} from "react-icons/fa";
import {AiFillCloseCircle} from "react-icons/ai";
import {storageURL} from "../../../../services/axios";

export function AssemblySlug () {
    const params = useParams()
    const assemblySlug = String(params.assemblySlug)
    const [assembly, setAssembly] = useState<any>({})

    const getAssembly = async (slug: string) => {
        const response = await getAssemblyBySlug(slug)
        console.log({response})
        setAssembly(response?.data?.assembly)
    }

    useEffect(() => {
        if (assemblySlug) {
            getAssembly(assemblySlug)
        }
    }, [assemblySlug])
    console.log({assembly})

    function downloadFile(url: string, fileName: string){
        fetch(url, { method: 'get', referrerPolicy: 'no-referrer' })
            .then(res => res.blob())
            .then(res => {
                console.log({res})
                const aElement = document.createElement('a');
                aElement.setAttribute('download', fileName);
                const href = URL.createObjectURL(res);
                aElement.href = href;
                // aElement.setAttribute('href', href);
                aElement.setAttribute('target', '_blank');
                aElement.click();
                URL.revokeObjectURL(href);
                aElement.remove()
            })
            .catch((err) => {
                console.log({err})
            });
    };
    const handleDownloadAttachment = (attachment: any) => {
        const url = `${storageURL}/${attachment?.source}`
        downloadFile(url, attachment?.name)
    }
    return (
        <Flex className={`w-full gap-5 flex-wrap md:flex-nowrap`}>
            <Accordion
                title={
                    <Flex className={`gap-2 items-center`}>
                        <Badge className={`!bg-red-500 !text-white`}>AO VIVO</Badge>
                        <Badge className={`!bg-primary !text-white`}>{new Date(assembly?.date)?.toLocaleDateString()}</Badge>
                        <Text>{assembly?.title}</Text>
                    </Flex> as any
                }
                className={`h-max w-full md:w-8/12`}
                panelClassName={`overflow-auto max-h-screen`}
            >
                <Stack spacing={0} className={`gap-2`}>
                    <div className={`flex bg-black !h-auto rounded-md w-full`}>
                        <Video url={assembly?.url_video} />
                    </div>
                    <div dangerouslySetInnerHTML={{__html: assembly?.description}} />
                </Stack>
            </Accordion>
            <Stack spacing={0} className={`gap-5 min-w-[300px] w-full md:w-4/12`}>
                <Chat chatId={assembly?.chat_id} />
                {assembly?.attachments?.length > 0 && (
                    <Accordion
                        title={"Documentos"}
                        className={`w-full`}
                        panelClassName={`overflow-auto max-h-screen`}
                    >
                        <Stack spacing={0} className={`gap-2`}>
                            {assembly?.attachments?.map((attachment: any, key: number) => {
                                return (
                                    <Box key={key} onClick={() => handleDownloadAttachment(attachment)}
                                         className={`bg-gray-300 dark:bg-neutral-800 rounded-md flex justify-center relative cursor-pointer hover:opacity-80`}>
                                        <Flex className={`items-center gap-2 p-2`}>
                                            <FaFileAlt size={30} />
                                            <Text className={`!text-sm`}>{attachment?.name}</Text>
                                        </Flex>
                                    </Box>
                                )
                            })}
                        </Stack>
                    </Accordion>
                )}
            </Stack>
        </Flex>
    )
}