import {Accordion} from "../../components/Accordion";
import {PaginationInfoProps, UserProps} from "../../types";
import { Table } from "../../components/Table";
import {tableColumns} from "./tableColumns"
import {deleteUser, getUsers, updateUser} from "../../services/users";
import React, {useEffect, useState} from "react";
import {Control, FieldValues, useForm} from "react-hook-form";
import {SearchBox} from "./searchBox";
import {NewItemBox} from "../../components/NewItemBox";
import {useNavigate} from "react-router-dom";
import {useDisclosure, useToast} from "@chakra-ui/react";
import {Dialog} from "../../components/Dialog";
import {updateAssembly} from "../../services/assemblies";
import {debounce} from "debounce";

export function Users() {
    const {onOpen: deleteDialogOnOpen, isOpen: deleteDialogIsOpen, onClose: deleteDialogOnClose} = useDisclosure()
    const [deleteId, setDeleteId] = useState(0)
    const toast = useToast()
    const navigate = useNavigate()
    const {
        control,
        watch
    } = useForm()
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfoProps>({
        per_page: 10,
        page: 1,
        last_page: 0,
        total: 0,
        to: 0
    })

    const [data, setData] = useState<UserProps[]>([] as UserProps[])

    useEffect(() => {
    }, [data])

    const getData = debounce(async (page?: number) => {
        const response = await getUsers({
            per_page: paginationInfo.per_page,
            page: page ? page : paginationInfo.page,
            ...watch()
        })
        if (response.isAxiosError) {
            return
        }
        setData(response.data.data.map((user: UserProps) => {
            user.created_at = new Date(user.created_at).toLocaleString('pt-br')
            if (user.updated_at) {
                user.updated_at = new Date(user.updated_at).toLocaleString('pt-br')
            }
            return user
        }))
        setPaginationInfo(oldValue => {
            return {
                ...oldValue,
                last_page: response.data.last_page,
                total: response.data.total,
                to: response.data.to,
                page: response.data.current_page
            }
        })
    }, 750)
    const onPageChange = (page: number) => {
        getData(page + 1)
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        watch((data, {name}) => {
            getData(1)
        })
    }, [])
    const handleEdit = (userId: number) => {
        return navigate(`/usuarios/${userId}`)
    }
    const handleDelete = async () => {
        const response = await deleteUser(deleteId)
        if (response?.status !== 204) {
            return toast({
          position: 'top-right',
                status: "error",
                title: "Erro ao deletar usuário",
                description: response.data.message || "Houve um erro deletar o usuário, por favor tente novamente",
                isClosable: true
            })
        }
        getData()
        return toast({
          position: 'top-right',
            status: "success",
            title: "Usuário deletado com sucesso",
            description: response.data.message || "O usuário foi deletado com sucesso",
            isClosable: true
        })
    }
    const handleToggleOptions = async (user: any, value: any, option: string) => {
        const payload: any = {
            ...user
        }
        payload[option] = value
        const response = await updateUser(user.id, payload)

        if (response?.status !== 200) {
            return toast({
                position: 'top-right',
                status: "error",
                title: "Erro ao atualizar usuário",
                description: response.data.message || "Houve um erro ao atualizar usuário, por favor tente novamente",
                isClosable: true
            })
        }
        return toast({
            position: 'top-right',
            status: "success",
            title: "Usuário atualizado com sucesso",
            isClosable: true
        })
    }
    return (
        <>
            <Dialog
                onAccept={handleDelete}
                isOpen={deleteDialogIsOpen}
                onClose={deleteDialogOnClose}
                title={"Deletar usuário"}
                description={"Ao confirmar, o usuário será deletado"}
            />
            <NewItemBox title={"Novo usuário"} className={""} onClick={() => navigate(`/usuarios/cadastro`)} />
            <Accordion title={"Digite sua busca"} defaultExpanded={false} className={""}>
                <SearchBox control={control} />
            </Accordion>
            <Accordion title={"Usuários"} panelClassName={`overflow-auto max-h-screen`}>
                <Table data={data} columns={tableColumns({
                    onEdit: handleEdit,
                    onDelete:(userId) => {
                        setDeleteId(userId)
                        deleteDialogOnOpen()
                    },
                    onToggleActive: (user, active) => handleToggleOptions(user, active ? 1 : 2, 'status_id')
                })} onPageChange={onPageChange} pageCount={paginationInfo.last_page} />
            </Accordion>
        </>
    )
}