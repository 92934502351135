import {Badge, Flex, SimpleGrid, Spinner} from "@chakra-ui/react";
import {Input} from "../../Input";
import {Control} from "react-hook-form";
import {Select} from "../../Select";
import {Button} from "../../Button";
import React, {useState} from "react";
import {TextEditor} from "../../TextEditor";
import {MediaInput} from "../../MediaInput";
import {Text} from "../../Text";
import {Switch} from "../../Switch";
import {ImageUploader} from "../../ImageUploader";

type SearchBoxProps = {
    control: Control<any>
    isEditing?: boolean
    isLoading?: boolean
    isReadOnly?: boolean
    isClient?: boolean
}

export function NoticeBox({control, isEditing, isLoading, isReadOnly = false}: SearchBoxProps) {
    return (
        <SimpleGrid columns={2} className={"flex-1 gap-2"}>
            <Switch isReadOnly={isReadOnly} control={control} name={'active'} labelClassName={"flex-1 col-span-2"}
                   label={"Ativo *"}
            />
            <MediaInput onlyImage={true} rules={{required: true}} isReadOnly={isReadOnly} control={control} name={'image'} labelClassName={"flex-1 col-span-2"}
                   label={"Imagem principal *"} multiple={false}
            />
            <Input rules={{required: true}} isReadOnly={isReadOnly} control={control} name={'title'} labelClassName={"flex-1 col-span-2"}
                   label={"Título *"}
            />
            <ImageUploader  className={"flex-1 col-span-2"} />
            <TextEditor rules={{required: true}} isReadOnly={isReadOnly} control={control} name={'content'} labelClassName={"flex-1 col-span-2"}
                   label={"Conteúdo *"}
            />
        </SimpleGrid>
    )
}