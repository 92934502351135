import {
    Flex,
    InputGroup,
    InputLeftAddon,
    Switch as ChakraUiSwitch,
    SwitchProps as ChakraUiSwitchProps
} from '@chakra-ui/react'
import {Control, useController, UseControllerProps, useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import {Text} from "../Text"
type SwitchProps = (ChakraUiSwitchProps & Partial<UseControllerProps>) & {
    label?: string
    labelClassName?: string
    name?: string
    mask?: "BRL" | "USD" | "%"
}
export function Switch({label, labelClassName, name = "", mask,  ...input}: SwitchProps) {
    const [isChecked, setIsChecked] = useState(input.defaultChecked ?? false)
    const {control: controlDefault} = useForm()
    const {fieldState, field} = useController({
        control: input.control || controlDefault,
        rules: input.rules,
        name: name,
        defaultValue: input.defaultValue
    })

    // const maskAsBRL = (value: string) => {
    //
    //     value = value.replaceAll((/!?(\D)/g), '').replaceAll(".", "").replaceAll(",", ".")
    //     console.log({value})
    //     const formattedValue = Number(value).toLocaleString("pt-BR", {
    //         minimumFractionDigits: 2 , currency: 'BRL'
    //     })
    //
    //     return formattedValue
    // }


    useEffect(() => {
        if (input.defaultChecked) {
            setIsChecked(true)
        }
    }, [input.defaultChecked])


    useEffect(() => {
        setIsChecked(field.value)
    }, [field.value])




    return (
        <>
            <Flex className={`flex flex-row items-center gap-2 ${labelClassName ? labelClassName : ``}`}>
                <InputGroup className={`!w-max`}>
                    {mask && (
                        <InputLeftAddon className={`dark:bg-neutral-800 border !border-gray-300`} children={mask} />
                    )}
                <ChakraUiSwitch
                    {...input}
                    {...field}
                    isChecked={isChecked}
                    className={`!border-gray-300 p-1 ${!!input.className && input.className} placeholder:text-black dark:placeholder:text-white ${fieldState.error && `!border-primary`}`}
                    onChange={(e) => {
                        // console.log({selection: window?.getSelection()?.toString()})
                        // if (e.target.value && e.target.value !== "") {
                        //     if (mask === "BRL") {
                        //         e.target.value = maskAsBRL(e.target.value)
                        //     }
                        // }

                        if (input.onChange) {
                            input.onChange(e)
                        }
                        field.onChange(e)
                    }}
                    _active={{borderColor: `#0B71B4 !important`}}
                    _focus={{borderColor: `#0B71B4 !important`}}
                    _focusVisible={{borderColor: `#0B71B4 !important`}}
                    _invalid={{borderColor: `#E53E3E !important`}}
                    {...(fieldState?.error ? {isInvalid: true} : {})}
                />
                </InputGroup>
                {label && (
                    <Text className={"mr-2 line-clamp-2 min-w-max"}>{label}</Text>
                )}
                {fieldState?.error && (
                    <Text className={"mt-1 line-clamp-1 !text-[#E53E3E] text-sm"}>{fieldState?.error?.message}</Text>
                )}
            </Flex>
        </>
    )
}