import {ColumnProps} from "../../types/table";
import {Badge, Flex} from "@chakra-ui/react";
import {AiFillCloseCircle, AiFillDelete, AiFillEdit} from "react-icons/ai";
import React from "react";
import {DropdownMenu} from "../../components/DropdownMenu";
import {LineClamp} from "../../components/LineClamp";
import {Switch} from "../../components/Switch";
type TableColumnsProps = {
    onDelete: (eventId: number) => void
    onEdit: (eventId: number) => void
    onSeeGuests: (eventId: number) => void
    onToggleActive: (event: any, active: boolean) => void
    onToggleMenu: (event: any, active: boolean) => void
}
export const tableColumns = ({onDelete, onEdit, onSeeGuests, onToggleMenu, onToggleActive}: TableColumnsProps): ColumnProps[] => {
    return [
        {
            header: "AÇÕES",
            cell: (info) => {
                return (
                    <DropdownMenu options={[
                        {
                            label: <Flex className={`cursor-pointer gap-2`} onClick={() => onSeeGuests(info.row.original.id)} ><AiFillEdit /> Ver convidados</Flex>
                        },
                        {
                            label: <Flex className={`cursor-pointer gap-2`} onClick={() => onEdit(info.row.original.id)} ><AiFillEdit /> Editar</Flex>
                        },
                        {
                            label: <Flex className={`cursor-pointer gap-2`} onClick={() => onDelete(info.row.original.id)} ><AiFillDelete /> Excluir</Flex>
                        }
                    ]} />


                )
            }
        },
        {
            id: "active",
            header: "ATIVO",
            cell: (info) => {
                const active = !!info.row.original.active
                return (
                    <Switch name={'active'} labelClassName={"flex-1"} defaultChecked={active} onChange={(e) => {
                        onToggleActive(info.row.original, e?.target?.checked)
                    }}
                    />
                )
            }
        },
        {
            id: "show_on_menu",
            header: "MENU",
            cell: (info) => {
                const showOnMenu = !!info.row.original.show_on_menu
                return (
                    <Switch name={'show_on_menu'} labelClassName={"flex-1"} defaultChecked={showOnMenu} onChange={(e) => {
                        onToggleMenu(info.row.original, e?.target?.checked)
                    }}
                    />
                )
            }
        },
        {
            id: "id",
            header: "ID"
        },
        {
            id: "date",
            header: "DATA",
            cell: (info) => {
                return (
                    <Badge className={`!bg-primary !text-white `}>{new Date(info.row.original?.date!)?.toLocaleDateString()}</Badge>
                )
            }
        },
        {
            id: "title",
            header: "TÍTULO",
            cell: (info) => (
                <LineClamp>{info.row.original.title}</LineClamp>
            )
        },
        {
            id: "slug",
            header: "SLUG",
            cell: (info) => (
                <LineClamp>{info.row.original.slug}</LineClamp>
            )
        },
        {
            id: "content",
            header: "CONTEÚDO",
            cell: (info) => (
                <LineClamp>{info.row.original.content.replace( /(<([^>]+)>)/ig, '')}</LineClamp>
            )
        },
        {
            id: "created_at",
            header: "CRIADO EM"
        },
        {
            id: "updated_at",
            header: "ATUALIZADO EM"
        }
    ]
}
