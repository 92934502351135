import {Flex, SimpleGrid, Spinner} from "@chakra-ui/react";
import {Input} from "../../Input";
import {Control, UseFormReset, UseFormWatch} from "react-hook-form";
import {Select} from "../../Select";
import {Button} from "../../Button";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {CreatableSelect} from "../../CreatableSelect";
import {InputMask} from "../../InputMask";
import {
    getAffiliateBloodTypes,
    getAffiliateCivilStates, getAffiliateEducationDegrees,
    getAffiliateGenders,
    getAffiliateGroups,
    getAffiliateOffices,
    getAffiliateSpecialities
} from "../../../services/affiliates";
import {listCities, listStates} from "../../../services/address";
import cloneDeep from "clone-deep";

type SearchBoxProps = {
    control: Control<any>
    isEditing?: boolean
    isLoading?: boolean
    isReadOnly?: boolean
    isClient?: boolean
    values?: any
    watch: UseFormWatch<any>
    reset?: UseFormReset<any>
}

export function AffiliateBox({control, isEditing, reset, isLoading, isReadOnly = false, watch}: SearchBoxProps) {
    const [genders, setGenders] = useState<any>([])
    const [civilStates, setCivilStates] = useState<any>([])
    const [bloodTypes, setBloodTypes] = useState<any>([])
    const [educationDegrees, setEducationDegrees] = useState<any>([])
    const [groups, setGroups] = useState<any>([])
    const [offices, setOffices] = useState<any>([])
    const [specialities, setSpecialities] = useState<any>([])
    const firstCall = useRef(false)
    const firstCallWatch = useRef(false)


    const [states, setStates] = useState<any>([])
    const [cities, setCities] = useState<any>([])

    const booleanOptions = [
        {
            label: `Sim`,
            value: 1
        },
        {
            label: `Não`,
            value: 0
        }
    ]


    const getGenders = async () => {
        const response = await getAffiliateGenders()

        const formattedGenders = Object.keys(response?.data)?.map((key) => {
            return {
                label: key?.replaceAll("_", " "),
                value: response?.data[key]
            }
        })
        setGenders(formattedGenders)

        if (isEditing) {
            reset && reset({
                ...watch(),
                gender: formattedGenders?.find(gender => gender?.value === watch()?.gender)
            })
        }
    }
    const getCivilStates = async () => {
        const response = await getAffiliateCivilStates()

        const formattedCivilStates = Object.keys(response?.data)?.map((key) => {
            return {
                label: key?.replaceAll("_", " "),
                value: response?.data[key]
            }
        })

        setCivilStates(formattedCivilStates)

        if (isEditing) {
            reset && reset({
                ...watch(),
                civil_state: formattedCivilStates?.find(civilState => civilState?.value === watch()?.civil_state)
            })
        }
    }
    const getBloodTypes = async () => {
        const response = await getAffiliateBloodTypes()

        const formattedBloodTypes = Object.keys(response?.data)?.map((key) => {
            return {
                label: key?.replaceAll("_", " "),
                value: response?.data[key]
            }
        })

        setBloodTypes(formattedBloodTypes)

        if (isEditing) {
            reset && reset({
                ...watch(),
                blood_type: formattedBloodTypes?.find(bloodType => bloodType?.value === watch()?.blood_type)
            })
        }
    }
    const getEducationDegrees = async () => {
        const response = await getAffiliateEducationDegrees()

        const formattedEducationDegrees = Object.keys(response?.data)?.map((key) => {
            return {
                label: key?.replaceAll("_", " "),
                value: response?.data[key]
            }
        })
        setEducationDegrees(formattedEducationDegrees)

        if (isEditing) {
            reset && reset({
                ...watch(),
                education_degree: formattedEducationDegrees?.find(educationDegree => educationDegree?.value === watch()?.education_degree)
            })
        }
    }
    const getGroups = async () => {
        const response = await getAffiliateGroups()

        const formattedGroups = response?.data?.data?.map((group: any) => {
            return {
                label: group?.name.replaceAll("_", " "),
                value: group?.id
            }
        })
        setGroups(formattedGroups)

        if (isEditing) {
            reset && reset({
                ...watch(),
                group_id: formattedGroups?.find((group: any) => group?.value === watch()?.group_id)
            })
        }
    }
    const getOffices = async () => {
        const response = await getAffiliateOffices()

        const formattedOffices = response?.data?.data?.map((office: any) => {
            return {
                label: office?.name.replaceAll("_", " "),
                value: office?.id
            }
        })

        setOffices(formattedOffices)

        if (isEditing) {
            reset && reset({
                ...watch(),
                office_id: formattedOffices?.find((office: any) => office?.value === watch()?.office_id)
            })
        }
    }
    const getSpecialities = async () => {
        const response = await getAffiliateSpecialities()

        const formattedSpecialities = response?.data?.data?.map((speciality: any) => {
            return {
                label: speciality?.name.replaceAll("_", " "),
                value: speciality?.id
            }
        })

        setSpecialities(formattedSpecialities)

        if (isEditing) {
            reset && reset({
                ...watch(),
                speciality_id: formattedSpecialities?.find((speciality: any) => speciality?.value === watch()?.speciality_id)
            })
        }
    }

    const getCities = async (stateId: number) => {
        const response = await listCities(stateId)

        const formattedCities = response?.data?.cities?.map((city: any) => {
            return {
                label: city?.name.replaceAll("_", " "),
                value: city?.id
            }
        })

        setCities(formattedCities)
        if (isEditing) {
            reset && reset({
                ...watch(),
                birth_city_id: formattedCities?.find((city: any) => city?.value === watch()?.birth_city_id)
            })
        }
    }

    const getStates = async () => {
        const response = await listStates()

        const formattedStates = response?.data?.states?.map((state: any) => {
            return {
                label: state?.name.replaceAll("_", " "),
                value: state?.id
            }
        })

        setStates(formattedStates)

        if (isEditing) {
            getCities(watch()?.birth_state_id)
            reset && reset({
                ...watch(),
                birth_state_id: formattedStates?.find((state: any) => state?.value === watch()?.birth_state_id)
            })
        }
    }

    const watchCallback = useCallback(async (data :any, {name} :any) => {
        console.log({data, name, firstCall})

        if (name === `birth_state_id`) {
            const stateId = data[name]?.value
            getCities(stateId)
        }

        // const selectKeys: any = {
        //     "birth_state_id": states,
        //     "birth_city_id": cities
        // }
        //

    }, [firstCall])


    useEffect(() => {
        if (!isLoading && !firstCall?.current) {
            watch && watch((data, current) => watchCallback(data, current))
            getStates()
            getEducationDegrees()
            getBloodTypes()
            getCivilStates()
            getSpecialities()
            getOffices()
            getGroups()
            getGenders()
            firstCall.current = true
        }

    }, [isLoading])
    return (
        <SimpleGrid columns={2} className={"flex-1 gap-2"}>
            <Input isReadOnly={isReadOnly} control={control} name={'first_name'}
                   labelClassName={"flex-1"}
                   label={"Nome *"}
            />
            <Input isReadOnly={isReadOnly} control={control} name={'last_name'}
                   labelClassName={"flex-1"}
                   label={"Sobrenome *"}
            />
            <Input isReadOnly={isReadOnly} control={control} name={'email'}
                   labelClassName={"flex-1"}
                   label={"E-mail *"}
            />
            <Input isReadOnly={isReadOnly} control={control} name={'phone'}
                   labelClassName={"flex-1"}
                   label={"Telefone *"}
            />
            {/*<Input isReadOnly={isReadOnly} control={control} name={'email'} labelClassName={"flex-1"}*/}
            {/*       label={"E-mail (Será enviado a senha neste e-mail)"}*/}
            {/*/>*/}
            {/*<Input isReadOnly={isReadOnly} control={control} name={'phone'} labelClassName={"flex-1"}*/}
            {/*       label={"Telefone"}*/}
            {/*/>*/}
            <Select options={genders} control={control} name={'gender'} labelClassName={"flex-1"}
                    label={"Gênero *"}
            />
            <CreatableSelect options={groups} control={control} name={'group_id'} labelClassName={"flex-1"}
                    label={"Grupo *"}
            />
            <CreatableSelect options={offices} control={control} name={'office_id'} labelClassName={"flex-1"}
                    label={"Cargo *"}
            />
            <CreatableSelect options={specialities} control={control} name={'speciality_id'} labelClassName={"flex-1"}
                    label={"Especialidade *"}
            />
            <Input isReadOnly={isReadOnly} control={control} name={'registration'}
                   labelClassName={"flex-1"}
                   label={"Matrícula *"}
            />
            <Input isReadOnly={isReadOnly} control={control} name={'agefis_registration'} labelClassName={"flex-1"}
                   label={"Matrícula AGEFIS"}
            />
            {/*<Input isReadOnly={isReadOnly} control={control} name={'capacity'} labelClassName={"flex-1"}*/}
            {/*       label={"Lotação"}*/}
            {/*/>*/}
            <Input isReadOnly={isReadOnly} control={control} name={'cpf'}
                   labelClassName={"flex-1"}
                   label={"CPF *"}
            />
            <Input isReadOnly={isReadOnly} control={control} name={'rg'}
                   labelClassName={"flex-1"}
                   label={"RG *"}
            />
            <Input isReadOnly={isReadOnly} control={control} name={'rg_expedition_body'}
                   labelClassName={"flex-1"}
                   label={"Local de expedição do RG *"}
            />
            <Input isReadOnly={isReadOnly} type={`date`} control={control}
                   name={'rg_expedition_date'} labelClassName={"flex-1"}
                   label={"Data de expedição do RG *"}
            />
            <Input isReadOnly={isReadOnly} control={control} name={'mother_name'}
                   labelClassName={"flex-1"}
                   label={"Nome da mãe"}
            />
            <Input isReadOnly={isReadOnly} control={control} name={'father_name'}
                   labelClassName={"flex-1"}
                   label={"Nome do pai"}
            />
            <Select options={civilStates} control={control} name={'civil_state'} labelClassName={"flex-1"}
                    label={"Estado civil *"}
            />
            <Input isReadOnly={isReadOnly} type={`date`} control={control} name={'birthdate'}
                   labelClassName={"flex-1"}
                   label={"Data de nascimento *"}
            />
            <Select options={states} control={control} name={'birth_state_id'} labelClassName={"flex-1"}
                    label={"Estado de nascimento *"}
            />
            <CreatableSelect options={cities} control={control} name={'birth_city_id'} labelClassName={"flex-1"}
                             label={"Cidade de nascimento *"}
            />
            <Input isReadOnly={isReadOnly} type={`date`} control={control}
                   name={'gdf_admission_date'} labelClassName={"flex-1"}
                   label={"Data de adminissão no GDF *"}
            />
            <InputMask isReadOnly={isReadOnly} defaultValue={0} mask={`integer`}
                       control={control} name={'dependents'} labelClassName={"flex-1"}
                       label={"Número de dependentes *"}
            />
            <Select control={control} options={educationDegrees} name={'education_degree'} labelClassName={"flex-1"}
                    label={"Grau de ensino"}
            />
            <Select control={control} options={bloodTypes} name={'blood_type'} labelClassName={"flex-1"}
                    label={"Tipo de sangue"}
            />
            <Input isReadOnly={isReadOnly} control={control}
                   name={'sigrhnet'} labelClassName={"flex-1"}
                   label={"SIGRHNET"}
            />
            <Select control={control}
                    options={booleanOptions}
                    name={'permanence_allowance'} labelClassName={"flex-1"}
                    label={"Abono permanencia"}
            />
        </SimpleGrid>
    )
}