import {useForm} from "react-hook-form";
import {Flex, Stack, useToast} from "@chakra-ui/react";
import {MediaInput} from "../MediaInput";
import {Button} from "../Button";
import {useState} from "react";
import { IoIosCopy} from "react-icons/io"
import {Text} from "../Text";
import cloneDeep from "clone-deep";
import {serialize} from "object-to-formdata";
import {uploadImage} from "../../services/media";

export function ImageUploader({...rest}) {
    const toast = useToast()
    const [storedFileName, setStoredFileName] = useState<string|undefined>()
    const {control, watch, trigger, reset} = useForm()

    const handleUploadImage = async () => {
        if (!await trigger()) {
            return
        }
        const payload = serialize(watch()?.media)
        const response = await uploadImage(payload)
        console.log({payload})

        reset({
            media: null
        })
        setStoredFileName(`${process.env.REACT_APP_STORAGE_URL}/${response?.data?.source}`)
        console.log({response})


    }

    const handleCopy = () => {

        if (storedFileName) {
            navigator.clipboard.writeText(storedFileName)
            toast({
                position: 'top-right',
                status: "success",
                title: "Link copiado!",
                isClosable: true
            })
        }
    }
    return (
        <Stack {...rest} className={`${rest?.className} bg-gray-200/30 p-2 rounded-md`}>
            <MediaInput rules={{required: true}} control={control} name={`media`} label={`Upload de imagens`} />
            <Flex className={` gap-2`}>
                <span onClick={handleCopy} className={`!bg-gray-100 justify-center !flex gap-2 ${storedFileName && `cursor-pointer`} rounded-md items-center py-2 px-4 max-w-[70%] !font-light [&>svg>path]:!fill-primary flex-1`}><Text className={`!text-primary !line-clamp-1`}>{storedFileName ? storedFileName : `Nenhuma imagem enviada`}</Text>{storedFileName && <IoIosCopy className={`min-w-[16px]`} />}</span>
                <Button className={`!bg-primary !text-white !flex-1`} onClick={handleUploadImage}>Enviar imagem</Button>
            </Flex>
        </Stack>
    )
}