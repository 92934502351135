import {HTMLProps} from "react";
import {Link} from "../Link";

type LogoProps = any & {
}
export function Logo({...rest}: LogoProps ) {
    let source = `/logo-menu.png`

    return (
        <Link {...rest} href={`/`}>
            <img {...rest} src={source} className={`w-[240px] ${rest?.className}`} />
        </Link>
    )
}