import {api} from "./axios";

export const getAssemblies = async (params?: any) => {
    return await api.get(`/assembly-list`, {
        params
    }).catch(({response}) => {
        return response
    })
}
export const getAssemblyById = async (assemblyId?: number) => {
    return await api.get(`/assembly-get/${assemblyId}`, {
    }).catch(({response}) => {
        return response
    })
}
export const updateAssembly = async (assemblyId: number, payload?: any) => {
    return await api.post(`/assembly-update/${assemblyId}`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const createAssembly = async (payload?: any) => {
    return await api.post(`/assembly-register`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const deleteAssembly = async (assemblyId: number) => {
    return await api.delete(`/assembly-delete/${assemblyId}`, {
    }).catch(({response}) => {
        return response
    })
}
export const getAssemblyBySlug = async (assemblySlug?: string) => {
    return await api.get(`/assembly-slug/${assemblySlug}`, {
        params: {
            slug: assemblySlug
        }
    }).catch(({response}) => {
        return response
    })
}