import {Users as UsersTemplate} from "../../templates/Users"
import {UserCreateAndEdit} from "../../templates/Users/CreateAndEdit";
export function Users() {
    const pathname = window.location.pathname
    if (pathname.includes("/usuarios/") && pathname !== "/usuarios") {
        return <UserCreateAndEdit />
    }
    return (
        <UsersTemplate />
    )
}