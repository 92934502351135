import {Flex, SimpleGrid, Spinner} from "@chakra-ui/react";
import {Input} from "../../Input";
import {Control} from "react-hook-form";
import {Select} from "../../Select";
import {Button} from "../../Button";
import React, {useState} from "react";

type SearchBoxProps = {
    control: Control<any>
    isEditing?: boolean
    isLoading?: boolean
}

export function PasswordBox({control, isEditing, isLoading}: SearchBoxProps) {
    return (
        <SimpleGrid columns={2} className={"flex-1 gap-2"}>
            <Input {...(!isEditing ? {rules: {required: true}} : {})} control={control} type={`password`} name={'password'} labelClassName={"flex-1"}
                   label={"Senha *"} placeholder={"Senha *"}
            />
            <Input {...(!isEditing ? {rules: {required: true}} : {})} control={control} type={`password`} name={'password_repeat'} labelClassName={"flex-1"}
                   label={"Repetir senha *"} placeholder={"Repetir senha *"}
            />
        </SimpleGrid>
    )
}