import {api} from "./axios";

export const getBoards = async (params?: any) => {
    return await api.get(`/board-list`, {
        params
    }).catch(({response}) => {
        return response
    })
}
export const getBoardById = async (boardId?: number) => {
    return await api.get(`/board-get/${boardId}`, {
    }).catch(({response}) => {
        return response
    })
}
export const updateBoard = async (boardId: number, payload?: any) => {
    return await api.post(`/board-update/${boardId}`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const createBoard = async (payload?: any) => {
    return await api.post(`/board-register`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const deleteBoard = async (boardId: number) => {
    return await api.delete(`/board-delete/${boardId}`, {
    }).catch(({response}) => {
        return response
    })
}
export const getBoardTypes = async () => {
    return await api.get(`/board-type-list`, {
    }).catch(({response}) => {
        return response
    })
}
export const getBoardOffices = async () => {
    return await api.get(`/board-office-list`, {
    }).catch(({response}) => {
        return response
    })
}
export const getBoardGroups = async () => {
    return await api.get(`/board-group-list`, {
    }).catch(({response}) => {
        return response
    })
}