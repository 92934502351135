import {useNavigate, useParams} from "react-router-dom";
import {Accordion} from "../../../components/Accordion";
import {Control, useForm} from "react-hook-form";
import {AssemblyBox} from "../../../components/FormBox/AssemblyBox";
import React, {useEffect, useState} from "react";
import {AddressProps, AssemblyProps} from "../../../types";
import {createAddress, getAddressById} from "../../../services/address";
import {createAssembly, getAssemblyById, updateAssembly} from "../../../services/assemblies";
import {Box, Flex, Spinner, useDisclosure, useToast} from "@chakra-ui/react";
import {CancelItemBox} from "../../../components/CancelItemBox";
import {Dialog} from "../../../components/Dialog";
import {Container} from "../../../components/Container";
import {Button} from "../../../components/Button";
import {PasswordBox} from "../../../components/FormBox/PasswordBox";
import {serialize} from "object-to-formdata";
import {storageURL} from "../../../services/axios";
import {MediaProps} from "../../../components/MediaInput";
import {v4 as uuid} from 'uuid';
import {formatSelectsToPayload} from "../../../helpers";
import {FileProps} from "../../../components/FileInput";

export function AssemblyCreateAndEdit() {
    const [isSaving, setIsSaving] = useState(false)

    const {onOpen: saveDialogOnOpen, isOpen: saveDialogIsOpen, onClose: saveDialogOnClose} = useDisclosure()
    const navigate = useNavigate()
    const toast = useToast()
    const {
        control: assemblyControl,
        watch: assemblyWatch,
        getValues: assemblyGetValues,
        reset: assemblyReset,
        resetField: assemblyResetField,
        trigger: assemblyTrigger,
        setError: assemblySetError
    } = useForm<Partial<AssemblyProps>>({
        defaultValues: {
            active: true,
            live: false,
            create_chat: true,
            external_video: false,
            youtube_video: false
        }
    })
    const params = useParams()
    const assemblyId = Number(params.assemblyId)

    const getAssemblyData = async () => {
        const response = await getAssemblyById(assemblyId)
        assemblyReset({
            ...response.data?.assembly,
            active: !!response?.data?.assembly?.active,
            create_chat: false,
            chat: !!response?.data?.assembly?.chat,
            youtube_video: !!response?.data?.assembly?.youtube_video,
            external_video: !!response?.data?.assembly?.external_video,
            chat_id: {
                value: response?.data?.assembly?.chat?.id,
                label: response?.data?.assembly?.chat?.title
            }
        })
    }
    useEffect(() => {

        if (assemblyId) {
            getAssemblyData()
        }
    }, [])


    const validateFileName = (fieldName: string, files: FileProps[]) => {
        const names: string[] = []
        for (const file of files) {
            const fileName = file.file?.name ?? file?.name

            if (names.find((item) => item === fileName)) {
                assemblySetError('attachments',{
                    type: 'required',
                    message: `Os arquivos não podem ter nomes iguais`
                })
                return false
            }

            names.push(fileName!)
        }
        return true
    }

    const handleSave = async () => {
        if (!await assemblyTrigger()) {
            return
        }
        if (!validateFileName('attachments', assemblyGetValues().attachments) ) {
            return
        }
        let assemblyPayload = serialize(formatSelectsToPayload(assemblyGetValues()), {
            booleansAsIntegers: true,
            indices: true,
            allowEmptyArrays: true
        })

        let assemblyResponse: any
        setIsSaving(true)
        if (assemblyId) {
            assemblyResponse = await updateAssembly(assemblyId, assemblyPayload)
        } else {
            assemblyResponse = await createAssembly(assemblyPayload)
        }
        if (assemblyResponse.status !== 201 && assemblyResponse.status !== 200) {
            setIsSaving(false)
            return toast({
          position: 'top-right',
                status: "error",
                title: "Erro ao salvar assembleia",
                description: assemblyResponse.data.message || "Houve um erro salvar assembleia, por favor tente novamente",
                isClosable: true
            })
        }
        setIsSaving(false)

        navigate('/assembleias')
        return toast({
          position: 'top-right',
            status: "success",
            title: "Assembleia salva com sucesso",
            description: assemblyResponse.data.message || "A assembleia foi salva com sucesso",
            isClosable: true
        })

    }
    const handleCancel = () => {
        return navigate("/assembleias")
    }

    useEffect(() => {
        assemblyWatch((data) => {
            console.log({data})
        })
    }, [])

    return (
        <>
            <Dialog
                onAccept={handleSave}
                isOpen={saveDialogIsOpen}
                onClose={saveDialogOnClose}
                title={"Salvar assembleia"}
                description={"Ao confirmar, a assembleia será salva"}
            />
            <CancelItemBox title={"Voltar para lista de assembleias"} onClick={() => navigate('/assembleias')} />
            <Accordion title={"Dados da assembleia"} defaultExpanded={true}>
                <AssemblyBox watch={assemblyWatch} control={assemblyControl as Control<any>} isEditing={!!assemblyId} />
            </Accordion>
            <Container>
                <Flex className={`col-span-2 gap-2 justify-end`}>
                    <Button className={`!bg-neutral-800 !text-white`} onClick={() => handleCancel()}>Voltar</Button>
                    <Button className={`!bg-primary !text-white`} onClick={() => saveDialogOnOpen()}> Salvar {isSaving && <Spinner size={"sm"} className={`ml-1`}/>}</Button>
                </Flex>
            </Container>
        </>
    )
}