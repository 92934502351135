import {ColumnProps} from "../../types/table";
import {Badge, Flex} from "@chakra-ui/react";
import {AiFillCloseCircle, AiFillDelete, AiFillEdit} from "react-icons/ai";
import React from "react";
import {DropdownMenu} from "../../components/DropdownMenu";
import {Text} from "../../components/Text";
import {LineClamp} from "../../components/LineClamp";
import {Switch} from "../../components/Switch";
type TableColumnsProps = {
    onDelete: (noticeId: number) => void
    onEdit: (noticeId: number) => void
    onToggleActive: (notice: any, active: boolean) => void
}
export const tableColumns = ({onDelete, onEdit, onToggleActive}: TableColumnsProps): ColumnProps[] => {
    return [
        {
            header: "AÇÕES",
            cell: (info) => {
                return (
                        <DropdownMenu options={[
                            {
                                label: <Flex className={`cursor-pointer gap-2`} onClick={() => onEdit(info.row.original.id)} ><AiFillEdit /> Editar</Flex>
                            },
                            {
                                label: <Flex className={`cursor-pointer gap-2`} onClick={() => onDelete(info.row.original.id)} ><AiFillDelete /> Excluir</Flex>
                            }
                        ]} />


                )
            }
        },
        {
            id: "active",
            header: "ATIVO",
            cell: (info) => {
                const active = !!info.row.original.active
                console.log({active})
                return (
                    <Switch name={'active'} labelClassName={"flex-1"} defaultChecked={active} onChange={(e) => {
                        onToggleActive(info.row.original, e?.target?.checked)
                    }}
                    />
                )
            }
        },
        {
            id: "id",
            header: "ID"
        },
        {
            id: "title",
            header: "TÍTULO",
            cell: (info) => (
                <LineClamp>{info.row.original.title}</LineClamp>
            )
        },
        {
            id: "slug",
            header: "SLUG",
            cell: (info) => (
                <LineClamp>{info.row.original.slug}</LineClamp>
            )
        },
        {
            id: "content",
            header: "CONTEÚDO",
            cell: (info) => (
                <LineClamp>{info.row.original.content.replace( /(<([^>]+)>)/ig, '')}</LineClamp>
            )
        },
        {
            id: "created_at",
            header: "CRIADO EM"
        },
        {
            id: "updated_at",
            header: "ATUALIZADO EM"
        }
    ]
}