import {api} from "./axios";
import {UserProps} from "../types";

export const getUsers = async (params?: any) => {
    return await api.get(`/user-list`, {
        params
    }).catch(({response}) => {
        return response
    })
}
export const listProfiles = async (params?: any) => {
    return await api.get(`/user-profile-list`, {
        params
    }).catch(({response}) => {
        return response
    })
}
export const getUserById = async (userId?: number) => {
    return await api.get(`/user-get/${userId}`, {
    }).catch(({response}) => {
        return response
    })
}
export const updateUser = async (userId: number, payload?: any) => {
    return await api.post(`/user-update/${userId}`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const createUser = async (payload?: any) => {
    return await api.post(`/user-register`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const deleteUser = async (userId: number) => {
    return await api.delete(`/user-delete/${userId}`, {
    }).catch(({response}) => {
        return response
    })
}