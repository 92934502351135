import {ColumnProps} from "../../../types/table";
import {Badge, Flex} from "@chakra-ui/react";
import {AiFillCloseCircle, AiFillDelete, AiFillEdit} from "react-icons/ai";
import React from "react";
import {DropdownMenu} from "../../../components/DropdownMenu";
import {LineClamp} from "../../../components/LineClamp";
import {Switch} from "../../../components/Switch";
type TableColumnsProps = {
}
export const tableColumns = ({}: TableColumnsProps): ColumnProps[] => {
    return [
        {
            id: "id",
            header: "ID"
        },
        {
            id: "number",
            header: "MESA",
            cell: (info) => {
                return (
                    <Badge className={`!bg-primary !text-white `}>{info?.row.original?.number}</Badge>
                )
            }
        },
        {
            id: "name",
            header: "NOME",
            cell: (info) => (
                <LineClamp>{info?.row.original?.name}</LineClamp>
            )
        },
        {
            id: "is_guest",
            header: "CONVIDADO",
            cell: (info) => (
                <Badge className={`!bg-primary !text-white `}>{!info?.row.original?.affiliate ? "SIM" : "NÃO"}</Badge>
            )
        },
        {
            id: "inviter",
            header: "MATRICULA",
            cell: (info) => (
                <LineClamp>{info?.row.original?.inviter?.registration ?? info?.row.original?.affiliate?.registration ?? 'SISTEMA'}</LineClamp>
            )
        },
        {
            id: "created_at",
            header: "CRIADO EM"
        },
        {
            id: "updated_at",
            header: "ATUALIZADO EM"
        }
    ]
}
