import {TextLogo} from "../../../components/TextLogo";
import {Input} from "../../../components/Input";
import {Button} from "../../../components/Button";
import {Link} from "../../../components/Link";
import {Spinner, Stack, Text, useToast} from "@chakra-ui/react";
import {WizardStepProps} from "../../../types/wizard";
import {Logo} from "../../../components/Logo";
import {Control, useForm} from "react-hook-form";
import {LoginProps} from "../../../types";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {forgotPasswordChange, forgotPasswordCode} from "../../../services/login";

export function CodeConfirm({email, ...props}: Partial<WizardStepProps> & any) {
    const {
        control,
        watch,
        getValues,
        reset,
        resetField,
        trigger
    } = useForm<Partial<LoginProps>>()
    const [isLoading, setIsLoading] = useState(false)
    const toast = useToast()
    const navigate = useNavigate()
    const handleForgotPasswordChange = async () => {
        if (!await trigger()) {
            return
        }
        const payload = getValues()
        if (payload?.password !== payload?.password_repeat) {

            return toast({
                position: 'top-right',
                status: "error",
                title: "Não autorizado",
                description: "As senhas não são iguais",
                isClosable: true
            })
        }


        setIsLoading(true)
        const userResponse = await forgotPasswordChange({...payload, registration: email, email} as LoginProps)

        setIsLoading(false)
        if (userResponse.status !== 200) {

            return toast({
                position: 'top-right',
                status: "error",
                title: "Não autorizado",
                description: userResponse?.data?.message ?? "E-mail ou/e matrícula incorretos",
                isClosable: true
            })
        }


        toast({
            position: 'top-right',
            status: "success",
            title: "Autorizado",
            description: "Senha alterada com sucesso",
            isClosable: true
        })
        props.goToStep && props.goToStep(1)
    }
    return (
        <div className={"w-full flex flex-col items-center justify-center gap-5"}>
            <Logo />
            <Text>Digite o código recebido em seu e-mail para continuar</Text>
            <Stack className={`flex-1 w-full`}>
                <Input placeholder={"000000"} rules={{required: true}} control={control as Control<any>} name={'token'} className={"w-full"} labelClassName={"w-full"} />
                <Input placeholder={"Nova senha"} rules={{required: true}} control={control as Control<any>} name={'password'} type={'password'} className={"w-full"} labelClassName={"w-full"} />
                <Input placeholder={"Repetir nova senha"} rules={{required: true}} control={control as Control<any>} name={'password_repeat'} type={'password'} className={"w-full"} labelClassName={"w-full"} />
            </Stack>
            <Button isDisabled={isLoading} className={`!text-neutral-800`} onClick={handleForgotPasswordChange}> {isLoading && <Spinner size={"xs"} className={`!text-primary mr-2`} />} Confirmar código</Button>
            <Link onClick={() => props.goToStep && props.goToStep(2)}>Alterar o e-mail</Link>
            <Link onClick={() => props.goToStep && props.goToStep(1)}>Voltar para o login</Link>
        </div>
    )
}