import BackgroundImage from "../../assets/templates/default/login/background.jpg";
import StepWizard from "react-step-wizard";
import {SignIn} from "./steps/SignIn";
import {EmailConfirm} from "./steps/EmailConfirm";
import {CodeConfirm} from "./steps/CodeConfirm";
import {useForm} from "react-hook-form";
import {LoginProps} from "../../types";
import {useState} from "react";

export function Login() {
    const [email, setEmail] = useState<string>()
    return (
        <div className={"w-full h-screen flex overflow-y relative justify-center items-center"}>
            <div className={"absolute left-0 top-0 w-full !h-full z-50 bg-cover bg-center"} style={{backgroundBlendMode: "color", backgroundColor: `#0569B3`, minHeight: `30%`}}>
            </div>
            <div className={" p-12 md:w-2/5 md:max-w-2/5 bg-white !min-h-max flex items-center overflow-hidden relative z-50 rounded-md"} >
                <StepWizard className={"w-full pr-10 pl-10"}>
                    <SignIn />
                    <EmailConfirm setEmail={setEmail} />
                    <CodeConfirm email={email} />
                </StepWizard>
            </div>
        </div>
    )
}