import {TemplateProps} from "../../types/template";
import {LeftNav} from "./components/LeftNav";
import {TopNav} from "./components/TopNav";

export function Template({children}: TemplateProps) {
    return (
        <>
            <LeftNav />
            <main id={"main"} className={"flex-1 flex flex-col p-5 h-full z-40 bg-gray-200 dark:bg-neutral-800 overflow-y-auto gap-5"}>
                {/*<TopNav />*/}
                {children}
            </main>
        </>
    )
}