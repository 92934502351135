import {ColumnProps} from "../../../types/table";
import {Badge, Flex} from "@chakra-ui/react";
import {AiFillCloseCircle, AiFillDelete, AiFillEdit} from "react-icons/ai";
import React from "react";
import {DropdownMenu} from "../../../components/DropdownMenu";
type TableColumnsProps = {
    onDelete: (noticeId: number) => void
}
export const listColumns = ({onDelete}: TableColumnsProps): ColumnProps[] => {
    return [
        {
            header: "AÇÕES",
            cell: (info) => {
                return (
                        <DropdownMenu options={[
                            {
                                label: <Flex className={`cursor-pointer gap-2`} onClick={() => onDelete(info.row.original.id)} ><AiFillDelete /> Excluir</Flex>
                            }
                        ]} />


                )
            }
        },
        {
            id: "numbers",
            header: "MESAS"
        },
        {
            id: "chairs",
            header: "CADEIRAS"
        },
    ]
}