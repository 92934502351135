import {Accordion} from "../../../components/Accordion";
import {PaginationInfoProps, EventProps} from "../../../types";
import { Table } from "../../../components/Table";
import {tableColumns} from "./tableColumns"
import {deleteEvent, getEventGuests, getEvents, updateEvent} from "../../../services/events";
import React, {useEffect, useState} from "react";
import {Control, FieldValues, useForm} from "react-hook-form";
import {SearchBox} from "./searchBox";
import {NewItemBox} from "../../../components/NewItemBox";
import {useNavigate, useParams} from "react-router-dom";
import {useDisclosure, useToast} from "@chakra-ui/react";
import {debounce} from "debounce";

export function Guests() {
    const toast = useToast()
    const params = useParams()
    const eventId = Number(params.eventId)
    const navigate = useNavigate()
    const {
        control,
        watch
    } = useForm()
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfoProps>({
        per_page: 10,
        page: 1,
        last_page: 0,
        total: 0,
        to: 0
    })

    const [data, setData] = useState<EventProps[]>([] as EventProps[])

    useEffect(() => {
    }, [data])

    const getData = debounce(async (page?: number) => {
        const response = await getEventGuests(eventId, {
            per_page: paginationInfo.per_page,
            page: page ? page : paginationInfo.page,
            ...watch()
        })
        if (response.isAxiosError) {
            return
        }
        setData(response.data.data.map((event: EventProps) => {
            event.created_at = new Date(event.created_at).toLocaleString('pt-br')
            if (event.updated_at) {
                event.updated_at = new Date(event.updated_at).toLocaleString('pt-br')
            }
            return event
        }))
        setPaginationInfo(oldValue => {
            return {
                ...oldValue,
                last_page: response.data.last_page,
                total: response.data.total,
                to: response.data.to,
                page: response.data.current_page
            }
        })
    }, 750)
    const onPageChange = (page: number) => {
        getData(page + 1)
    }

    useEffect(() => {
        if (eventId) {
            getData()
        }
    }, [eventId])

    useEffect(() => {
        watch((data, {name}) => {
            getData(1)
        })
    }, [])
    const handleEdit = (eventId: number) => {
        return navigate(`/eventos/${eventId}`)
    }
    return (
        <>
            <NewItemBox title={"Novo evento"} className={""} onClick={() => navigate(`/eventos/cadastro`)} />
            <Accordion title={"Digite sua busca"} defaultExpanded={false} className={""}>
                <SearchBox control={control} />
            </Accordion>
            <Accordion title={"Eventos"} panelClassName={`overflow-auto max-h-screen`}>
                <Table data={data} columns={tableColumns({})} onPageChange={onPageChange} pageCount={paginationInfo.last_page} />
            </Accordion>
        </>
    )
}
