import {Badge, Flex, SimpleGrid, Spinner} from "@chakra-ui/react";
import {Input} from "../../Input";
import {Control, UseFormSetError, UseFormWatch} from "react-hook-form";
import {Select} from "../../Select";
import {Button} from "../../Button";
import React, {useState} from "react";
import {TextEditor} from "../../TextEditor";
import {MediaInput} from "../../MediaInput";
import {Text} from "../../Text";
import {Switch} from "../../Switch";
import {FileInput} from "../../FileInput";
import {ImageUploader} from "../../ImageUploader";

type SearchBoxProps = {
    control: Control<any>
    isEditing?: boolean
    isLoading?: boolean
    isReadOnly?: boolean
    isClient?: boolean
    watch: UseFormWatch<any>
}

export function AssemblyBox({watch, control, isEditing, isLoading, isReadOnly = false}: SearchBoxProps) {
    return (
        <SimpleGrid columns={2} className={"flex-1 gap-2"}>
            <Flex className={`flex-1 col-span-2`}>
                <Switch isReadOnly={isReadOnly} control={control} name={'active'} labelClassName={"flex-1"}
                        label={"Ativo"}
                />
                {/*<Switch isReadOnly={isReadOnly} control={control} name={'live'} labelClassName={"flex-1"}*/}
                {/*        label={"Ao vivo"}*/}
                {/*/>*/}
            </Flex>
            <Flex className={`flex-1 col-span-2`}>
                {!watch()?.chat && (
                    <Switch isReadOnly={isReadOnly} control={control} name={'create_chat'} labelClassName={"flex-1"}
                            label={"Criar chat"}
                    />
                )}
                {!watch()?.create_chat && (
                    <Switch isReadOnly={isReadOnly} control={control} name={'chat'} labelClassName={"flex-1"}
                            label={"Utilizar chat existente"}
                    />
                )}
            </Flex>
            <Flex className={`flex-1 col-span-2`}>
                {!watch()?.external_video && (
                    <Switch isReadOnly={isReadOnly} control={control} name={'youtube_video'} labelClassName={"flex-1"}
                            label={"Vídeo do youtube"}
                    />
                )}
                {!watch()?.youtube_video && (
                    <Switch isReadOnly={isReadOnly} control={control} name={'external_video'} labelClassName={"flex-1"}
                            label={"Vídeo externo"}
                    />
                )}
            </Flex>
            {watch()?.chat && (
                <Select control={control} name={'chat_id'} labelClassName={"flex-1 col-span-2"}
                       label={"Chat"}
                />
            )}
            {(watch()?.youtube_video || watch()?.external_video) && (
                <Input rules={{required: true}} isReadOnly={isReadOnly} control={control} name={'url_video'} labelClassName={"flex-1 col-span-2"}
                        label={"URL do vídeo *"}
                />
            )}
            <Input rules={{required: true}} isReadOnly={isReadOnly} control={control} name={'title'} labelClassName={"flex-1"}
                   label={"Título *"}
            />
            <Input rules={{required: true}} type={`datetime-local`} isReadOnly={isReadOnly} control={control} name={'date'} labelClassName={"flex-1"}
                   label={"Data *"}
            />
            <FileInput isReadOnly={isReadOnly} control={control} name={'attachments'} labelClassName={"flex-1 col-span-2"}
                        label={"Documentos"} multiple={true}
            />
            <ImageUploader  className={"flex-1 col-span-2"} />
            <TextEditor isReadOnly={isReadOnly} control={control} name={'description'} labelClassName={"flex-1 col-span-2"}
                   label={"Descrição"}
            />
        </SimpleGrid>
    )
}