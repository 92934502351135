import axios from "axios"
import {useUser} from "../hooks/useUser";
export const storageURL = process.env.REACT_APP_STORAGE_URL
export const apiURL = 'https://api.sindafis.org.br/api'
// export const apiURL = 'http://127.0.0.1:8000/api'
export const api = axios.create({
    baseURL: apiURL,
    withCredentials: false,
    headers: {
        "accept" : "application/json",
        "content-type" : "application/json"
    }
})
export const axiosCustomReq = axios.create({
    headers: {
        "accept" : "application/json",
        "content-type" : "application/json"
    }
})

api.interceptors.request.use(function (config) {
    const {user} = useUser()
    const token = user()?.token
    // Do something before request is sent
    if (config.headers && token) {
        config.headers['Authorization'] = `Bearer ${token}`
    }
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});
