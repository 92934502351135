import * as React from 'react'
import {Table as ChakraUiTable, TableContainer, Thead, Tbody, Tfoot, Tr, Th, Td, Box} from "@chakra-ui/react"
import ReactPaginate from 'react-paginate'
import {GrFormNext, GrFormPrevious, GrMore} from "react-icons/gr"

import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable
} from '@tanstack/react-table'
import {TableProps} from "../../types/table";
import { useMediaQuery } from '@chakra-ui/react'

const columnHelper = createColumnHelper<any>()

export function Table({columns, data, onPageChange, pageCount = 0, onRowClick}: TableProps) {
    const [isMobile] = useMediaQuery('(max-width: 768px)')

    const columnHelpers = columns?.map((column) => {
        return columnHelper.accessor(column.id!, {
            ...column
        })
    })

    const table = useReactTable({
        data,
        columns: columnHelpers,
        getCoreRowModel: getCoreRowModel(),
    })

    const handlePageChange = ({selected}: { selected: number }) => {
        if (onPageChange) {
            onPageChange(selected)
        }
    }
    const handleRowClick = (index: number) => {
        if (onRowClick) {
            onRowClick(index)
        }
    }

    return (
        <Box className={`max-w-full`}>
            <TableContainer className={`max-w-full overflow-auto border-gray-300 dark:border-neutral-900 border-solid border rounded-md rounded-b-none`}>
                <ChakraUiTable size={"sm"}>
                    <Thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <Tr key={headerGroup.id} className={`!border-0 bg-gray-300 dark:bg-neutral-900`}>
                                {headerGroup.headers.map(header => (
                                    <Th key={header.id} className={`!text-black dark:!text-white !border-0`}>
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                    </Th>
                                ))}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                        {table.getRowModel().rows.map(row => (
                            <Tr onClick={() => {
                                handleRowClick(Number(row.id))
                            }} key={row.id} className={`odd:bg-gray-200 dark:odd:bg-neutral-800  dark:hover:!bg-neutral-900 hover:!bg-gray-100  dark:hover:text-white !border-0`}>
                                {row.getVisibleCells().map(cell => (
                                    <Td key={cell.id} className={`!border-0`}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </Td>
                                ))}
                            </Tr>
                        ))}
                    </Tbody>
                    <Tfoot>
                        {table.getFooterGroups().map(footerGroup => (
                            <Tr key={footerGroup.id}>
                                {footerGroup.headers.map(header => (
                                    <Th key={header.id}>
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                header.column.columnDef.footer,
                                                header.getContext()
                                            )}
                                    </Th>
                                ))}
                            </Tr>
                        ))}
                    </Tfoot>
                </ChakraUiTable>
            </TableContainer>
            {(!!pageCount && pageCount > 1) && (
                <ReactPaginate
                    className={`flex justify-between items-center w-max p-2 m-auto gap-2`}
                    pageLinkClassName={'h-8 w-8 min-w-max flex justify-center items-center rounded-md bg-gray-200 dark:bg-neutral-800 p-2'}
                    breakLabel={!isMobile && <GrMore/>}
                    nextLabel={<GrFormNext/>}
                    onPageChange={handlePageChange}
                    pageRangeDisplayed={isMobile ? 3 : 5}
                    marginPagesDisplayed={isMobile ? 0 : 3}
                    pageCount={pageCount}
                    previousLabel={<GrFormPrevious/>}
                    activeLinkClassName={`!bg-primary text-white`}
                />
            )}
        </Box>
    )
}
