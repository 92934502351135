import {BrowserRouter, Routes as BrowserRoutes, Route, Navigate} from "react-router-dom";
import {routes} from "./routes";
import {DefaultTemplate} from "../templates/Default";
import {useEffect} from "react";
import {useUser} from "../hooks/useUser";

export function Routes() {
    const {user} = useUser()

    useEffect(() => {
        console.log({user: user()})
        if (!user()) {
            if (!window?.location?.pathname?.includes("login")) {
                window.location.href = "/login"
            }
            return
        }
    }, [])

    useEffect(() => {
        if (localStorage) {
            if (localStorage.getItem('theme') === 'dark') {
                document.documentElement.classList.add('dark')
                document.documentElement.classList.remove('light')
                document.getElementsByTagName('html')[0].setAttribute("data-theme", "dark")
                document.getElementsByTagName('html')[0].style.colorScheme = "dark"
            } else {
                document.documentElement.classList.add('light')
                document.documentElement.classList.remove('dark')
                document.getElementsByTagName('html')[0].setAttribute("data-theme", "light")
                document.getElementsByTagName('html')[0].style.colorScheme = "light"
            }
        }
    }, [localStorage])
    return (
        <BrowserRouter>
            <BrowserRoutes>
                <Route path={`/`} element={<Navigate to={"/inicio"} replace={true} />} />
                {routes.map((route, i) => {
                    if (!route.template) {
                        if (!route.empty) {
                            route.element = (
                                <DefaultTemplate>
                                    {route.element}
                                </DefaultTemplate>
                            )
                        }
                    }
                    return (
                        <Route key={i} {...route} />
                    )
                })}
            </BrowserRoutes>
        </BrowserRouter>
    )
}
