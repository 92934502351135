import {Flex, SimpleGrid, Spinner} from "@chakra-ui/react";
import {Input} from "../../Input";
import {Control, UseFormReset, UseFormWatch} from "react-hook-form";
import {Select} from "../../Select";
import {Button} from "../../Button";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {TextEditor} from "../../TextEditor";
import {MediaInput} from "../../MediaInput";
import {getAffiliateOffices} from "../../../services/affiliates";
import {getBoardTypes} from "../../../services/boards";
import {getAgreementTypes} from "../../../services/agreements";
import {CreatableSelect} from "../../CreatableSelect";
import {Switch} from "../../Switch";
import {ImageUploader} from "../../ImageUploader";

type SearchBoxProps = {
    control: Control<any>
    isEditing?: boolean
    isLoading?: boolean
    isReadOnly?: boolean
    isClient?: boolean
    reset: UseFormReset<any>
    watch: UseFormWatch<any>
}

export function AgreementBox({control, isEditing, isLoading, reset, watch, isReadOnly = false}: SearchBoxProps) {
    const [types, setTypes] = useState<any>([])
    const firstCall = useRef(false)

    const getTypes = async () => {
        const response = await getAgreementTypes()

        const formattedTypes = response?.data?.data?.map((type: any) => {
            return {
                label: type?.name.replaceAll("_", " "),
                value: type?.id
            }
        })

        setTypes(formattedTypes)

        if (isEditing) {
            reset && reset({
                ...watch(),
                type_id: formattedTypes?.find((type: any) => type?.value === watch()?.type_id)
            })
        }
    }

    const watchCallback = useCallback(async (data :any, {name} :any) => {
        console.log({data, name, firstCall})

        // const selectKeys: any = {
        //     "birth_state_id": states,
        //     "birth_city_id": cities
        // }
        //

    }, [firstCall])

    useEffect(() => {
        if (!isLoading && !firstCall?.current) {
            watch && watch((data, current) => watchCallback(data, current))
            getTypes()
            firstCall.current = true
        }

    }, [isLoading])
    return (
        <SimpleGrid columns={2} className={"flex-1 gap-2"}>
            <Switch isReadOnly={isReadOnly} control={control} name={'active'} labelClassName={"flex-1 col-span-2"}
                    label={"Ativo *"}
            />
            <Switch isReadOnly={isReadOnly} control={control} name={'highlight'} labelClassName={"flex-1 col-span-2"}
                    label={"Destaque *"}
            />
            <MediaInput onlyImage={true} isReadOnly={isReadOnly} control={control} name={'image'} labelClassName={"flex-1 col-span-2"}
                   label={"Imagem principal *"} multiple={false}
            />
            <Input rules={{required: true}} isReadOnly={isReadOnly} control={control} name={'title'} labelClassName={"flex-1 col-span-2"}
                   label={"Título *"}
            />
            <CreatableSelect rules={{required: true}} control={control} options={types} name={'type_id'} labelClassName={"flex-1"}
                   label={"Tipo"}
            />
            <Input isReadOnly={isReadOnly} control={control} name={'discount'} labelClassName={"flex-1"}
                   label={"Desconto"}
            />
            <Input isReadOnly={isReadOnly} control={control} name={'phone'} labelClassName={"flex-1"}
                   label={"Telefone"}
            />
            <Input isReadOnly={isReadOnly} control={control} name={'email'} type={`email`} labelClassName={"flex-1"}
                   label={"E-mail"}
            />
            <Input isReadOnly={isReadOnly} placeholder={`https://...`} type={`url`} control={control} name={'website'} labelClassName={"flex-1"}
                   label={"Website"}
            />
            <Input isReadOnly={isReadOnly} control={control} name={'address'} labelClassName={"flex-1"}
                   label={"Endereço"}
            />
            <ImageUploader  className={"flex-1 col-span-2"} />
            <TextEditor isReadOnly={isReadOnly} control={control} name={'info'} labelClassName={"flex-1 col-span-2"}
                   label={"Informativo"}
            />
        </SimpleGrid>
    )
}