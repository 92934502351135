import {api} from "./axios";

export const getEvents = async (params?: any) => {
    return await api.get(`/event-list`, {
        params
    }).catch(({response}) => {
        return response
    })
}
export const getEventGuests = async (eventId: number, params?: any) => {
    return await api.get(`/event/${eventId}/guests-list`, {
        params
    }).catch(({response}) => {
        return response
    })
}
export const getMenuEvents = async (params?: any) => {
    return await api.get(`/event-list-on-menu`, {
        params
    }).catch(({response}) => {
        return response
    })
}
export const getEventById = async (eventId?: number) => {
    return await api.get(`/event-get/${eventId}`, {
    }).catch(({response}) => {
        return response
    })
}
export const getEventBySlug = async (eventSlug?: string) => {
    return await api.get(`/event-slug/${eventSlug}`, {
    }).catch(({response}) => {
        return response
    })
}
export const updateEvent = async (eventId: number, payload?: any) => {
    return await api.post(`/event-update/${eventId}`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const createEvent = async (payload?: any) => {
    return await api.post(`/event-register`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const updateEventTableChair = async (chairId: number, payload?: any) => {
    return await api.post(`/event-table-chair-update/${chairId}`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const deleteEventTableChair = async (chairId: number) => {
    return await api.delete(`/event-table-chair-delete/${chairId}`, {
    }).catch(({response}) => {
        return response
    })
}
export const registerEventTableChair = async (tableId: number, payload?: any) => {
    return await api.post(`/event-table-chair-register/${tableId}`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const deleteEvent = async (eventId: number) => {
    return await api.delete(`/event-delete/${eventId}`, {
    }).catch(({response}) => {
        return response
    })
}
