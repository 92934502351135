import {Link as ChakraUiLink, LinkProps as ChakraUiLinkProps} from "@chakra-ui/react"
import {useNavigate} from "react-router-dom";
type LinkProps = ChakraUiLinkProps & {

}
export function Link({children, ...rest}: LinkProps) {
    const navigate = useNavigate()
    return (
        <>
            <ChakraUiLink {...rest} onClick={(e) => {
                e.preventDefault()
                rest.href && navigate(rest.href)
                rest.onClick && rest.onClick(e)
            }}>
                {children}
            </ChakraUiLink>
        </>
    )
}