import {Text, TextProps} from "../Text";
import React, {ReactNode} from "react";

type LineClampProps = TextProps & {
    width?: number
    children: string
}
export function LineClamp({width = 200, children, ...rest}: LineClampProps) {
    return (
        <Text style={{maxWidth: `${width}px`}} {...rest} className={`!block text-ellipsis line-clamp-1 ${rest.className}`} title={children?.replace( /(<([^>]+)>)/ig, '')?.replaceAll(`&nbsp;`, ``)}>{children?.replace( /(<([^>]+)>)/ig, '')?.replaceAll(`&nbsp;`, ``)}</Text>
    )
}