import {TextLogo} from "../../../components/TextLogo";
import {Input} from "../../../components/Input";
import {Button} from "../../../components/Button";
import {Link} from "../../../components/Link";
import {WizardStepProps} from "../../../types/wizard";
import {Logo} from "../../../components/Logo";
import {Control, useForm} from "react-hook-form";
import {LoginProps} from "../../../types";
import {forgotPasswordCode, userLogin} from "../../../services/login";
import {useToast, Spinner} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {useState} from "react";

export function EmailConfirm({setEmail, ...props}: Partial<WizardStepProps> & any) {
    const {
        control,
        watch,
        getValues,
        reset,
        resetField,
        trigger
    } = useForm<Partial<LoginProps>>()
    const [isLoading, setIsLoading] = useState(false)
    const toast = useToast()
    const navigate = useNavigate()
    const handleForgotPasswordCode = async () => {
        if (!await trigger()) {
            return
        }
        const payload = getValues()


        setIsLoading(true)
        const userResponse = await forgotPasswordCode({...payload, registration: payload?.email} as LoginProps)

        setIsLoading(false)
        if (userResponse.status !== 201) {

            return toast({
                position: 'top-right',
                status: "error",
                title: "Não autorizado",
                description: "E-mail ou/e matrícula incorretos",
                isClosable: true
            })
        }

        setEmail(payload.email)
        toast({
            position: 'top-right',
            status: "success",
            title: "Autorizado",
            description: "Foi enviado um código para o seu e-mail",
            isClosable: true
        })
        props.goToStep && props.goToStep(3)
    }
    return (
        <div className={"w-full flex flex-col items-center justify-center gap-5"}>
            <Logo />
            <Input rules={{required: true}} control={control as Control<any>} name={"email"} placeholder={"Seu e-mail ou matrícula"} className={"w-full"}
                   labelClassName={"w-full"}/>
            <Button isDisabled={isLoading} onClick={handleForgotPasswordCode} className={`!text-neutral-800`}>{isLoading && <Spinner size={"xs"} className={`!text-primary mr-2`} />} Recuperar senha</Button>
            <Link onClick={() => props.goToStep && props.goToStep(1)}>Voltar para o login</Link>
        </div>
    )
}