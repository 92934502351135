import {useNavigate, useParams} from "react-router-dom";
import {Accordion} from "../../../components/Accordion";
import {Control, useForm} from "react-hook-form";
import {UserBox} from "../../../components/FormBox/UserBox";
import React, {useEffect, useState} from "react";
import {AddressProps, UserProps} from "../../../types";
import {createAddress, getAddressById} from "../../../services/address";
import {createUser, getUserById, updateUser} from "../../../services/users";
import {Box, Flex, Spinner, useDisclosure, useToast} from "@chakra-ui/react";
import {CancelItemBox} from "../../../components/CancelItemBox";
import {Dialog} from "../../../components/Dialog";
import {Container} from "../../../components/Container";
import {Button} from "../../../components/Button";
import {PasswordBox} from "../../../components/FormBox/PasswordBox";
import {formatSelectsToPayload} from "../../../helpers";

export function UserCreateAndEdit() {
    const [isSaving, setIsSaving] = useState(false)

    const {onOpen: saveDialogOnOpen, isOpen: saveDialogIsOpen, onClose: saveDialogOnClose} = useDisclosure()
    const navigate = useNavigate()
    const toast = useToast()
    const {
        control: userControl,
        watch: userWatch,
        getValues: userGetValues,
        reset: userReset,
        resetField: userResetField,
        trigger: userTrigger,
        setError: userSetError
    } = useForm<Partial<UserProps>>()
    const params = useParams()
    const userId = Number(params.userId)

    const getUserData = async () => {
        const response = await getUserById(userId)
        userReset({
            ...response.data?.user,
            profile_id: {
                label:   response.data?.user?.profile?.name,
                value:   response.data?.user?.profile?.id,
            },
            password: undefined,
            password_repeat: undefined
        })
    }
    useEffect(() => {

        if (userId) {
            getUserData()
        }
    }, [])


    const handleSave = async () => {
        if (!await userTrigger()) {
            return
        }
        const userPayload = formatSelectsToPayload(userGetValues())

        if(userPayload?.password !== userPayload?.password_repeat) {
            userSetError(`password`, {type: `value`, message: `As senhas devem ser iguais`})
            userSetError(`password_repeat`, {type: `value`, message: `As senhas devem ser iguais`})
            return
        }

        let userResponse: any
        setIsSaving(true)
        if (userId) {
            userResponse = await updateUser(userId, {...userPayload})
        } else {
            userResponse = await createUser({...userPayload})
        }
        if (userResponse.status !== 201 && userResponse.status !== 200) {
            setIsSaving(false)
            return toast({
          position: 'top-right',
                status: "error",
                title: "Erro ao salvar usuário",
                description: userResponse.data.message || "Houve um erro salvar o usuário, por favor tente novamente",
                isClosable: true
            })
        }
        setIsSaving(false)

        navigate('/usuarios')
        return toast({
          position: 'top-right',
            status: "success",
            title: "Usuário salvo com sucesso",
            description: userResponse.data.message || "O usuário foi salvo com sucesso",
            isClosable: true
        })

    }
    const handleCancel = () => {
        return navigate("/usuarios")
    }

    return (
        <>
            <Dialog
                onAccept={handleSave}
                isOpen={saveDialogIsOpen}
                onClose={saveDialogOnClose}
                title={"Salvar usuário"}
                description={"Ao confirmar, o usuário será salvo"}
            />
            <CancelItemBox title={"Voltar para lista de usuários"} onClick={() => navigate('/usuarios')} />
            <Accordion title={"Dados do usuário"} defaultExpanded={true}>
                <UserBox control={userControl as Control<any>} isEditing={!!userId} />
            </Accordion>
            <Accordion title={"Senha do usuário"} defaultExpanded={false}>
                <PasswordBox control={userControl as Control<any>} isEditing={!!userId} />
            </Accordion>
            <Container>
                <Flex className={`col-span-2 gap-2 justify-end`}>
                    <Button className={`!bg-neutral-800 !text-white`} onClick={() => handleCancel()}>Voltar</Button>
                    <Button className={`!bg-primary !text-white`} onClick={() => saveDialogOnOpen()}> Salvar {isSaving && <Spinner size={"sm"} className={`ml-1`}/>}</Button>
                </Flex>
            </Container>
        </>
    )
}