import {api} from "./axios";

export const getBanners = async (params?: any) => {
    return await api.get(`/banner-list`, {
        params
    }).catch(({response}) => {
        return response
    })
}
export const getBannerById = async (bannerId?: number) => {
    return await api.get(`/banner-get/${bannerId}`, {
    }).catch(({response}) => {
        return response
    })
}
export const updateBanner = async (bannerId: number, payload?: any) => {
    return await api.post(`/banner-update/${bannerId}`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const createBanner = async (payload?: any) => {
    return await api.post(`/banner-register`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const deleteBanner = async (bannerId: number) => {
    return await api.delete(`/banner-delete/${bannerId}`, {
    }).catch(({response}) => {
        return response
    })
}