import {Accordion} from "../../../components/Accordion";
import {PaginationInfoProps, AssemblyProps} from "../../../types";
import {deleteAssembly, getAssemblies} from "../../../services/assemblies";
import React, {useEffect, useState} from "react";
import {Control, FieldValues, useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {Badge, Flex, Stack, useDisclosure, useToast} from "@chakra-ui/react";
import {Text} from "../../../components/Text";
import {Link} from "../../../components/Link";

export function Assemblies() {
    const {onOpen: deleteDialogOnOpen, isOpen: deleteDialogIsOpen, onClose: deleteDialogOnClose} = useDisclosure()

    const [deleteId, setDeleteId] = useState(0)
    const toast = useToast()
    const navigate = useNavigate()
    const {
        control,
        watch
    } = useForm()
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfoProps>({
        per_page: 10,
        page: 1,
        last_page: 0,
        total: 0,
        to: 0
    })

    const [data, setData] = useState<AssemblyProps[]>([] as AssemblyProps[])

    useEffect(() => {
    }, [data])

    const getData = async (page?: number) => {
        const response = await getAssemblies({
            per_page: paginationInfo.per_page,
            page: page ? page : paginationInfo.page,
            active: 1
        })
        if (response.isAxiosError) {
            return
        }
        setData(response.data.data.map((assembly: AssemblyProps) => {
            assembly.created_at = new Date(assembly.created_at).toLocaleString('pt-br')
            if (assembly.updated_at) {
                assembly.updated_at = new Date(assembly.updated_at).toLocaleString('pt-br')
            }
            return assembly
        }))
        setPaginationInfo(oldValue => {
            return {
                ...oldValue,
                last_page: response.data.last_page,
                total: response.data.total,
                to: response.data.to,
                page: response.data.current_page
            }
        })
    }
    const onPageChange = (page: number) => {
        getData(page + 1)
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        watch((data, {name}) => {
            console.log({data, name})
        })
    }, [])
    const handleEdit = (assemblyId: number) => {
        return navigate(`/assembleias/${assemblyId}`)
    }
    const handleDelete = async () => {
        const response = await deleteAssembly(deleteId)
        if (response?.status !== 204) {
            return toast({
          position: 'top-right',
                status: "error",
                title: "Erro ao deletar assembleia",
                description: response.data.message || "Houve um erro deletar assembleia, por favor tente novamente",
                isClosable: true
            })
        }
        getData()
        return toast({
          position: 'top-right',
            status: "success",
            title: "Assembleia deletada com sucesso",
            description: response.data.message || "A assembleia foi deletada com sucesso",
            isClosable: true
        })
    }
    return (
        <>
            <Accordion title={"Próximas assembleias"} panelClassName={`overflow-auto max-h-screen`}>
                <Stack spacing={0} className={`gap-2`}>
                    {data?.length < 1 && (
                        <Flex className={`bg-gray-100 dark:bg-neutral-800 p-2 gap-2 rounded-md `}>
                            <Text>Nenhuma assembleia marcada no momento</Text>
                        </Flex>
                    )}
                    {data?.map((assembly, key) => {
                        return (
                            <Link key={key} href={!!assembly?.live ? `/cliente/assembleias/${assembly?.slug}` : undefined}>
                                <Flex
                                    className={`
                                        transition-all 
                                        bg-gray-100 
                                        dark:bg-neutral-800 
                                        p-2 
                                        gap-2 
                                        rounded-md 
                                        items-center 
                                        cursor-pointer 
                                        dark:hover:!bg-neutral-900 
                                        hover:!bg-gray-200
                                    `}
                                >
                                    {!!assembly?.live && (
                                        <Badge className={`!bg-red-500 !text-white`}>AO VIVO</Badge>
                                    )}
                                    <Badge
                                        className={`!bg-blue-500 !text-white`}>{new Date(assembly?.date)?.toLocaleString()}</Badge>
                                    <Text className={`!text-sm`}><b>{assembly?.title}</b></Text>
                                </Flex>
                            </Link>
                        )
                    })}
                </Stack>
            </Accordion>
        </>
    )
}