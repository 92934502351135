import {Flex, SimpleGrid, Spinner} from "@chakra-ui/react";
import {Input} from "../../Input";
import {Control, UseFormReset, UseFormWatch} from "react-hook-form";
import {Select} from "../../Select";
import {Button} from "../../Button";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {TextEditor} from "../../TextEditor";
import {MediaInput} from "../../MediaInput";
import {getAffiliateOffices} from "../../../services/affiliates";
import {getBoardGroups, getBoardOffices, getBoards, getBoardTypes} from "../../../services/boards";
import {CreatableSelect} from "../../CreatableSelect";
import {Switch} from "../../Switch";

type SearchBoxProps = {
    control: Control<any>
    isEditing?: boolean
    isLoading?: boolean
    isReadOnly?: boolean
    isClient?: boolean
    reset: UseFormReset<any>
    watch: UseFormWatch<any>
}

export function BoardBox({control, isEditing, isLoading, reset, watch, isReadOnly = false}: SearchBoxProps) {
    const [offices, setOffices] = useState<any>([])
    const [types, setTypes] = useState<any>([])
    const [groups, setGroups] = useState<any>([])
    const firstCall = useRef(false)

    const getOffices = async () => {
        const response = await getBoardOffices()

        const formattedOffices = response?.data?.data?.map((office: any) => {
            return {
                label: office?.name.replaceAll("_", " "),
                value: office?.id
            }
        })

        setOffices(formattedOffices)

        if (isEditing) {
            reset && reset({
                ...watch(),
                office_id: formattedOffices?.find((office: any) => office?.value === watch()?.office_id)
            })
        }
    }

    const getTypes = async () => {
        const response = await getBoardTypes()

        const formattedTypes = response?.data?.data?.map((type: any) => {
            return {
                label: type?.name.replaceAll("_", " "),
                value: type?.id
            }
        })

        setTypes(formattedTypes)

        if (isEditing) {
            reset && reset({
                ...watch(),
                type_id: formattedTypes?.find((type: any) => type?.value === watch()?.type_id)
            })
        }
    }

    const getGroups = async () => {
        const response = await getBoardGroups()

        const formattedGroups = response?.data?.data?.map((group: any) => {
            return {
                label: group?.name.replaceAll("_", " "),
                value: group?.id
            }
        })

        setGroups(formattedGroups)

        if (isEditing) {
            reset && reset({
                ...watch(),
                group_id: formattedGroups?.find((group: any) => group?.value === watch()?.group_id)
            })
        }
    }

    const watchCallback = useCallback(async (data :any, {name} :any) => {
        console.log({data, name, firstCall})

        // const selectKeys: any = {
        //     "birth_state_id": states,
        //     "birth_city_id": cities
        // }
        //

    }, [firstCall])

    useEffect(() => {
        if (!isLoading && !firstCall?.current) {
            watch && watch((data, current) => watchCallback(data, current))
            getOffices()
            getTypes()
            getGroups()
            firstCall.current = true
        }

    }, [isLoading])
    return (
        <SimpleGrid columns={2} className={"flex-1 gap-2"}>
            <Switch isReadOnly={isReadOnly} control={control} name={'active'} labelClassName={"flex-1 col-span-2"}
                    label={"Ativo *"}
            />
            <MediaInput onlyImage={true} isReadOnly={isReadOnly} control={control} name={'image'} labelClassName={"flex-1 col-span-2"}
                   label={"Imagem principal *"} multiple={false}
            />
            <Input rules={{required: true}} isReadOnly={isReadOnly} control={control} name={'name'} labelClassName={"flex-1"}
                   label={"Nome *"}
            />
            <Input isReadOnly={isReadOnly} control={control} name={'email'} type={`email`} labelClassName={"flex-1"}
                   label={"E-mail"}
            />
            <CreatableSelect control={control} options={offices} name={'office_id'} labelClassName={"flex-1 col-span-2"}
                   label={"Cargo"}
            />
            <CreatableSelect rules={{required: true}} control={control} options={types} name={'type_id'} labelClassName={"flex-1"}
                             label={"Tipo *"}
            />
            <CreatableSelect control={control} options={groups} name={'group_id'} labelClassName={"flex-1"}
                             label={"Grupo"}
            />
        </SimpleGrid>
    )
}