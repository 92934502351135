import {api} from "./axios";

export const getAgreements = async (params?: any) => {
    return await api.get(`/agreement-list`, {
        params
    }).catch(({response}) => {
        return response
    })
}
export const getAgreementById = async (agreementId?: number) => {
    return await api.get(`/agreement-get/${agreementId}`, {
    }).catch(({response}) => {
        return response
    })
}
export const updateAgreement = async (agreementId: number, payload?: any) => {
    return await api.post(`/agreement-update/${agreementId}`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const createAgreement = async (payload?: any) => {
    return await api.post(`/agreement-register`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const deleteAgreement = async (agreementId: number) => {
    return await api.delete(`/agreement-delete/${agreementId}`, {
    }).catch(({response}) => {
        return response
    })
}
export const getAgreementTypes = async () => {
    return await api.get(`/agreement-type-list`, {
    }).catch(({response}) => {
        return response
    })
}