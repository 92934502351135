import {Text} from "@chakra-ui/react"
import { AsyncPaginate } from 'react-select-async-paginate';

import  {Props as ReactSelectProps} from "react-select"
import "./styles.css"
import {SelectOptionProps} from "../../types";
import {useController, UseControllerProps, ControllerProps, useForm} from "react-hook-form";
import {useEffect, useState} from "react";
type SelectProps = (Partial<ReactSelectProps> & UseControllerProps) & {
    options?: SelectOptionProps[]
    label?: string
    labelClassName?: string
    onSelect?: (event: SelectOptionProps) => void
    onLoadingOptions: any

    name?: string
}

export function PaginatedSelect( {name = "", onLoadingOptions, ...select}: SelectProps) {
    const {control: controlDefault} = useForm()
    const {fieldState, field} = useController({
        control: select.control || controlDefault,
        rules: select.rules,
        name: name,
        defaultValue: select.defaultValue
    })
    return (
        <label className={`flex flex-col ${select.labelClassName ? select.labelClassName : ``}`}>
            {select.label && (
                <Text className={"line-clamp-1 mb-2 "}>{select.label}</Text>
            )}
            <AsyncPaginate
                {...select}
                {...field}
                isClearable={true}
                loadOptions={onLoadingOptions}
                debounceTimeout={750}
                additional={{
                    page: 1
                }}
                placeholder={select.placeholder || "Selecione uma opção"}
                className={`react-select-container  ${fieldState.error && `is-invalid`} ${fieldState?.error ? `react-select-container--is-invalid` : ``} ${!!select.className && select.className}`}
                classNamePrefix={`react-select`}
                // options={select.options}
                // noOptionsMessage={({inputValue}) => !inputValue && "Nenhuma opção disponível"}
                onChange={(e, a) => {
                    if (select.onSelect) {
                        select.onSelect(e as SelectOptionProps)
                    }
                    if (select.onChange) {
                        select.onChange(e, a)
                    }
                    if (field.onChange) {
                        field.onChange(e)
                    }
                }}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                {...(fieldState?.error ? {invalid: true} : {})}
            />
        </label>
    )
}