import {api} from "./axios";
import {AffiliateProps} from "../types";

export const getAffiliates = async (params?: any) => {
    return await api.get(`/affiliate-list`, {
        params
    }).catch(({response}) => {
        return response
    })
}
export const getAffiliateById = async (affiliateId?: number) => {
    return await api.get(`/affiliate-get/${affiliateId}`, {
    }).catch(({response}) => {
        return response
    })
}
export const updateAffiliate = async (affiliateId: number, payload?: any) => {
    return await api.post(`/affiliate-update/${affiliateId}`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const createAffiliate = async (payload?: any) => {
    return await api.post(`/affiliate-register`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const deleteAffiliate = async (affiliateId: number) => {
    return await api.delete(`/affiliate-delete/${affiliateId}`, {
    }).catch(({response}) => {
        return response
    })
}
export const getAffiliateBloodTypes = async () => {
    return await api.get(`/affiliate-blood-type-list`, {
    }).catch(({response}) => {
        return response
    })
}
export const getAffiliateCivilStates = async () => {
    return await api.get(`/affiliate-civil-state-list`, {
    }).catch(({response}) => {
        return response
    })
}
export const getAffiliateEducationDegrees = async () => {
    return await api.get(`/affiliate-education-degree-list`, {
    }).catch(({response}) => {
        return response
    })
}
export const getAffiliateGenders = async () => {
    return await api.get(`/affiliate-gender-list`, {
    }).catch(({response}) => {
        return response
    })
}
export const getAffiliateGroups = async () => {
    return await api.get(`/affiliate-group-list`, {
    }).catch(({response}) => {
        return response
    })
}
export const getAffiliateOffices = async () => {
    return await api.get(`/affiliate-office-list`, {
    }).catch(({response}) => {
        return response
    })
}
export const getAffiliateSpecialities = async () => {
    return await api.get(`/affiliate-speciality-list`, {
    }).catch(({response}) => {
        return response
    })
}