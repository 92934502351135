import {useNavigate, useParams} from "react-router-dom";
import {Accordion} from "../../../components/Accordion";
import {Control, useForm} from "react-hook-form";
import {BannerBox} from "../../../components/FormBox/BannerBox";
import React, {useEffect, useState} from "react";
import {AddressProps, BannerProps} from "../../../types";
import {createAddress, getAddressById} from "../../../services/address";
import {createBanner, getBannerById, updateBanner} from "../../../services/banners";
import {Box, Flex, Spinner, useDisclosure, useToast} from "@chakra-ui/react";
import {CancelItemBox} from "../../../components/CancelItemBox";
import {Dialog} from "../../../components/Dialog";
import {Container} from "../../../components/Container";
import {Button} from "../../../components/Button";
import {PasswordBox} from "../../../components/FormBox/PasswordBox";
import {serialize} from "object-to-formdata";
import {storageURL} from "../../../services/axios";
import {MediaProps} from "../../../components/MediaInput";
import {v4 as uuid} from 'uuid';
import {formatSelectsToPayload} from "../../../helpers";

export function BannerCreateAndEdit() {
    const [isSaving, setIsSaving] = useState(false)

    const {onOpen: saveDialogOnOpen, isOpen: saveDialogIsOpen, onClose: saveDialogOnClose} = useDisclosure()
    const navigate = useNavigate()
    const toast = useToast()
    const {
        control: bannerControl,
        watch: bannerWatch,
        getValues: bannerGetValues,
        reset: bannerReset,
        resetField: bannerResetField,
        trigger: bannerTrigger
    } = useForm<Partial<BannerProps>>()
    const params = useParams()
    const bannerId = Number(params.bannerId)

    const getBannerData = async () => {
        const response = await getBannerById(bannerId)
        bannerReset({
            ...response.data?.banner,
            image: {
                uuid: uuid(),
                url: `${storageURL}/${response.data?.banner?.image}`,
                type: `image`

            } as MediaProps,
            active: !!response?.data?.banner?.active
        })
    }
    useEffect(() => {

        if (bannerId) {
            getBannerData()
        }
    }, [])


    const handleSave = async () => {
        if (!await bannerTrigger()) {
            return
        }
        let bannerPayload = formatSelectsToPayload(bannerGetValues())

        if (!bannerPayload?.image?.size) {
            bannerPayload.image = undefined
        }

        bannerPayload = serialize(bannerPayload, {
            booleansAsIntegers: true
        })

        let bannerResponse: any
        setIsSaving(true)
        if (bannerId) {
            bannerResponse = await updateBanner(bannerId, bannerPayload)
        } else {
            bannerResponse = await createBanner(bannerPayload)
        }
        if (bannerResponse.status !== 201 && bannerResponse.status !== 200) {
            setIsSaving(false)
            return toast({
          position: 'top-right',
                status: "error",
                title: "Erro ao salvar notícia",
                description: bannerResponse.data.message || "Houve um erro salvar notícia, por favor tente novamente",
                isClosable: true
            })
        }
        setIsSaving(false)

        navigate('/banners')
        return toast({
          position: 'top-right',
            status: "success",
            title: "Notícia salva com sucesso",
            description: bannerResponse.data.message || "A notícia foi salva com sucesso",
            isClosable: true
        })

    }
    const handleCancel = () => {
        return navigate("/banners")
    }

    useEffect(() => {
        bannerWatch((data) => {
            console.log({data})
        })
    }, [])

    return (
        <>
            <Dialog
                onAccept={handleSave}
                isOpen={saveDialogIsOpen}
                onClose={saveDialogOnClose}
                title={"Salvar notícia"}
                description={"Ao confirmar, a notícia será salva"}
            />
            <CancelItemBox title={"Voltar para lista de notícias"} onClick={() => navigate('/banners')} />
            <Accordion title={"Dados da notícia"} defaultExpanded={true}>
                <BannerBox control={bannerControl as Control<any>} isEditing={!!bannerId} />
            </Accordion>
            <Container>
                <Flex className={`col-span-2 gap-2 justify-end`}>
                    <Button className={`!bg-neutral-800 !text-white`} onClick={() => handleCancel()}>Voltar</Button>
                    <Button className={`!bg-primary !text-white`} onClick={() => saveDialogOnOpen()}> Salvar {isSaving && <Spinner size={"sm"} className={`ml-1`}/>}</Button>
                </Flex>
            </Container>
        </>
    )
}