import {
    Accordion as ChakraUiAccordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    AccordionProps as ChakraUiAccordionProps,
    Box, Text
} from "@chakra-ui/react";
import {CSSProperties, ReactNode, useRef} from "react";
import {useDraggable} from "react-use-draggable-scroll";

type AccordionProps = ChakraUiAccordionProps & {
    panelClassName?: string | undefined
    headerClassName?: string | undefined
    defaultExpanded?: boolean
    title: ReactNode
    icon?: ReactNode
}


export function Accordion({children, title, icon, defaultExpanded = true, ...rest}: AccordionProps) {

    return (
        <ChakraUiAccordion defaultIndex={!!defaultExpanded ? [0] : undefined} allowToggle {...rest} className={`bg-white dark:bg-neutral-700 rounded-md ${rest?.className}`}>
            <AccordionItem className={"border-none"}>
                <AccordionButton className={`!p-4 ${rest?.headerClassName}`}>
                    <Box className={"flex-1 text-left"}>
                        <Text className={"font-medium"}>
                            {title}
                        </Text>
                    </Box>
                    {icon && (
                        <Box className={`mr-2`}>{icon}</Box>
                    )}
                    <AccordionIcon/>
                </AccordionButton>
                <AccordionPanel className={`p-4 ${rest?.panelClassName}`}>
                    {children}
                </AccordionPanel>
            </AccordionItem>
        </ChakraUiAccordion>
    )
}