import {BalanceSheets as BalanceSheetsTemplate} from "../../templates/BalanceSheets"
import {BalanceSheetCreateAndEdit} from "../../templates/BalanceSheets/CreateAndEdit";
import {BalanceSheets as ClientBalanceSheets} from "../../templates/Client/BalancheSheets"
import {AssemblySlug} from "../../templates/Client/Assemblies/Slug";
export function BalanceSheets() {
    const pathname = window.location.pathname
    if (pathname === "/cliente/prestacao-de-contas" || pathname === "/cliente/prestacao-de-contas/") {
        return <ClientBalanceSheets />
    }
    if (pathname.includes("/prestacao-de-contas/") && pathname !== "/prestacao-de-contas") {
        return <BalanceSheetCreateAndEdit />
    }
    return (
        <BalanceSheetsTemplate />
    )
}