import {
    Box,
    Flex,
    Stack,
    Text, useMediaQuery,
    useToast
} from "@chakra-ui/react";
import {Item} from "./Item";
import {AiOutlineLogout} from "react-icons/ai"
import {
    BsFacebook,
    BsInstagram,
    BsImage,
    BsFillCartFill,
    BsFilterSquareFill,
    BsFillCalendar3EventFill,
    BsNewspaper
} from "react-icons/bs"
import {FaFileInvoiceDollar, FaBox, FaThList, FaClipboardList, FaUserFriends} from "react-icons/fa"
import {ImUserTie} from "react-icons/im"
import {MdOutlineVideoSettings, MdOutlineVideoLibrary} from "react-icons/md"
import {BiRadioCircle, BiRadioCircleMarked} from "react-icons/bi"
import {HiDocumentAdd, HiDocumentText} from "react-icons/hi"
import {RiAdminFill, RiRefund2Line, RiMoneyDollarCircleFill} from "react-icons/ri"
import {useLocation, useNavigate} from "react-router-dom"
import React, {useEffect, useRef, useState} from "react";
import {Button} from "../../../components/Button";
import {RiHomeLine} from "react-icons/ri";
import {AiFillStar, AiFillPropertySafety, AiFillEdit} from "react-icons/ai";
import {useUser} from "../../../hooks/useUser";
import {CgDarkMode} from "react-icons/cg";
import {useTheme} from "../../../hooks/useTheme";
import {DropdownMenu} from "../../../components/DropdownMenu";
import {Logo} from "../../../components/Logo";
import {EventProps, UserProps} from "../../../types";
import {getMenuEvents} from "../../../services/events";
import {useMenuRoutes} from "../../../hooks/useMenuRoutes";

type NavigationProps = {
    admin?: boolean
    route: string
    title: string
    remove?: string
    icon: React.ReactNode
    isLoading?: boolean,
    validate?: () => boolean
    notFound?: boolean
    selected?: boolean
}

export function LeftNav() {
    const [isOpen, setIsOpen] = useState(true)
    const [isMobile] = useMediaQuery('(max-width: 768px)')

    const {events, getMenuEventsData} = useMenuRoutes()
    const toast = useToast()
    const {user, storeUser} = useUser()
    const location = useLocation()
    const navigate = useNavigate()
    const {toggleTheme, theme} = useTheme()


    useEffect(() => {
        if (localStorage) {
            if (localStorage.getItem("FindScrap@LeftNavIsOpen") === "true") {
                setIsOpen(true)
            } else {
                localStorage.setItem("FindScrap@LeftNavIsOpen", "false")
                setIsOpen(false)
            }
        }
    }, [localStorage])

    const disabledRoute = () => {
        return false
    }
    const validateDisabledRoute = () => {
        toast({
            position: 'top-right',
            status: "error",
            title: "Recurso indisponível",
            description: "O recurso encontra-se indisponível no momento",
            isClosable: true
        })
        return false
    }
    const navigation: NavigationProps[] = [
        {
            route: "inicio",
            title: "Início",
            icon: <RiHomeLine/>
        },
        {
            route: "cliente/assembleias",
            title: "Assembleias",
            icon: <MdOutlineVideoLibrary/>
        },
        {
            route: "cliente/prestacao-de-contas",
            title: "Prestação de contas",
            icon: <HiDocumentText/>
        },
        {
            route: "usuarios",
            title: "Usuários",
            icon: <RiAdminFill/>,
            admin: true
        },
        {
            route: "afiliados",
            title: "Filiados",
            icon: <FaUserFriends/>,
            admin: true
        },
        {
            route: "noticias",
            title: "Notícias",
            icon: <BsNewspaper/>,
            admin: true
        },
        {
            route: "banners",
            title: "Banners",
            icon: <BsNewspaper/>,
            admin: true
        },
        {
            route: "eventos",
            title: "Eventos",
            icon: <BsFillCalendar3EventFill/>,
            remove: "cliente",
            admin: true
        },
        {
            route: "diretoria",
            title: "Diretoria",
            icon: <ImUserTie/>,
            admin: true
        },
        {
            route: "convenios",
            title: "Convênios",
            icon: <FaClipboardList/>,
            admin: true
        },
        {
            route: "assembleias",
            title: "Assembleiass",
            remove: "cliente",
            icon: <MdOutlineVideoSettings/>,
            admin: true
        },
        {
            route: "prestacao-de-contas",
            title: "Prestação de contass",
            remove: "cliente",
            icon: <HiDocumentAdd/>,
            admin: true
        },
    ]

    const handleNavigateTo = (item: NavigationProps) => {
        closeMobileMenu()
        if (item.notFound) {
            return toast({
                position: 'top-right',
                status: "error",
                title: "Recurso indisponível",
                description: "O recurso encontra-se indisponível no momento",
                isClosable: true
            })
        }
        if (item.isLoading) {
            return toast({
                position: 'top-right',
                status: "error",
                title: "Aguardar carregamento",
                description: "Os dados estão sendo carregados",
                isClosable: true
            })
        }

        if (item.validate && !item.validate()) {
            return
        }

        return navigate(`/${item.route}`)

    }
    const closeMobileMenu = () => {
        if (isMobile) {
            setIsOpen(false)
        }
    }
    const handleEditUser = () => {
        if (!user()?.admin) {
            return navigate(`/afiliados/${user()?.id}`)
        }
        return navigate(`/usuarios/${user()?.id}`)
    }
    const handleLogout = () => {
        storeUser({} as UserProps)
        return navigate('/login')
    }

    useEffect(() => {
        getMenuEventsData()
    }, [])
    return (
        <>

            <Stack spacing={"0"}
                   className={`w-16 h-full transition-all bg-white dark:bg-neutral-700 ${isOpen && `!w-full md:!w-60 absolute z-50 left-0 top-0 md:!relative`}`}>
                <Flex
                    className={`${!isOpen ? `justify-center` : `justify-end`} w-full p-2 gap-2 bg-gray-300 dark:bg-neutral-900 items-center`}>
                    {isOpen ? (
                        <>
                            {/*<Button className={`!bg-neutral-800 dark:!bg-primary font-bold !text-white flex-1`}*/}
                            {/*        onClick={() => {*/}
                            {/*            toggleTheme()*/}
                            {/*        }}*/}
                            {/*>*/}
                            {/*    <CgDarkMode/>*/}
                            {/*</Button>*/}
                            <Text className={"font-light"}>Seja bem vindo, <b>{user()?.first_name}</b></Text>
                            <DropdownMenu options={[
                                {
                                    label: <Flex className={`cursor-pointer gap-2 items-center`}
                                                 onClick={() => handleEditUser()}><AiFillEdit/> Meus dados</Flex>
                                },
                                {
                                    label: <Flex className={`cursor-pointer gap-2 items-center`}
                                                 onClick={() => toggleTheme()}><CgDarkMode/> {theme === "dark" ? `Modo claro` : `Modo escuro`}
                                    </Flex>
                                },
                                {
                                    label: <Flex className={`cursor-pointer gap-2 items-center`}
                                                 onClick={() => handleLogout()}><AiOutlineLogout/> Logout</Flex>
                                },
                            ]}/>
                            <BiRadioCircleMarked className={`!text-white min-w-[30px] cursor-pointer`} onClick={() => {
                                setIsOpen(false)
                                localStorage.setItem("FindScrap@LeftNavIsOpen", "false")
                            }} size={30}/>

                        </>
                    ) : (
                        <BiRadioCircle className={` !text-white min-w-[30px] cursor-pointer`} onClick={() => {
                            setIsOpen(true)
                            localStorage.setItem("FindScrap@LeftNavIsOpen", "true")
                        }} size={30}/>
                    )}
                </Flex>
                <Stack spacing={"1"} className={`p-2 text-neutral-800 dark:text-white overflow-y-auto flex-1`}>
                    <>
                        {events?.map((item: any, key: number) => {
                            return (
                                <Item key={key} icon={<BsFillCalendar3EventFill/>} title={isOpen ? item.title : undefined}
                                      loading={false}
                                      selected={isOpen ? item?.selected : false}
                                      active={location.pathname.includes(item?.slug)}
                                      onClick={() => navigate(`/cliente/eventos/${item?.slug}`)}/>
                            )
                        })}
                        {navigation?.map((item, key) => {
                            return ((user()?.type === "administrator" && item.admin) || !item.admin) && (
                                <Item key={key} icon={item.icon} title={isOpen ? item.title : undefined} disabled={item?.notFound}
                                      loading={item?.isLoading}
                                      selected={isOpen ? item?.selected : false}
                                      active={location.pathname.includes(item.route === "scrap/home" ? "scrap" : item.route) && (item.remove ? !location.pathname.includes(item.remove) : true)}
                                      onClick={() => handleNavigateTo(item)}/>
                            )
                        })}
                    </>
                </Stack>
                {isOpen && (
                    <Flex className={`p-2 py-4 w-full justify-center`}>
                        <Logo/>
                    </Flex>
                )}
            </Stack>
        </>
    )
}
