import Echo from "laravel-echo"
import {useUser} from "./useUser";
import {useEffect, useState} from "react";
import axios from "axios";
//@ts-ignore
window.Pusher = require('pusher-js');

export function useChannels() {
    const {user} = useUser()
    const [echo, setEcho] = useState<Echo>()
    useEffect(() => {
        if (user()?.token) {
            console.log(1248987329847239847)
            console.log(user()?.token)
            setEcho(new Echo({
                broadcaster: 'pusher',
                key: 'ABCDEFG',
                secret: `HIJKLMNOP`,
                wsHost: `socket.sindafis.solution4apps.com`,
                wssHost: `socket.sindafis.solution4apps.com`,
                // wsHost: `127.0.0.1`,
                wsPort: 9075,
                wssPort: 9075,
                enabledTransports: ['ws', 'wss'],
                disableStats: true,
                encrypted: true,
                authEndpoint: `https://api.sindafis.solution4apps.com/broadcasting/auth`,
                // authEndpoint: `http://127.0.0.1:8000/broadcasting/auth`,
                cluster: `mtl`,
                auth: {
                    headers: {
                        Authorization: `Bearer ${user()?.token}`,
                        Accept: "application/json"
                    }
                },
                // authorizer: (channel: any, options: any) => {
                //     return {
                //         authorize: (socketId: any, callback: any) => {
                //             console.log({socketId, channel})
                //             axios.post('http://127.0.0.1:8000/broadcasting/auth', {
                //                 socket_id: socketId,
                //                 channel_name: channel.name
                //             }, {
                //                 headers: {
                //                     Authorization: `Bearer ${user()?.token}`,
                //                     Accept: "application/json"
                //                 }
                //             })
                //                 .then((response: any) => {
                //                     callback(false, response.data);
                //                 })
                //                 .catch((error: any) => {
                //                     console.log({error})
                //                     callback(true, error);
                //                 });
                //         }
                //     };
                // },
            }))
        }
    }, [user()?.token])

    return {echo}
}