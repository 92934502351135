import {Text} from "@chakra-ui/react"
import ReactSelect, {Props as ReactSelectProps} from "react-select"
import "./styles.css"
import {SelectOptionProps} from "../../types";
import {useController, UseControllerProps, ControllerProps, useForm} from "react-hook-form";
import {useEffect} from "react";
type SelectProps = (Partial<ReactSelectProps> & UseControllerProps) & {
    options?: SelectOptionProps[]
    label?: string
    labelClassName?: string
    onSelect?: (event: SelectOptionProps) => void
    name?: string
}

export function Select( {name = "", ...select}: SelectProps) {
    const {control: controlDefault} = useForm()
    const {fieldState, field} = useController({
        control: select.control || controlDefault,
        rules: select.rules,
        name: name,
        defaultValue: select.defaultValue
    })
    return (
        <label className={`flex flex-col ${select.labelClassName ? select.labelClassName : ``}`}>
            {select.label && (
                <Text className={"line-clamp-1 mb-2 "}>{select.label}</Text>
            )}
            <ReactSelect
                {...select}
                {...field}
                isClearable={true}
                placeholder={select.placeholder || "Selecione uma opção"}
                className={`react-select-container ${!!select.className && select.className} ${fieldState.error && `is-invalid`}`}
                classNamePrefix={`react-select`}
                options={select.options}
                noOptionsMessage={({inputValue}) => !inputValue && "Nenhuma opção disponível"}
                onChange={(e, a) => {
                    if (select.onSelect) {
                        select.onSelect(e as SelectOptionProps)
                    }
                    if (select.onChange) {
                        select.onChange(e, a)
                    }
                    if (field.onChange) {
                        field.onChange(e)
                    }

                }}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            />
        </label>
    )
}