import {Events as EventsTemplate} from "../../templates/Events"
import {EventCreateAndEdit} from "../../templates/Events/CreateAndEdit";
import {EventSlug} from "../../templates/Client/Event/Slug";
import {Guests} from "../../templates/Events/Guests";
export function Events() {
    const pathname = window.location.pathname
    if (pathname.includes("/cliente/eventos/")) {
        return <EventSlug />
    }
    if (pathname.includes("/convidados")) {
        return <Guests />
    }
    if (pathname.includes("/eventos/") && pathname !== "/eventos") {
        return <EventCreateAndEdit />
    }
    return (
        <EventsTemplate />
    )
}
