import {Accordion} from "../Accordion";
import {Badge, Flex, Spinner, Stack} from "@chakra-ui/react";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {Input} from "../Input";
import {IoMdSend} from "react-icons/io"
import {Button} from "../Button";
import {Text} from "../Text";
import {LineClamp} from "../LineClamp";
import {DropdownMenu} from "../DropdownMenu";
import {createChatMessage, deleteMessage, getChatMessages} from "../../services/chat";
import {IoIosCloseCircle} from "react-icons/io"
import {useForm} from "react-hook-form";
import {useUser} from "../../hooks/useUser";
import {useChannels} from "../../hooks/useSocket";
import {debounce} from "debounce";
import cloneDeep from "clone-deep";

type ChatProps = {
    chatId: number
}

export function Chat({chatId}: ChatProps) {
    const {user} = useUser()
    const userIsAffiliate = !user()?.profile_id
    const {echo} = useChannels()

    const {control, watch, trigger, reset} = useForm<any>()
    const [isLoading, setIsLoading] = useState(false)
    const [messages, setMessages] = useState<any[]>([])
    const [replyMessage, setReplyMessage] = useState<any>({})
    const messagesContainerRef = useRef<HTMLDivElement>(null)
    const [showSeeLastMessages, setShowSeeLastMessages] = useState(false)
    const [lastScrollHeight, setLastScrollHeight] = useState(0)
    const [isScrolling, setIsScrolling] = useState(false)
    const firstCall = useRef(false)
    const inputRef = useRef<HTMLInputElement>(null)
    const [chatConfig, setChatConfig] = useState({
        page: 1,
        total: 0,
        to: 0,
        last_page: 0
    })
    const scrollToEnd = () => {
        const top = messagesContainerRef?.current?.scrollHeight!
        let scrolling = false
        setIsScrolling(prev => {
            scrolling = prev
            return prev
        })

        if (scrolling) {
            return
        }

        messagesContainerRef?.current?.scrollTo({
            top: top + 300,
            behavior: "smooth"
        })

        setTimeout(() => setIsScrolling(false), 1000)
    }
    const sliceChatOnFirstPage = () => {
        setMessages(prev => {
            if (prev.length <= 15) {
                return prev
            }
            setChatConfig(prev => {
                return {
                    ...prev,
                    page: 1,
                    to: 15
                }
            })
            return prev.slice(prev.length - 15, prev.length)
        })
    }


    const seeLastMessages = () => {
        scrollToEnd()
        sliceChatOnFirstPage()
        setShowSeeLastMessages(false)
    }

    const listChatMessages = async (page: number) => {
        page = page ?? chatConfig?.page
        setIsLoading(true)
        const response = await getChatMessages(chatId, {
            ...chatConfig,
            page
        })
        setIsLoading(false)
        setChatConfig({
            page: page,
            total: response?.data?.total,
            to: response?.data?.to,
            last_page: response?.data?.last_page
        })
        setMessages(prev => [
            ...response?.data?.data?.reverse(),
            ...(page === chatConfig.page ? [] : prev)
        ])
    }
    const removeMessageFromChat = (message: any) => {
        setMessages(prev => {

            console.log(Math.round(prev.length / 15))
            return prev.filter((item: any) => item?.id !== message.id)
        })
    }

    const onReceiveChatMessage = async (message: any) => {
        if (message?.deleted_at) {
            return removeMessageFromChat(message)
        }
        const messageUser = message?.user?.user ?? message?.user?.affiliate
        const messageFromAffiliate = !messageUser?.profile_id

        const sameUser = messageUser.id === user()?.id && messageFromAffiliate === userIsAffiliate

        const totalScrollHeight = messagesContainerRef?.current?.scrollHeight!
        const scrollHeight = messagesContainerRef?.current?.scrollTop! + messagesContainerRef?.current?.clientHeight!

        let updateMessage = false

        await setMessages(prev => {
            if (prev.find((item) => item.id === message.id)) {
                updateMessage = true
                return prev?.map((item) => {
                    if (item.id === message.id) {
                        return message
                    }
                    return item
                })
            }
            const current = cloneDeep([
                ...prev,
                message
            ])
            if (current.length <= 15) {
                return current
            }
            if (totalScrollHeight - scrollHeight > 500) {
                return current
            }
            return current.slice(current.length - 15, current.length)
        })

        if (updateMessage) {
            return
        }
        setTimeout(() => {
            if (sameUser) {
                scrollToEnd()
                setReplyMessage({} as any)
            } else {
                if (totalScrollHeight - scrollHeight > 500) {
                    if (!sameUser) {
                        setShowSeeLastMessages(true)
                    }
                } else {
                    scrollToEnd()
                }
            }
        }, 300)
    }

    useEffect(() => {
        console.log({chatConfig})
    }, [chatConfig])

    // useEffect(() => {
    //     chatConfig.page === 1 && messagesContainerRef?.current?.scrollTo({
    //         top: 1000000,
    //         behavior: "smooth"
    //     })
    // }, [messages])

    useEffect(() => {
        if (chatId && echo) {
            echo?.channel(`presence-chat.${chatId}`)
                ?.listen(`.sendMessage`, (data: any) => {
                    console.log({data})
                    onReceiveChatMessage(data?.message)
                }).error((error: any) => {
                console.log(2, {error})
            }).subscribed((e: any) => {
                console.log("subscribed")
            }).notification((data: any) => {
                console.log(3, {data})
            })
        }
    }, [chatId, echo])

    const registerChatMessage = async (replyMessage: any) => {
        if (!await trigger()) {
            return
        }
        const payload = watch()
        const response = await createChatMessage(chatId, {...payload, chat_id: chatId, active: true, info: false, chat_message_id: replyMessage?.id ?? undefined})
        reset({
            message: ''
        })
        // await listChatMessages()

    }

    const onChatScroll = (event: Event) => {

        setLastScrollHeight(prev => {
            const totalScrollHeight = messagesContainerRef?.current?.scrollHeight!
            const scrollHeight = messagesContainerRef?.current?.scrollTop! + messagesContainerRef?.current?.clientHeight!
            // console.log({
            //     scrollHeight,
            //     prev,
            //     totalScrollHeight
            // })
            if (scrollHeight > prev) {
                // setShowSeeLastMessages(true)
            } else {
                // setShowSeeLastMessages(false)
            }

            if (scrollHeight > prev && totalScrollHeight - 200 < scrollHeight) {
                sliceChatOnFirstPage()
                setShowSeeLastMessages(false)
            }

            return scrollHeight
        })

    }

    useEffect(() => {
        if (messagesContainerRef?.current) {
            messagesContainerRef.current.addEventListener('scroll', onChatScroll, false)
            return () => messagesContainerRef?.current?.removeEventListener('scroll', onChatScroll, false)
        }
    }, [])

    const init = async() => {
        await listChatMessages(1)
    }
    useEffect(() => {
        if (chatId) {
            init()
        }
    }, [chatId])

    useEffect(() => {
        if (!firstCall?.current && messages?.length > 0) {
            scrollToEnd()
            firstCall.current = true
        }
    }, [messages])

    const handleDeleteMessage = async (messageId: number) => {
        const response = await deleteMessage(chatId, messageId)
        console.log({response})
    }
    const handleReplyMessage = async (message: any) => {
        setReplyMessage(message)
        inputRef?.current?.focus()

    }
    const Message = ({message, isReply, className}: any) => {
        const messageUser = message?.user?.user ?? message?.user?.affiliate
        const messageFromAffiliate = !messageUser?.profile_id
        const hasReply = !!message.chat_message

        const sameUser = messageUser.id === user()?.id && messageFromAffiliate === userIsAffiliate
        // const sameUser = messageUser.id === user()?.id

        const time = Math.round((new Date().getTime() - new Date(message?.created_at).getTime()) / 1000)

        const [expireTime, setExpireTime] = useState((60 * 5) - time)
        const [timer, setTimer] = useState<NodeJS.Timer>()
        const options = isReply ? [] : [
            ...((sameUser || !userIsAffiliate) && (expireTime > 0 || !userIsAffiliate) ? [
                {
                    label: `Excluir mensagem ${userIsAffiliate ? expireTime > 0 ? ` - ${expireTime} segundos` : `` : ``}`,
                    onClick: () => handleDeleteMessage(message.id)
                }
            ] : []),
            ...(!userIsAffiliate && !sameUser ? [
                {
                    label: `Responder`,
                    onClick: () => handleReplyMessage(message)
                }
            ] : [])

        ]

        useEffect(() => {
            if (expireTime < 1) {
                clearInterval(timer)
                setExpireTime(0)
            }
        }, [expireTime])

        useEffect(() => {
            setTimer(setInterval(() => {
                setExpireTime(prev => prev - 1)
            }, 1000))
        }, [])

        return (
            <Stack className={`flex-1 ${className} ${hasReply ? `` : ``}`}>
                <Flex className={`flex-1 ${sameUser && `justify-end`}`}>
                    <Stack spacing={0} className={`${messageFromAffiliate ? `bg-white dark:bg-neutral-700` : `${isReply ? `bg-white dark:bg-neutral-700` : `bg-primary/20 dark:bg-neutral-800`} `} rounded-md p-2 gap-2 relative ${isReply ? `w-full` : `w-11/12`}`}>
                        {hasReply && (
                            <Stack className={`flex-1 m-2 ${sameUser && `justify-end`}`}>
                                <Text className={`!text-sm font-light`}>Respondendo:</Text>
                                <Message message={message.chat_message} isReply={true} className={`opacity-60`} />
                            </Stack>
                        )}
                        {options?.length > 0 && (
                            <DropdownMenu buttonClassName={`absolute right-2 top-2`} options={options}/>
                        )}
                        <Flex className={`gap-2`}>
                            <div className={`block !min-h-[38px] !min-w-[38px] !h-[38px] !w-[38px] rounded-full bg-black`}/>
                            <Stack spacing={0} className={`flex-0`}>
                                <Badge
                                    className={`!bg-primary !text-white w-max`}>{new Date(message?.created_at)?.toLocaleString()}</Badge>
                                <Text
                                    className={`text-sm font-bold line-clamp-1`}>{messageUser?.first_name} {messageUser?.last_name}</Text>
                            </Stack>
                        </Flex>
                        <Text
                            className={`text-sm font-light bg-gray-100 dark:bg-neutral-600 p-2 w-full rounded-md`}> {message?.message}</Text>
                    </Stack>
                </Flex>
            </Stack>
        )
    }
    console.log({replyMessage})

    return (
        <>
            <Accordion
                title={"Sindafis Chat"}
                className={`w-full h-max`}
                panelClassName={`overflow-auto max-h-screen`}
            >
                <Stack spacing={0} className={`gap-2`}>
                    <Flex className={` relative w-full`}>

                        <Stack spacing={0} ref={messagesContainerRef}
                               className={`gap-2 h-[300px] rounded-md bg-gray-100 dark:bg-neutral-600 p-2 overflow-auto relative flex-1`}>
                            {chatConfig?.to < chatConfig?.total && (
                                <Button disabled={isLoading} btnSize={"sm"} className={`!font-light !text-xs !bg-primary !text-white !min-h-[2rem]`} onClick={() => {
                                    listChatMessages(chatConfig?.page + 1)
                                }}>{isLoading && <Spinner size={`sm`} colorScheme={`white`} className={`mr-2`} />} Carregar mais mensagens</Button>
                            )}
                            {messages?.map((message, key) => {
                                return (
                                    <Message message={message} key={key}/>
                                )
                            })}
                        </Stack>
                        {replyMessage?.id && (
                            <Stack spacing={0} className={`!p-1 gap-2 !bg-gray-200 dark:!bg-neutral-800 !absolute left-0 bottom-0 w-full rounded-md`}>
                                <Flex className={`flex-1 justify-between items-center`}>
                                    <Text className={`!text-sm font-light`}>Respondendo {replyMessage?.user?.user?.first_name}</Text>
                                    <IoIosCloseCircle size={20} className={`cursor-pointer`} onClick={() => setReplyMessage({} as any)} />

                                </Flex>
                                <Message message={replyMessage} isReply={true} />
                            </Stack>
                        )}
                        {showSeeLastMessages && (
                            <Button btnSize={"sm"} className={`!p-1 !font-light !text-sm !bg-primary !text-white !absolute left-0 bottom-0 w-full`} onClick={() => {
                                seeLastMessages()
                            }}>Ver últimas mensagens</Button>
                        )}
                    </Flex>
                    <Flex className={`rounded-md bg-gray-100 dark:bg-neutral-600 items-center`}>
                        <Input
                            control={control}
                            rules={{required: true}}
                            name={'message'}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    registerChatMessage(replyMessage)
                                }
                            }}
                            inputRef={inputRef as any}
                            labelClassName={"flex-1"}
                            className={`!border-transparent !text-sm`}
                            placeholder={`Digite sua mensagem`}
                        />
                        <Button onClick={() => registerChatMessage(replyMessage)}
                                className={`!bg-transparent hover:!bg-primary hover:!text-white`}>
                            <IoMdSend/>
                        </Button>
                    </Flex>
                </Stack>
            </Accordion>
        </>
    )
}