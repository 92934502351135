import {Flex, SimpleGrid, Spinner} from "@chakra-ui/react";
import {Input} from "../../Input";
import {Control, UseFormReset, UseFormResetField, UseFormWatch} from "react-hook-form";
import {Select} from "../../Select";
import {Button} from "../../Button";
import React, {useState} from "react";
import {TextEditor} from "../../TextEditor";
import {MediaInput} from "../../MediaInput";
import {Switch} from "../../Switch";
import {ImageUploader} from "../../ImageUploader";
import {CreatableSelect} from "../../CreatableSelect";
import {Text} from "../../Text";
import {List} from "../../List";
import {listColumns} from "./listColumns";

type SearchBoxProps = {
    control: Control<any>
    watch: UseFormWatch<any>
    resetField: UseFormResetField<any>
    reset: UseFormReset<any>
    isEditing?: boolean
    isLoading?: boolean
    isReadOnly?: boolean
    isClient?: boolean
}

export function EventBox({control, resetField, reset, watch, isEditing, isLoading, isReadOnly = false}: SearchBoxProps) {
    const handleAddTables = () => {
        const tables = watch()?.tables ? watch()?.tables : []

        const tableChairsAmount = Number(watch()?.table_chairs_amount)
        const tablesAmount = Number(watch()?.tables_amount)

        const lastTablesNumbers = tables[tables.length - 1]?.tables[tables[tables.length - 1]?.tables?.length - 1]?.number ?? 0

        const newTables = []

        for (let i = lastTablesNumbers; i < lastTablesNumbers + tablesAmount; i++) {
            newTables.push({
                number: i + 1
            })
        }

        reset({
            ...watch(),
            tables: [...tables, {
                tables: newTables,
                numbers: `${newTables[0]?.number} - ${newTables[newTables?.length - 1]?.number}`,
                chairs: tableChairsAmount
            }]
        })
        console.log(watch()?.tables)
    }
    return (
        <SimpleGrid columns={2} className={"flex-1 gap-2"}>
            <Switch isReadOnly={isReadOnly} control={control} name={'active'} labelClassName={"flex-1 col-span-2"}
                    label={"Ativo *"}
            />
            <MediaInput onlyImage={true} rules={{required: true}} isReadOnly={isReadOnly} control={control} name={'image'} labelClassName={"flex-1 col-span-2"}
                   label={"Imagem principal *"} multiple={false}
            />
            <Input rules={{required: true}} isReadOnly={isReadOnly} control={control} name={'title'} labelClassName={"flex-1"}
                   label={"Título *"}
            />
            <Input rules={{required: true}} isReadOnly={isReadOnly} control={control} name={'date'} type={`date`} labelClassName={"flex-1"}
                   label={"Data *"}
            />

            <ImageUploader  className={"flex-1 col-span-2"} />
            <TextEditor rules={{required: true}} isReadOnly={isReadOnly} control={control} name={'content'} labelClassName={"flex-1 col-span-2"}
                        label={"Conteúdo *"}
            />
            <Switch isReadOnly={isReadOnly} control={control} name={'table_reservation_required'} labelClassName={"flex-1 col-span-2"}
                    label={"Nescessário reserva de mesa"}
            />
            {watch()?.table_reservation_required && (
                <>
                    <Switch isReadOnly={isReadOnly} control={control} name={'show_on_menu'} labelClassName={"flex-1 col-span-2"}
                            label={"Mostrar no menu"}
                    />
                    <Select rules={{required: true}} control={control} options={[
                        {
                            value: 1,
                            label: 'Layout 1 (Padrão - 68 mesas)',
                            maxChairs: 68
                        }

                    ]} name={'table_layout_id'} labelClassName={"flex-1 col-span-2"}
                            label={"Layout das mesas *"}
                    />
                    <Flex  className={"flex-1 flex-col gap-2 col-span-2 bg-gray-200/30 rounded-md p-2"}>
                        <Text className={`col-span-2 font-semibold w-full`}>Adicionar mesas</Text>
                        <Flex className={`w-full gap-2 items-end`}>
                            <Input rules={{required: true}} isReadOnly={isReadOnly} control={control} name={'tables_amount'} type={'number'} labelClassName={"flex-1"}
                                   label={"Quantidade de mesas *"}
                            />
                            <Input rules={{required: true}} isReadOnly={isReadOnly} control={control} name={'table_chairs_amount'} type={'number'} labelClassName={"flex-1"}
                                   label={"Quantidade de cadeiras por mesa *"}
                            />
                            <Button className={`!bg-primary !text-white`} onClick={handleAddTables}>Adicionar</Button>
                        </Flex>
                        <List columns={listColumns({
                            onDelete: () => {}
                        })} data={watch()?.tables ?? []} />
                    </Flex>
                </>
            )}
        </SimpleGrid>
    )
}