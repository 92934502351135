import {useNavigate, useParams} from "react-router-dom";
import {Accordion} from "../../../components/Accordion";
import {Control, useForm} from "react-hook-form";
import {AgreementBox} from "../../../components/FormBox/AgreementBox";
import React, {useEffect, useState} from "react";
import {AddressProps, AgreementProps} from "../../../types";
import {createAddress, getAddressById} from "../../../services/address";
import {createAgreement, getAgreementById, updateAgreement} from "../../../services/agreements";
import {Badge, Box, Flex, Spinner, useDisclosure, useToast} from "@chakra-ui/react";
import {CancelItemBox} from "../../../components/CancelItemBox";
import {Dialog} from "../../../components/Dialog";
import {Container} from "../../../components/Container";
import {Button} from "../../../components/Button";
import {PasswordBox} from "../../../components/FormBox/PasswordBox";
import {serialize} from "object-to-formdata";
import {formatSelectsToPayload} from "../../../helpers";
import {v4 as uuid} from "uuid";
import {storageURL} from "../../../services/axios";
import {MediaProps} from "../../../components/MediaInput";

export function AgreementCreateAndEdit() {
    const [isSaving, setIsSaving] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    const {onOpen: saveDialogOnOpen, isOpen: saveDialogIsOpen, onClose: saveDialogOnClose} = useDisclosure()
    const navigate = useNavigate()
    const toast = useToast()
    const {
        control: agreementControl,
        watch: agreementWatch,
        getValues: agreementGetValues,
        reset: agreementReset,
        resetField: agreementResetField,
        trigger: agreementTrigger
    } = useForm<Partial<AgreementProps>>({
        defaultValues: {
            active: false,
            highlight: false,
        }
    })
    const params = useParams()
    const agreementId = Number(params.agreementId)

    const getAgreementData = async () => {
        setIsLoading(true)
        const response = await getAgreementById(agreementId)
        setIsLoading(false)
        agreementReset({
            ...response.data?.agreement,
            image: {
                uuid: uuid(),
                url: `${storageURL}/${response.data?.agreement?.image}`,
                type: `image`

            } as MediaProps,
            active: !!response?.data?.agreement?.active,
            highlight: !!response?.data?.agreement?.highlight
        })
    }
    useEffect(() => {

        if (agreementId) {
            getAgreementData()
        } else {
            setIsLoading(false)
        }
    }, [])


    const handleSave = async () => {
        if (!await agreementTrigger()) {
            return
        }
        let agreementPayload = formatSelectsToPayload(agreementGetValues())

        if (!agreementPayload?.image?.size) {
            agreementPayload.image = undefined
        }
        agreementPayload = serialize(agreementPayload, {
            booleansAsIntegers: true
        })
        setIsSaving(true)
        let agreementResponse: any
        if (agreementId) {
            agreementResponse = await updateAgreement(agreementId, agreementPayload)
        } else {
            agreementResponse = await createAgreement(agreementPayload)
        }
        if (agreementResponse.status !== 201 && agreementResponse.status !== 200) {
            setIsSaving(false)
            return toast({
          position: 'top-right',
                status: "error",
                title: "Erro ao salvar convênio",
                description: agreementResponse.data.message || "Houve um erro salvar convênio, por favor tente novamente",
                isClosable: true
            })
        }
        setIsSaving(false)

        navigate('/convenios')
        return toast({
          position: 'top-right',
            status: "success",
            title: "Convênio salvo com sucesso",
            description: agreementResponse.data.message || "O convênio foi salvo com sucesso",
            isClosable: true
        })

    }
    const handleCancel = () => {
        return navigate("/convenios")
    }

    return (
        <>

            <Dialog
                onAccept={handleSave}
                isOpen={saveDialogIsOpen}
                onClose={saveDialogOnClose}
                title={"Salvar convênio"}
                description={"Ao confirmar, o convênio será salvo"}
            />
            <CancelItemBox title={"Voltar para lista de convênios"} onClick={() => navigate('/convenios')} />
            <Accordion title={`Dados do convênio`} defaultExpanded={true}>
                <AgreementBox isLoading={isLoading} reset={agreementReset} watch={agreementWatch} control={agreementControl as Control<any>} isEditing={!!agreementId} />
            </Accordion>
            <Container>
                <Flex className={`col-span-2 gap-2 justify-end`}>
                    <Button className={`!bg-neutral-800 !text-white`} onClick={() => handleCancel()}>Voltar</Button>
                    <Button className={`!bg-primary !text-white`} onClick={() => saveDialogOnOpen()}> Salvar {isSaving && <Spinner size={"sm"} className={`ml-1`}/>}</Button>
                </Flex>
            </Container>
        </>
    )
}