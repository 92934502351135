import {useNavigate, useParams} from "react-router-dom";
import {Accordion} from "../../../components/Accordion";
import {Control, useForm} from "react-hook-form";
import {NoticeBox} from "../../../components/FormBox/NoticeBox";
import React, {useEffect, useState} from "react";
import {AddressProps, NoticeProps} from "../../../types";
import {createAddress, getAddressById} from "../../../services/address";
import {createNotice, getNoticeById, updateNotice} from "../../../services/notices";
import {Box, Flex, Spinner, useDisclosure, useToast} from "@chakra-ui/react";
import {CancelItemBox} from "../../../components/CancelItemBox";
import {Dialog} from "../../../components/Dialog";
import {Container} from "../../../components/Container";
import {Button} from "../../../components/Button";
import {PasswordBox} from "../../../components/FormBox/PasswordBox";
import {serialize} from "object-to-formdata";
import {storageURL} from "../../../services/axios";
import {MediaProps} from "../../../components/MediaInput";
import {v4 as uuid} from 'uuid';
import {formatSelectsToPayload} from "../../../helpers";

export function NoticeCreateAndEdit() {
    const [isSaving, setIsSaving] = useState(false)

    const {onOpen: saveDialogOnOpen, isOpen: saveDialogIsOpen, onClose: saveDialogOnClose} = useDisclosure()
    const navigate = useNavigate()
    const toast = useToast()
    const {
        control: noticeControl,
        watch: noticeWatch,
        getValues: noticeGetValues,
        reset: noticeReset,
        resetField: noticeResetField,
        trigger: noticeTrigger
    } = useForm<Partial<NoticeProps>>()
    const params = useParams()
    const noticeId = Number(params.noticeId)

    const getNoticeData = async () => {
        const response = await getNoticeById(noticeId)
        noticeReset({
            ...response.data?.notice,
            image: {
                uuid: uuid(),
                url: `${storageURL}/${response.data?.notice?.image}`,
                type: `image`

            } as MediaProps,
            active: !!response?.data?.notice?.active
        })
    }
    useEffect(() => {

        if (noticeId) {
            getNoticeData()
        }
    }, [])


    const handleSave = async () => {
        if (!await noticeTrigger()) {
            return
        }
        let noticePayload = formatSelectsToPayload(noticeGetValues())

        if (!noticePayload?.image?.size) {
            noticePayload.image = undefined
        }

        noticePayload = serialize(noticePayload, {
            booleansAsIntegers: true
        })

        let noticeResponse: any
        setIsSaving(true)
        if (noticeId) {
            noticeResponse = await updateNotice(noticeId, noticePayload)
        } else {
            noticeResponse = await createNotice(noticePayload)
        }
        if (noticeResponse.status !== 201 && noticeResponse.status !== 200) {
            setIsSaving(false)
            return toast({
          position: 'top-right',
                status: "error",
                title: "Erro ao salvar notícia",
                description: noticeResponse.data.message || "Houve um erro salvar notícia, por favor tente novamente",
                isClosable: true
            })
        }
        setIsSaving(false)

        navigate('/noticias')
        return toast({
          position: 'top-right',
            status: "success",
            title: "Notícia salva com sucesso",
            description: noticeResponse.data.message || "A notícia foi salva com sucesso",
            isClosable: true
        })

    }
    const handleCancel = () => {
        return navigate("/noticias")
    }

    useEffect(() => {
        noticeWatch((data) => {
            console.log({data})
        })
    }, [])

    return (
        <>
            <Dialog
                onAccept={handleSave}
                isOpen={saveDialogIsOpen}
                onClose={saveDialogOnClose}
                title={"Salvar notícia"}
                description={"Ao confirmar, a notícia será salva"}
            />
            <CancelItemBox title={"Voltar para lista de notícias"} onClick={() => navigate('/noticias')} />
            <Accordion title={"Dados da notícia"} defaultExpanded={true}>
                <NoticeBox control={noticeControl as Control<any>} isEditing={!!noticeId} />
            </Accordion>
            <Container>
                <Flex className={`col-span-2 gap-2 justify-end`}>
                    <Button className={`!bg-neutral-800 !text-white`} onClick={() => handleCancel()}>Voltar</Button>
                    <Button className={`!bg-primary !text-white`} onClick={() => saveDialogOnOpen()}> Salvar {isSaving && <Spinner size={"sm"} className={`ml-1`}/>}</Button>
                </Flex>
            </Container>
        </>
    )
}