import {Accordion} from "../../components/Accordion";
import {PaginationInfoProps, BalanceSheetProps} from "../../types";
import { Table } from "../../components/Table";
import {tableColumns} from "./tableColumns"
import {deleteBalanceSheet, getBalanceSheets} from "../../services/balance-sheets";
import React, {useEffect, useState} from "react";
import {Control, FieldValues, useForm} from "react-hook-form";
import {SearchBox} from "./searchBox";
import {NewItemBox} from "../../components/NewItemBox";
import {useNavigate} from "react-router-dom";
import {useDisclosure, useToast} from "@chakra-ui/react";
import {Dialog} from "../../components/Dialog";
import {debounce} from "debounce";

export function BalanceSheets() {
    const {onOpen: deleteDialogOnOpen, isOpen: deleteDialogIsOpen, onClose: deleteDialogOnClose} = useDisclosure()
    const [deleteId, setDeleteId] = useState(0)
    const toast = useToast()
    const navigate = useNavigate()
    const {
        control,
        watch
    } = useForm()
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfoProps>({
        per_page: 10,
        page: 1,
        last_page: 0,
        total: 0,
        to: 0
    })

    const [data, setData] = useState<BalanceSheetProps[]>([] as BalanceSheetProps[])

    useEffect(() => {
    }, [data])

    const getData = debounce(async (page?: number) => {
        const payload = watch()
        payload.date = watch()?.year?.value ?? undefined
        delete payload.year
        const response = await getBalanceSheets({
            per_page: paginationInfo.per_page,
            page: page ? page : paginationInfo.page,
            ...payload
        })
        if (response.isAxiosError) {
            return
        }
        setData(response.data.data.map((balanceSheet: BalanceSheetProps) => {
            balanceSheet.created_at = new Date(balanceSheet.created_at).toLocaleString('pt-br')
            if (balanceSheet.updated_at) {
                balanceSheet.updated_at = new Date(balanceSheet.updated_at).toLocaleString('pt-br')
            }
            return balanceSheet
        }))
        setPaginationInfo(oldValue => {
            return {
                ...oldValue,
                last_page: response.data.last_page,
                total: response.data.total,
                to: response.data.to,
                page: response.data.current_page
            }
        })
    }, 750)
    const onPageChange = (page: number) => {
        getData(page + 1)
    }

    useEffect(() => {
        getData()
        watch((data, {name}) => {
            getData(1)
        })
    }, [])

    const handleDelete = async () => {
        const response = await deleteBalanceSheet(deleteId)
        if (response?.status !== 200) {
            return toast({
          position: 'top-right',
                status: "error",
                title: "Erro ao deletar prestação de conta",
                description: response.data.message || "Houve um erro deletar prestação de conta, por favor tente novamente",
                isClosable: true
            })
        }
        getData()
        return toast({
          position: 'top-right',
            status: "success",
            title: "Prestação de contas removida com sucesso",
            description: response.data.message || "A prestação de conta foi removida com sucesso",
            isClosable: true
        })
    }
    return (
        <>
            <Dialog
                onAccept={handleDelete}
                isOpen={deleteDialogIsOpen}
                onClose={deleteDialogOnClose}
                title={"Deletar prestação de conta"}
                description={"Ao confirmar, a prestação de conta será removida"}
            />
            <NewItemBox title={"Nova prestação de conta"} className={""} onClick={() => navigate(`/prestacao-de-contas/cadastro`)} />
            <Accordion title={"Digite sua busca"} defaultExpanded={false} className={""}>
                <SearchBox control={control} />
            </Accordion>
            <Accordion title={"Prestação de contas"} panelClassName={`overflow-auto max-h-screen`}>
                <Table data={data} columns={tableColumns({
                    onDelete:(balanceSheetId) => {
                        setDeleteId(balanceSheetId)
                        deleteDialogOnOpen()
                    },
                })} onPageChange={onPageChange} pageCount={paginationInfo.last_page} />
            </Accordion>
        </>
    )
}