import {api} from "./axios";

export const getNotices = async (params?: any) => {
    return await api.get(`/notice-list`, {
        params
    }).catch(({response}) => {
        return response
    })
}
export const getNoticeById = async (noticeId?: number) => {
    return await api.get(`/notice-get/${noticeId}`, {
    }).catch(({response}) => {
        return response
    })
}
export const updateNotice = async (noticeId: number, payload?: any) => {
    return await api.post(`/notice-update/${noticeId}`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const createNotice = async (payload?: any) => {
    return await api.post(`/notice-register`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const deleteNotice = async (noticeId: number) => {
    return await api.delete(`/notice-delete/${noticeId}`, {
    }).catch(({response}) => {
        return response
    })
}