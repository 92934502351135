import {Accordion} from "../../components/Accordion";
import {PaginationInfoProps, EventProps} from "../../types";
import { Table } from "../../components/Table";
import {tableColumns} from "./tableColumns"
import {deleteEvent, getEvents, updateEvent} from "../../services/events";
import React, {useEffect, useState} from "react";
import {Control, FieldValues, useForm} from "react-hook-form";
import {SearchBox} from "./searchBox";
import {NewItemBox} from "../../components/NewItemBox";
import {useNavigate} from "react-router-dom";
import {useDisclosure, useToast} from "@chakra-ui/react";
import {Dialog} from "../../components/Dialog";
import {useMenuRoutes} from "../../hooks/useMenuRoutes";

export function Events() {
    const {onOpen: deleteDialogOnOpen, isOpen: deleteDialogIsOpen, onClose: deleteDialogOnClose} = useDisclosure()
    const [deleteId, setDeleteId] = useState(0)
    const toast = useToast()
    const {getMenuEventsData} = useMenuRoutes()
    const navigate = useNavigate()
    const {
        control,
        watch
    } = useForm()
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfoProps>({
        per_page: 10,
        page: 1,
        last_page: 0,
        total: 0,
        to: 0
    })

    const [data, setData] = useState<EventProps[]>([] as EventProps[])

    useEffect(() => {
    }, [data])

    const getData = async (page?: number) => {
        const response = await getEvents({
            per_page: paginationInfo.per_page,
            page: page ? page : paginationInfo.page,
            ...watch()
        })
        if (response.isAxiosError) {
            return
        }
        setData(response.data.data.map((event: EventProps) => {
            event.created_at = new Date(event.created_at).toLocaleString('pt-br')
            if (event.updated_at) {
                event.updated_at = new Date(event.updated_at).toLocaleString('pt-br')
            }

            const tables: any[] = []

            let lastChairsNumber = 0
            let firstInteraction = false
            let i = 0

            for (const table of event?.event_tables) {
                if (!firstInteraction) {
                    lastChairsNumber = table.chairs
                    firstInteraction = true
                }
                if (lastChairsNumber !== table?.chairs) {
                    lastChairsNumber = table.chairs
                    i++
                }
                console.log(tables,33, table?.chairs, lastChairsNumber, i)
                if (tables[i]?.tables?.length > 0) {
                    tables[i] = {
                        chairs: table?.chairs,
                        tables: [
                            ...tables[i]?.tables,
                            {
                                number: table.number
                            }
                        ]
                    }
                } else {
                    tables[i] = {
                        chairs: table?.chairs,
                        tables: [
                            {
                                number: table.number
                            }
                        ]
                    }
                }

            }
            event.tables = tables
            return event
        }))
        setPaginationInfo(oldValue => {
            return {
                ...oldValue,
                last_page: response.data.last_page,
                total: response.data.total,
                to: response.data.to,
                page: response.data.current_page
            }
        })
    }
    const onPageChange = (page: number) => {
        getData(page + 1)
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        watch((data, {name}) => {
            getData(1)
        })
    }, [])
    const handleEdit = (eventId: number) => {
        return navigate(`/eventos/${eventId}`)
    }
    const handleDelete = async () => {
        const response = await deleteEvent(deleteId)
        if (response?.status !== 204) {
            return toast({
          position: 'top-right',
                status: "error",
                title: "Erro ao deletar evento",
                description: response.data.message || "Houve um erro deletar evento, por favor tente novamente",
                isClosable: true
            })
        }
        getData()
        return toast({
          position: 'top-right',
            status: "success",
            title: "Evento deletado com sucesso",
            description: response.data.message || "O evento foi deletado com sucesso",
            isClosable: true
        })
    }
    const handleToggleOptions = async (event: any, value: boolean, option: string) => {
        const payload: any = {
            ...event
        }
        payload[option] = value



        delete payload.image
        const response = await updateEvent(event.id, payload)

        if (response?.status !== 200) {
            return toast({
                position: 'top-right',
                status: "error",
                title: "Erro ao atualizar evento",
                description: response.data.message || "Houve um erro ao atualizar evento, por favor tente novamente",
                isClosable: true
            })
        }
        if (option === "show_on_menu") {
            getMenuEventsData()
        }

        setData((prev) => {
            return prev.map((item) => {
                console.log(333)
                if (item?.id === event?.id) {
                    console.log(555)
                    item = {
                        ...item,
                        ...payload
                    }
                }
                return {
                    ...item
                }
            })
        })
        return toast({
            position: 'top-right',
            status: "success",
            title: "Evento atualizado com sucesso",
            isClosable: true
        })
    }

    const handleSeeGuests = (eventId: number) => {
        return navigate(`/eventos/${eventId}/convidados`)
    }
    return (
        <>
            <Dialog
                onAccept={handleDelete}
                isOpen={deleteDialogIsOpen}
                onClose={deleteDialogOnClose}
                title={"Deletar evento"}
                description={"Ao confirmar, o evento será deletado"}
            />
            <NewItemBox title={"Novo evento"} className={""} onClick={() => navigate(`/eventos/cadastro`)} />
            <Accordion title={"Digite sua busca"} defaultExpanded={false} className={""}>
                <SearchBox control={control} />
            </Accordion>
            <Accordion title={"Eventos"} panelClassName={`overflow-auto max-h-screen`}>
                <Table data={data} columns={tableColumns({
                    onEdit: handleEdit,
                    onSeeGuests: handleSeeGuests,
                    onDelete:(eventId) => {
                        setDeleteId(eventId)
                        deleteDialogOnOpen()
                    },
                    onToggleActive: (event, value) => handleToggleOptions(event, value, 'active'),
                    onToggleMenu: (event, value) => handleToggleOptions(event, value, 'show_on_menu')
                })} onPageChange={onPageChange} pageCount={paginationInfo.last_page} />
            </Accordion>
        </>
    )
}
