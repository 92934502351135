import {useParams} from "react-router-dom";
import React, {memo, useEffect, useState} from "react";
import {Accordion} from "../../../../components/Accordion";
import {Badge, Box, Flex, Spinner, Stack, Tag, TagRightIcon, useToast} from "@chakra-ui/react";
import {Text} from "../../../../components/Text";
import {Link} from "../../../../components/Link";
import {Chat} from "../../../../components/Chat";
import {Video} from "../../../../components/Video";
import Fade from "react-reveal/Fade"
import {AiFillDelete} from "react-icons/ai";
import {BsArrowsMove} from "react-icons/bs";
import {storageURL} from "../../../../services/axios";
import {
    deleteEventTableChair,
    getEventBySlug,
    registerEventTableChair,
    updateEventTableChair
} from "../../../../services/events";
import {Popover} from "../../../../components/Popover";
import {Button} from "../../../../components/Button";
import {Input} from "../../../../components/Input";
import {Control, useForm} from "react-hook-form";
import {Select} from "../../../../components/Select";
import {PaginatedSelect} from "../../../../components/PaginatedSelect";
import {getAffiliates} from "../../../../services/affiliates";
import {AffiliateProps} from "../../../../types";
import {useUser} from "../../../../hooks/useUser";
const Table = ({number, event, chairIsMoving, setChairIsMoving, userIsAffiliate, onDeleteUserChair,
                   onDeleteUserGuestChair, user, getEvent, eventSlug, toast, handleRegisterChair,
                   setUserAlreadyHasChair, setUserGuestAlreadyHasChair,
                   handleMoveChair,handleMoveChairToTable,userAlreadyHasChair,userGuestAlreadyHasChair,
                   ...rest}: { number: number } & any) => {
    const {control, watch, trigger} = useForm()
    const [isCreatingGuest, setIsCreatingGuest] = useState(false)
    const [isCreatingAffiliate, setIsCreatingAffiliate] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const tableData = event?.event_tables?.find((item: any) => item.number === number)
    const maxChairsAmount = tableData?.chairs
    const chairsAmount = tableData?.event_table_chairs?.length
    const emptyChairsAmount = maxChairsAmount - chairsAmount
    const affiliateInThisTable = tableData?.event_table_chairs?.find((chair: any) => chair.affiliate_id === user()?.id)
    const affiliateGuestInThisTable = tableData?.event_table_chairs?.find((chair: any) => chair.inviter_affiliate_id === user()?.id)
    const handleDeleteChair = async (chair: any) => {
        if (chairIsMoving?.id === chair?.id) {
            setChairIsMoving(undefined)
        }

        if (userIsAffiliate) {
            console.log(1111)
            if (chair?.affiliate_id === user()?.id) {
                console.log(2222)
                onDeleteUserChair()
            }
            if (chair?.inviter_affiliate_id === user()?.id) {
                console.log(3333)
                onDeleteUserGuestChair()
            }
        }

        setIsLoading(true)
        const response = await deleteEventTableChair(chair?.id)
        setIsLoading(false)

        if (response?.status !== 204) {
            return toast({
                position: 'top-right',
                status: "error",
                title: response?.data?.message ?? `Erro ao excluir cadeira`,
                description: response?.data?.error ?? `Erro ao excluir cadeira`,
                isClosable: true
            })
        }
        getEvent(eventSlug)
        return toast({
            position: 'top-right',
            status: "success",
            title: response?.data?.message ?? `Cadeira excluida com sucesso`,
            isClosable: true
        })
    }
    const moreThan0 = chairsAmount > 0
    const moreThan30Percent = chairsAmount >= Math.round(maxChairsAmount / 100 * 30)
    const moreThan50Percent = chairsAmount >= Math.round(maxChairsAmount / 100 * 50)
    const moreThan70Percent = chairsAmount >= Math.round(maxChairsAmount / 100 * 70)
    const full = chairsAmount === maxChairsAmount

    let style = '!bg-neutral-300 dark:!bg-neutral-600'

    if (moreThan0) {
        style = '!bg-blue-400 [&>p]:!text-white'
    }
    if (moreThan30Percent) {
        style = '!bg-green-500 [&>p]:!text-white'
    }
    if (moreThan50Percent) {
        style = '!bg-yellow-500 [&>p]:!text-white'
    }
    if (moreThan70Percent) {
        style = '!bg-orange-500 [&>p]:!text-white'
    }
    if (full) {
        style = '!bg-red-500 [&>p]:!text-white'
    }
    if (affiliateInThisTable) {
        style += ' outline outline-white'
    }
    if (affiliateGuestInThisTable) {
        style += ' outline outline-white'
    }
    const getAffiliatesData = async (page: number, search?: string) => {
        const response = await getAffiliates({
            per_page: 10,
            page,
            search_term: search
        })
        console.log({response})
        return {
            options: response?.data?.data?.map((affiliate: AffiliateProps) => {
                return {
                    value: affiliate?.id,
                    label: `${affiliate?.first_name} ${affiliate?.last_name} - ${affiliate?.registration}`,
                    data: affiliate
                }
            }) || [],
            hasMore: response?.data?.to ? !(response?.data?.to === response.data?.total) || false : false,
            additional: {
                page: page + 1,
            }
        }
    }

    const handleGetAffiliates = async (search: any, loadedOptions: any, {page}: any) => {
        return await getAffiliatesData(page, search)
    }

    const handleSubmit = async (callback?: () => void) => {
        setIsLoading(true)
        await handleRegisterChair(tableData, isCreatingGuest, isCreatingAffiliate, watch, trigger)
        callback && callback()
        setIsLoading(false)
    }

    const handleBlockChair = async (callback?: () => void) => {
        setIsLoading(true)

        const response = await registerEventTableChair(tableData?.id, {
            name: 'Autoridade/Político'
        })

        if (response?.status !== 201) {

            return toast({
                position: 'top-right',
                status: "error",
                title: response?.data?.message ?? "Erro ao bloquear cadeira",
                description: response?.data?.error ?? "Contate o administrador do servidor",
                isClosable: true
            })
        }
        setIsLoading(false)
        callback && callback()
        return toast({
            position: 'top-right',
            status: "success",
            title: "Mesa bloqueada com sucesso",
            isClosable: true
        })
    }


    const verifyUserAlreadyHasChair = () => {
        const find = tableData?.event_table_chairs?.find((chair: any) => {
            if (chair?.affiliate_id === user()?.id) {
                return true
            }
            return false
        })

        if (find) {
            setUserAlreadyHasChair(true)
        }
    }

    const verifyUserGuestAlreadyHasChair = () => {
        const find = tableData?.event_table_chairs?.find((chair: any) => {
            if (chair?.inviter_affiliate_id === user()?.id) {
                return true
            }
            return false
        })

        if (find) {
            setUserGuestAlreadyHasChair(true)
        }
    }

    useEffect(() => {
        if (tableData) {
            verifyUserGuestAlreadyHasChair()
            verifyUserAlreadyHasChair()
        }
    }, [event])

    if (!tableData) {
        return (
            <Flex
                className={`w-8 h-8 rounded-full border justify-center items-center !opacity-0 ${rest?.className}`}>
                <Text className={`!`}>{number}</Text>
            </Flex>
        )
    }

    return (
        <Popover
            header={<Tag className={`${style}`}><Text className={`!font-light `}>MESA {number} - {chairsAmount}/{maxChairsAmount}</Text></Tag>}
            body={<Stack>
                {tableData?.event_table_chairs?.map((chair: any) => {

                    if (userIsAffiliate && !(chair?.affiliate_id === user()?.id || chair?.inviter_affiliate_id === user()?.id)) {
                        return <></>
                    }
                    return (
                        <Tag
                            className={`${(chairIsMoving?.id === chair?.id || chair?.affiliate_id === user()?.id || chair?.inviter_affiliate_id === user()?.id) ? `!border !border-primary` : ``} !bg-neutral-300 dark:!bg-white [&>svg>path]:fill-red-500 [&>svg]:min-w-[14px] [&>svg]:cursor-pointer gap-2`}>
                            <Text
                                className={`!font-light ${(chairIsMoving?.id === chair?.id || chair?.affiliate_id === user()?.id || chair?.inviter_affiliate_id === user()?.id) ? ` !text-primary` : `!text-black`}`}>{chair?.name} {chair?.inviter ? ` - ${chair?.inviter?.registration}` : ``}</Text>
                            <Flex className={`flex-1`}/>
                            {isLoading ? <Spinner size={`sm`} className={`mr-2`}/> : (
                                <>
                                    {(
                                        userIsAffiliate && chair?.affiliate_id === user()?.id ||
                                        userIsAffiliate && chair?.inviter_affiliate_id === user()?.id ||
                                        !userIsAffiliate
                                    ) && (
                                        <>
                                            {!userIsAffiliate && (
                                                <BsArrowsMove onClick={() => handleMoveChair(chair)}/>
                                            )}
                                            <AiFillDelete onClick={() => handleDeleteChair(chair)}/>
                                        </>
                                    )}
                                </>
                            )}
                        </Tag>
                    )
                })}
                {(!tableData?.event_table_chairs?.find((chair: any) => chair.id === chairIsMoving?.id) && chairIsMoving) && (
                    <Button className={`!bg-primary !text-white !text-xs !line-clamp-1`}
                            onClick={() => handleMoveChairToTable(tableData)}>Adicionar {chairIsMoving?.name}</Button>
                )}
                {(userIsAffiliate && !full && !isCreatingGuest) && (
                    <>
                        {!userAlreadyHasChair && (
                            <Button className={`!bg-primary !text-white`} isDisabled={isLoading} onClick={() => handleSubmit()}>Reservar assento</Button>
                        )}
                        {!userGuestAlreadyHasChair && (
                            <Button className={`!bg-primary !text-white`} isDisabled={isLoading} onClick={() => {
                                setIsCreatingGuest(true)
                                setIsCreatingAffiliate(false)
                            }}>Reservar p/ convidado</Button>
                        )}
                    </>
                )}
                {(!userIsAffiliate && !isCreatingGuest && !isCreatingAffiliate && !full) && (
                    <>
                        <Button className={`!bg-primary !text-white`} isDisabled={isLoading} onClick={() => {
                            setIsCreatingGuest(false)
                            setIsCreatingAffiliate(true)
                        }}>Adicionar filiado</Button>
                        <Button className={`!bg-primary !text-white`} isDisabled={isLoading} onClick={() => {
                            setIsCreatingGuest(true)
                            setIsCreatingAffiliate(false)
                        }}>Adicionar convidado</Button>
                        <Button className={`!bg-red-500 !text-white`} isDisabled={isLoading} onClick={() => {
                            handleBlockChair(() => {
                                getEvent(eventSlug)
                            })
                        }}>Bloquear cadeira</Button>
                    </>
                )}
                {isCreatingAffiliate && (
                    <PaginatedSelect isDisabled={isLoading} onLoadingOptions={handleGetAffiliates}
                                     rules={{required: true}} control={control as Control<any>} name={"affiliate"}
                                     placeholder={"Filiado *"} className={"w-full"}
                                     labelClassName={"w-full"}/>
                )}
                {isCreatingGuest && !userIsAffiliate && (
                    <PaginatedSelect isDisabled={isLoading} onLoadingOptions={handleGetAffiliates}
                                     control={control as Control<any>} name={"inviter"}
                                     placeholder={"Filiado responsável"} className={"w-full"}
                                     labelClassName={"w-full"}/>
                )}
                {isCreatingGuest && (
                    <>
                        <Text className={`!text-red-500 !text-sm`}>O convidado não poderá ser um auditor não filiado e deverá apresentar um documento com foto no dia do evento</Text>
                        <Input rules={{required: true}} isDisabled={isLoading} control={control as Control<any>}
                               name={"name"} placeholder={"Nome do convidado *"} className={"w-full"}
                               labelClassName={"w-full"}/>
                    </>
                )}
                {(isCreatingGuest || isCreatingAffiliate) && (
                    <>
                        <Button className={`!bg-primary !text-white`} isDisabled={isLoading}
                                onClick={() => handleSubmit(() => {
                                    setIsCreatingGuest(false)
                                    setIsCreatingAffiliate(false)
                                })}>{isLoading &&
                            <Spinner size={`sm`} className={`mr-2`}/>}Confirmar</Button>
                        <Button className={`!bg-red-500 !text-white`} isDisabled={isLoading} onClick={() => {
                            setIsCreatingGuest(false)
                            setIsCreatingAffiliate(false)
                        }}>Cancelar</Button>
                    </>
                )}
            </Stack>}
        >
            <Flex
                className={`w-8 h-8 cursor-pointer drop-shadow rounded-xl justify-center items-center ${style} ${rest?.className}`}>
                <Text className={`!font-light `}>{number}</Text>
            </Flex>
        </Popover>
    )
}

export function EventSlug() {
    const params = useParams()
    const eventSlug = String(params.eventSlug)
    const [event, setEvent] = useState<any>({})
    const [isLoading, setIsLoading] = useState(false)
    const [eventConfig, setEventConfig] = useState<any>()
    const [chairIsMoving, setChairIsMoving] = useState<any>()
    const [userAlreadyHasChair, setUserAlreadyHasChair] = useState(false)
    const [userGuestAlreadyHasChair, setUserGuestAlreadyHasChair] = useState(false)

    const {user} = useUser()
    const userIsAffiliate = !user()?.profile_id
    const toast = useToast()
    const getEvent = async (slug: string) => {
        setIsLoading(true)
        const response = await getEventBySlug(slug)
        setIsLoading(false)
        setEvent(response?.data?.event)

    }

    useEffect(() => {
        if (eventSlug) {
            getEvent(eventSlug)
        }
    }, [eventSlug])

    const handleMoveChair = (chair: any) => {
        if (chairIsMoving?.id === chair?.id) {
            setChairIsMoving(undefined)
            return toast({
                position: 'top-right',
                status: "warning",
                title: `Você não está mais movendo o filiado ${chair?.name}`,
                isClosable: true
            })
        } else {
            setChairIsMoving(chair)
            return toast({
                position: 'top-right',
                status: "warning",
                title: `Você está movendo o filiado ${chair?.name}`,
                isClosable: true
            })
        }
    }
    const handleMoveChairToTable = async (table: any) => {
        const payload = {
            event_table_id: table?.id,
            name: chairIsMoving?.name
        }

        const response = await updateEventTableChair(chairIsMoving?.id, payload)

        if (response?.status !== 200) {

            return toast({
                position: 'top-right',
                status: "error",
                title: response?.data?.message ?? "Erro ao atualizar cadeira",
                description: response?.data?.error ?? "Erro ao atualizar cadeira",
                isClosable: true
            })
        }

        setChairIsMoving(undefined)
        await getEvent(eventSlug)

        return toast({
            position: 'top-right',
            status: "success",
            title: response?.data?.message ?? "Cadeira atualizada com sucesso",
            isClosable: true
        })
    }
    const handleRegisterChair = async (table: any, isCreatingGuest: boolean, isCreatingAffiliate: boolean, watch: any, trigger: any) => {

        if (!await trigger()) {
            return
        }
        let payload: any
        if (userIsAffiliate) {
            payload = {
                affiliate_id: user()?.id,
                name: `${user()?.first_name} ${user()?.last_name}`
            }
        }
        if (isCreatingGuest) {
            payload = {
                inviter_affiliate_id: userIsAffiliate ? user()?.id : watch()?.inviter?.value,
                name: watch()?.name
            }
        }
        if (isCreatingAffiliate) {
            payload = {
                affiliate_id: watch()?.affiliate?.value,
                name: `${watch()?.affiliate?.data?.first_name} ${watch()?.affiliate?.data?.last_name}`
            }
        }

        const response = await registerEventTableChair(table?.id, payload)

        if (response?.status !== 201) {

            return toast({
                position: 'top-right',
                status: "error",
                title: response?.data?.message ?? "Erro ao registrar cadeira",
                description: response?.data?.error ?? "Erro ao registrar cadeira",
                isClosable: true
            })
        }

        await getEvent(eventSlug)

        return toast({
            position: 'top-right',
            status: "success",
            title: response?.data?.message ?? "Cadeira registrada com sucesso",
            isClosable: true
        })
    }

    const onDeleteUserChair = () => {
        setUserAlreadyHasChair(prev => {
            console.log({prev})
            return false
        })
    }
    const onDeleteUserGuestChair = () => {
        setUserGuestAlreadyHasChair(prev => {
            console.log({prev})
            return false
        })
    }

    useEffect(() => {
        console.log({userAlreadyHasChair, userGuestAlreadyHasChair})
    }, [userAlreadyHasChair, userGuestAlreadyHasChair])

    const restOfTables = {
        event, chairIsMoving, setChairIsMoving, userIsAffiliate, onDeleteUserChair,
        onDeleteUserGuestChair, user, getEvent, eventSlug, toast, handleRegisterChair,
        setUserAlreadyHasChair, setUserGuestAlreadyHasChair,
        handleMoveChair,handleMoveChairToTable,userAlreadyHasChair,userGuestAlreadyHasChair,
    }

    return (
        <Flex className={`w-full gap-5 flex-wrap md:flex-nowrap`}>
            <Accordion
                title={
                    <Flex className={`gap-2 items-center`}>
                        <Text>{event?.title}</Text>
                    </Flex> as any
                }
                className={`h-max w-full `}
                panelClassName={`overflow-auto max-h-screen`}
            >
                <Stack spacing={0} className={`gap-2 w-[1000px]`}>
                    <div dangerouslySetInnerHTML={{__html: event?.content}}/>
                    <div className={`flex flex-col justify-center rounded-xl bg-gray-200 dark:bg-neutral-800 !h-auto w-full p-2`}>
                        <Flex className={`w-full h-[600px] justify-center`}>
                            <Stack spacing={0} className={`h-full w-[800px] justify-center`}>
                                <Flex
                                    className={`border border-r-gray-300 border-b-gray-300 border-black dark:border-neutral-600 rounded-xl h-[90%] w-full flex-col items-center p-2`}>
                                    <Flex
                                        className={` rounded-xl bg-gray-100 dark:bg-neutral-700 w-[300px] justify-center mb-2 h-max p-2`}>
                                        <Text className={`! `}>PALCO</Text>
                                    </Flex>
                                    <Flex className={`w-full h-full justify-center `}>
                                    <Flex className={`h-full flex-col justify-between py-4 w-[50px] items-center`}>
                                            <Flex
                                                className={` rounded-xl bg-gray-300 dark:bg-neutral-900 h-max  p-2 rotate-90`}>
                                                <Text className={`!`}>BUFFET</Text>
                                            </Flex>
                                            <Flex
                                                className={` rounded-xl bg-gray-300 dark:bg-neutral-900 mr-24 min-w-[150px] items-center justify-center   h-max  p-4 rotate-90`}>
                                                <Text className={`!`}>OPEN BAR</Text>
                                            </Flex>
                                            <Flex
                                                className={` rounded-xl bg-gray-300 dark:bg-neutral-900 h-max  p-2 rotate-90`}>
                                                <Text className={`!`}>BUFFET</Text>
                                            </Flex>
                                        </Flex>
                                        <Flex className={`h-[450px] w-full items-end justify-center gap-6 [&>div]:gap-2 `}>
                                            
                                            <Stack spacing={0}>
                                                <Flex className={`flex-1`}/>
                                                
                                                <Table  {...restOfTables} number={10} />
                                                <Table  {...restOfTables} number={9}/>
                                                <Table  {...restOfTables} number={8}/>
                                                <Table  {...restOfTables} number={7}/>
                                                <Table  {...restOfTables} number={6}/>
                                                <Table  {...restOfTables} number={5}/>
                                                <Table  {...restOfTables} number={4}/>
                                                <Table  {...restOfTables} number={3}/>
                                                <Table  {...restOfTables} number={2}/>
                                                <Table  {...restOfTables} number={1}/>
                                                <Table  {...restOfTables} number={91}/>
                                               
                                            </Stack>
                                            <Stack spacing={0}>
                                                <Table  {...restOfTables} number={20}/>
                                                <Table  {...restOfTables} number={19}/>
                                                <Table  {...restOfTables} number={18}/>
                                                <Table  {...restOfTables} number={17}/>
                                                <Table  {...restOfTables} number={16}/>
                                                <Table  {...restOfTables} number={15}/>
                                                <Table  {...restOfTables} number={14}/>
                                                <Table  {...restOfTables} number={13}/>
                                                <Table  {...restOfTables} number={12}/>
                                                <Table  {...restOfTables} number={11}/>
                                                <Table  {...restOfTables} number={92}/>
                                            </Stack>
                                            <Stack spacing={0}>
                                                <Table  {...restOfTables} number={30}/>
                                                <Table  {...restOfTables} number={29}/>
                                                <Table  {...restOfTables} number={28}/>
                                                <Table  {...restOfTables} number={27}/>
                                                <Table  {...restOfTables} number={26}/>
                                                <Table  {...restOfTables} number={25}/>
                                                <Table  {...restOfTables} number={24}/>
                                                <Table  {...restOfTables} number={23}/>
                                                <Table  {...restOfTables} number={22}/>
                                                <Table  {...restOfTables} number={21}/>
                                                <Table  {...restOfTables} number={93}/>
                                            </Stack>
                                            <Stack className={`relative`} spacing={0}>
                                            <div className={` rounded-xl  dark:bg-neutral-700 w-[300px] justify-center mb-2 h-max p-2 absolute bottom-80 text-center`} >PISTA DE DANÇA</div>
                                                
                                                <Table  {...restOfTables} number={35}/>
                                                <Table  {...restOfTables} number={34}/>
                                                <Table  {...restOfTables} number={33}/>
                                                <Table  {...restOfTables} number={32}/>
                                                <Table  {...restOfTables} number={31}/>
                                                <Table  {...restOfTables} number={94}/>
                                            </Stack>
                                            <Stack  spacing={0}>
                                            
                                                <Table  {...restOfTables} number={40}/>
                                                <Table  {...restOfTables} number={39}/>
                                                <Table  {...restOfTables} number={38}/>
                                                <Table  {...restOfTables} number={37}/>
                                                <Table  {...restOfTables} number={36}/>
                                                <Table  {...restOfTables} number={95}/>
                                            </Stack>
                                            <Stack spacing={0}>
                                                <Table  {...restOfTables} number={45}/>
                                                <Table  {...restOfTables} number={44}/>
                                                <Table  {...restOfTables} number={43}/>
                                                <Table  {...restOfTables} number={42}/>
                                                <Table  {...restOfTables} number={41}/>
                                                <Table  {...restOfTables} number={96}/>
                                            </Stack>
                                            <Stack spacing={0}>
                                                <Table  {...restOfTables} number={50}/>
                                                <Table  {...restOfTables} number={49}/>
                                                <Table  {...restOfTables} number={48}/>
                                                <Table  {...restOfTables} number={47}/>
                                                <Table  {...restOfTables} number={46}/> 
                                                <Table  {...restOfTables} number={97}/>
                                            </Stack>
                                            <Stack spacing={0}>
                                                <Table  {...restOfTables} number={55}/>
                                                <Table  {...restOfTables} number={54}/>
                                                <Table  {...restOfTables} number={53}/>
                                                <Table  {...restOfTables} number={52}/>
                                                <Table  {...restOfTables} number={51}/> 
                                                <Table  {...restOfTables} number={98}/>
                                            </Stack>
                                            <Stack spacing={0}>
                                                <Table  {...restOfTables} number={60}/>
                                                <Table  {...restOfTables} number={59}/>
                                                <Table  {...restOfTables} number={58}/>
                                                <Table  {...restOfTables} number={57}/>
                                                <Table  {...restOfTables} number={56}/> 
                                                <Table  {...restOfTables} number={99}/>
                                            </Stack>
                                            <Stack spacing={0}>
                                                <Flex className={`flex-1`}/>
                                                <Table  {...restOfTables} number={70} />
                                                <Table  {...restOfTables} number={69}/>
                                                <Table  {...restOfTables} number={68}/>
                                                <Table  {...restOfTables} number={67}/>
                                                <Table  {...restOfTables} number={66}/>
                                                <Table  {...restOfTables} number={65}/>
                                                <Table  {...restOfTables} number={64}/>
                                                <Table  {...restOfTables} number={63}/>
                                                <Table  {...restOfTables} number={62}/>
                                                <Table  {...restOfTables} number={61}/>
                                                <Table  {...restOfTables} number={100}/>
                                            </Stack>
                                            <Stack spacing={0}>
                                                <Table  {...restOfTables} number={80}/>
                                                <Table  {...restOfTables} number={79}/>
                                                <Table  {...restOfTables} number={78}/>
                                                <Table  {...restOfTables} number={77}/>
                                                <Table  {...restOfTables} number={76}/>
                                                <Table  {...restOfTables} number={75}/>
                                                <Table  {...restOfTables} number={74}/>
                                                <Table  {...restOfTables} number={73}/>
                                                <Table  {...restOfTables} number={72}/>
                                                <Table  {...restOfTables} number={71}/>
                                                <Table  {...restOfTables} number={101}/>
                                            </Stack>
                                            <Stack spacing={0}>
                                                <Table  {...restOfTables} number={90}/>
                                                <Table  {...restOfTables} number={89}/>
                                                <Table  {...restOfTables} number={88}/>
                                                <Table  {...restOfTables} number={87}/>
                                                <Table  {...restOfTables} number={86}/>
                                                <Table  {...restOfTables} number={85}/>
                                                <Table  {...restOfTables} number={84}/>
                                                <Table  {...restOfTables} number={83}/>
                                                <Table  {...restOfTables} number={82}/>
                                                <Table  {...restOfTables} number={81}/>
                                                <Table  {...restOfTables} number={102}/>
                                            </Stack>
                                           
                                        </Flex>
                                        <Flex className={`h-full flex-col justify-between py-4 w-[50px] items-center`}>
                                            <Flex
                                                className={`rounded-xl bg-gray-300 dark:bg-neutral-900 h-max  p-2 rotate-90`}>
                                                <Text className={`!`}>BUFFET</Text>
                                            </Flex>
                                            <Flex
                                                className={`rounded-xl bg-gray-300 dark:bg-neutral-900 h-max  p-2 rotate-90`}>
                                                <Text className={`!`}>BUFFET</Text>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Stack>
                        </Flex>
                    </div>
                    <div dangerouslySetInnerHTML={{__html: event?.description}}/>
                </Stack>
            </Accordion>
        </Flex>
    )
}
