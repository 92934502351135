import {Banners as BannersTemplate} from "../../templates/Banners"
import {BannerCreateAndEdit} from "../../templates/Banners/CreateAndEdit";
export function Banners() {
    const pathname = window.location.pathname
    if (pathname.includes("/banners/") && pathname !== "/banners") {
        return <BannerCreateAndEdit />
    }
    return (
        <BannersTemplate />
    )
}