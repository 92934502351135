import {SimpleGrid} from "@chakra-ui/react";
import {Input} from "../../components/Input";
import {Control, UseFormResetField} from "react-hook-form";
import {Switch} from "../../components/Switch";
type SearchBoxProps = {
    control: Control<any>
}
export function SearchBox({control}: SearchBoxProps) {
    return (
        <SimpleGrid columns={2} className={"flex-1 gap-2"}>
            <Switch control={control} name={'active'} labelClassName={"flex-1 col-span-2"}
                    label={"Ativo"}
            />
            <Switch control={control} name={'live'} labelClassName={"flex-1 col-span-2"}
                    label={"Ao vivo"}
            />
            <Input control={control} name={'search_term'} labelClassName={"flex-1 col-span-2"}
                   placeholder={"Busque por alguma coisa"} label={"Busque por alguma coisa"}
            />
        </SimpleGrid>
    )
}