import {Accordion} from "../../components/Accordion";
import {PaginationInfoProps, AffiliateProps} from "../../types";
import { Table } from "../../components/Table";
import {tableColumns} from "./tableColumns"
import {deleteAffiliate, getAffiliates, updateAffiliate} from "../../services/affiliates";
import React, {useEffect, useState} from "react";
import {Control, FieldValues, useForm} from "react-hook-form";
import {SearchBox} from "./searchBox";
import {NewItemBox} from "../../components/NewItemBox";
import {useNavigate} from "react-router-dom";
import {useDisclosure, useToast} from "@chakra-ui/react";
import {Dialog} from "../../components/Dialog";
import {debounce} from "debounce";

export function Affiliates() {
    const {onOpen: deleteDialogOnOpen, isOpen: deleteDialogIsOpen, onClose: deleteDialogOnClose} = useDisclosure()
    const [deleteId, setDeleteId] = useState(0)
    const toast = useToast()
    const navigate = useNavigate()
    const {
        control,
        watch
    } = useForm()
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfoProps>({
        per_page: 10,
        page: 1,
        last_page: 0,
        total: 0,
        to: 0
    })

    const [data, setData] = useState<AffiliateProps[]>([] as AffiliateProps[])

    useEffect(() => {
    }, [data])

    const getData = debounce(async (page?: number) => {
        const response = await getAffiliates({
            per_page: paginationInfo.per_page,
            page: page ? page : paginationInfo.page,
            ...watch()

        })
        if (response.isAxiosError) {
            return
        }
        setData(response.data.data.map((affiliate: AffiliateProps) => {
            affiliate.created_at = new Date(affiliate.created_at).toLocaleString('pt-br')
            if (affiliate.updated_at) {
                affiliate.updated_at = new Date(affiliate.updated_at).toLocaleString('pt-br')
            }
            return affiliate
        }))
        setPaginationInfo(oldValue => {
            return {
                ...oldValue,
                last_page: response.data.last_page,
                total: response.data.total,
                to: response.data.to,
                page: response.data.current_page
            }
        })
    }, 750)
    const onPageChange = (page: number) => {
        getData(page + 1)
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        watch((data, {name}) => {
            getData(1)
        })
    }, [])
    const handleEdit = (affiliateId: number) => {
        return navigate(`/afiliados/${affiliateId}`)
    }
    const handleDelete = async () => {
        const response = await deleteAffiliate(deleteId)
        if (response?.status !== 204) {
            return toast({
          position: 'top-right',
                status: "error",
                title: "Erro ao deletar filiado",
                description: response.data.message || "Houve um erro deletar o afiliado, por favor tente novamente",
                isClosable: true
            })
        }
        getData()
        return toast({
          position: 'top-right',
            status: "success",
            title: "Afiliado deletado com sucesso",
            description: response.data.message || "O filiado foi deletado com sucesso",
            isClosable: true
        })
    }
    const handleToggleOptions = async (affiliate: any, value: any, option: string) => {
        const payload: any = {
            ...affiliate
        }
        payload[option] = value
        const response = await updateAffiliate(affiliate.id, payload)

        if (response?.status !== 200) {
            return toast({
                position: 'top-right',
                status: "error",
                title: "Erro ao atualizar filiado",
                description: response.data.message || "Houve um erro ao atualizar filiado, por favor tente novamente",
                isClosable: true
            })
        }
        return toast({
            position: 'top-right',
            status: "success",
            title: "Afiliado atualizado com sucesso",
            isClosable: true
        })
    }
    return (
        <>
            <Dialog
                onAccept={handleDelete}
                isOpen={deleteDialogIsOpen}
                onClose={deleteDialogOnClose}
                title={"Deletar afiliado"}
                description={"Ao confirmar, o afiliado será deletado"}
            />
            <NewItemBox title={"Novo afiliado"} className={""} onClick={() => navigate(`/afiliados/cadastro`)} />
            <Accordion title={"Digite sua busca"} defaultExpanded={false} className={""}>
                <SearchBox control={control} />
            </Accordion>
            <Accordion title={"Afiliados"} panelClassName={`overflow-auto max-h-screen`}>
                <Table data={data} columns={tableColumns({
                    onEdit: handleEdit,
                    onDelete:(affiliateId) => {
                        setDeleteId(affiliateId)
                        deleteDialogOnOpen()
                    },
                    onToggleActive: (affiliate, active) => handleToggleOptions(affiliate, active ? 1 : 2, 'status_id')
                })} onPageChange={onPageChange} pageCount={paginationInfo.last_page} />
            </Accordion>
        </>
    )
}