import {Flex, SimpleGrid, Spinner} from "@chakra-ui/react";
import {Input} from "../../Input";
import {Control, UseFormReset, UseFormWatch} from "react-hook-form";
import {Select} from "../../Select";
import {Button} from "../../Button";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {TextEditor} from "../../TextEditor";
import {MediaInput} from "../../MediaInput";
import {getAffiliateOffices} from "../../../services/affiliates";
import {getBoardTypes} from "../../../services/boards";
import {getAgreementTypes} from "../../../services/agreements";
import {CreatableSelect} from "../../CreatableSelect";
import {Switch} from "../../Switch";
import {ImageUploader} from "../../ImageUploader";
import {FileInput} from "../../FileInput";

type SearchBoxProps = {
    control: Control<any>
    isEditing?: boolean
    isLoading?: boolean
    isReadOnly?: boolean
    isClient?: boolean
    reset: UseFormReset<any>
    watch: UseFormWatch<any>
}

export function BalanceSheetBox({control, isEditing, isLoading, reset, watch, isReadOnly = false}: SearchBoxProps) {
    const [types, setTypes] = useState<any>([])
    const firstCall = useRef(false)

    const getTypes = async () => {
        const response = await getAgreementTypes()

        const formattedTypes = response?.data?.data?.map((type: any) => {
            return {
                label: type?.name.replaceAll("_", " "),
                value: type?.id
            }
        })

        setTypes(formattedTypes)

        if (isEditing) {
            reset && reset({
                ...watch(),
                type_id: formattedTypes?.find((type: any) => type?.value === watch()?.type_id)
            })
        }
    }

    const watchCallback = useCallback(async (data :any, {name} :any) => {
        console.log({data, name, firstCall})

        // const selectKeys: any = {
        //     "birth_state_id": states,
        //     "birth_city_id": cities
        // }
        //

    }, [firstCall])

    useEffect(() => {
        if (!isLoading && !firstCall?.current) {
            watch && watch((data, current) => watchCallback(data, current))
            getTypes()
            firstCall.current = true
        }

    }, [isLoading])
    const years = ["2010", "2011", "2012", "2013", "2014", "2015", "2016", "2017", "2018", "2019", "2020", "2021", "2022", "2023"]

    return (
        <SimpleGrid columns={2} className={"flex-1 gap-2"}>
            <Input rules={{required: true}} isReadOnly={isReadOnly} control={control} name={'title'} labelClassName={"flex-1"}
                   label={"Título *"}
            />
            <Select control={control} labelClassName={"flex-1"} name={"year"} label={"Selecione um ano"}
                    placeholder={"Selecione um ano"} options={years?.reverse()?.map((item) => {
                return {
                    value: item,
                    label: item
                }
            })} />
            <FileInput rules={{required: true}} isReadOnly={isReadOnly} control={control} name={'document'} labelClassName={"flex-1 col-span-2"}
                       label={"Documento *"} multiple={false}
            />
        </SimpleGrid>
    )
}