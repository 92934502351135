import {UserProps} from "../types";

export function useUser () {
    const user = (): UserProps | null => {
        try {
            const userData = JSON.parse(localStorage?.getItem("Sindafis@UserData")!) as UserProps
            if (!userData || !userData.id) {
                return null
            }
            return userData
        } catch (e) {
            return null
        }
    }

    const storeUser = (user: UserProps): boolean => {
        try {
            if (!user?.id) {
                localStorage.removeItem("Sindafis@UserData")
                return true
            }
            localStorage?.setItem("Sindafis@UserData", JSON.stringify(user))
            return true
        } catch (e) {
            return false
        }
    }

    return {
        user,
        storeUser
    }
}