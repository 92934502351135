import React, {createContext, useContext, useEffect, useState} from "react";
import {EventProps} from "../types";
import {getMenuEvents} from "../services/events";
type MenuRoutesContextProps = {
    getMenuEventsData: () => void
    events: EventProps
}
type MenuRoutesProviderProps = {
    children?: React.ReactNode
}
const MenuRoutesContext = createContext({} as MenuRoutesContextProps)

export function MenuRoutesProvider({children}: MenuRoutesProviderProps) {
    const [events, setEvents] = useState<EventProps[]>([])

    const getMenuEventsData = async () => {
        try {
            const response = await getMenuEvents()
            setEvents(response?.data?.data)
        }catch (e) {

        }
    }
    useEffect(() => {
    }, [])
    return (
        <MenuRoutesContext.Provider value={{getMenuEventsData, events}}>
            {children}
        </MenuRoutesContext.Provider>
    )

}

export function useMenuRoutes() {
    return useContext(MenuRoutesContext)
}