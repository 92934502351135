import {
    Box,
    Flex,
    Input as ChakraUiInput,
    InputProps as ChakraUiInputProps, SimpleGrid,
    Stack,
    Text, useMediaQuery,
    useToast
} from '@chakra-ui/react'
import {FaFileAlt} from "react-icons/fa"
import {Control, FieldError, useController, UseControllerProps, useForm} from "react-hook-form";
import React, {ChangeEvent, createRef, useEffect, useRef, useState} from "react";
import {AiFillCloseCircle} from "react-icons/ai";
import {v4 as uuid} from 'uuid';

type InputProps = (ChakraUiInputProps & Partial<UseControllerProps>) & {
    label?: string
    labelClassName?: string
    name?: string
    onSelectInvalidType?: () => void
    onRemoveFile?: (file: FileProps) => void
    onlyImage?: boolean
    onlyVideo?: boolean
}
export type FileProps = {
    id?: number
    uuid: string
    name?: string
    url: string
    file?: File
}

export function FileInput({
                               label,
                               labelClassName,
                               onSelectInvalidType,
                               onRemoveFile,
                               name = "",
                               multiple = true,
                               ...input
                           }: InputProps) {
    const [isMobile] = useMediaQuery('(max-width: 768px)')
    const toast = useToast()
    const inputRef = createRef<HTMLInputElement>()
    const uniqueVideoRef = createRef<HTMLVideoElement>()
    const [files, setFiles] = useState<FileProps[]>([])
    const fileFirstRender = useRef(false)
    const {control: controlDefault} = useForm()
    const {fieldState, field} = useController({
        control: input.control || controlDefault,
        rules: input.rules,
        name: name,
        defaultValue: input.defaultValue
    })
    const updateFileList = () => {
        if (inputRef?.current) {
            const dt = new DataTransfer()

            for (const file of files) {
                if (file.file) {
                    dt.items.add(file.file)
                }
            }

            inputRef.current.files = dt.files
        }
    }

    const onSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
        if (e?.target?.files && e?.target?.files?.length > 0) {
            const urls: FileProps[] = []

            Array.from(e?.target?.files).forEach((file) => {
                urls.push({
                    url: URL.createObjectURL(file),
                    file,
                    uuid: uuid()
                })
            })

            setFiles(prev => {
                let newFiles = urls
                if (multiple) {
                    newFiles = [...prev, ...urls]
                }
                if (newFiles.length === 1) {
                    field.onChange(newFiles[0])
                } else {
                    field.onChange(newFiles)
                }
                return newFiles
            })
        }
        updateFileList()
    }
    const isArray = function(a: any) {
        return (!!a) && (a.constructor === Array);
    };
    useEffect(() => {
        if (fileFirstRender.current) {
            return
        }
        if (field?.value) {
            setFiles((prev) => {
                if (field.value.length) {
                    if (field.value.length < 1) {
                        return []
                    }
                    return field.value.map((item: FileProps) => {
                        return {
                            ...item,
                            uuid: uuid()
                        }
                    })
                } else {
                    if (isArray(field.value)) {
                        return []
                    }
                    return [{
                        ...field.value,
                        uuid: uuid()
                    }]
                }
            })
            fileFirstRender.current = true
        }
    }, [field])
    useEffect(() => {
        if (!field?.value && files?.length > 0) {
            setFiles([])
        }
    }, [field])

    const handleRemoveFile = (uuid: string) => {
        setFiles(prev => {
            if (onRemoveFile) {
                onRemoveFile(prev.find(item => item.uuid === uuid)!)
            }
            const newFiles = prev.filter(item => item.uuid !== uuid)
            field.onChange(newFiles.length === 1 ? newFiles[0] : newFiles.length === 0 ? undefined : newFiles)
            return newFiles
        })
    }


    useEffect(() => {
        updateFileList()
    }, [files])

    useEffect(() => {
        if (uniqueVideoRef?.current) {
            uniqueVideoRef.current.load()
        }
    }, [uniqueVideoRef])

    console.log({files})
    return (
        <>
            <label className={`flex flex-col ${labelClassName ? labelClassName : ``}`}>
                {label && (
                    <Text
                        className={"mb-2 whitespace-nowrap overflow-ellipsis overflow-hidden max-w-full"}>{label}</Text>
                )}
                <ChakraUiInput
                    {...input}
                    {...field}
                    ref={inputRef}
                    value={input.value}
                    type={"file"}
                    multiple={multiple}
                    className={`!border-gray-300 p-1 ${!!input.className && input.className} placeholder:text-black dark:placeholder:text-white ${fieldState?.error && `!border-red-500`}`}
                    onChange={(e) => {
                        if (input.onChange) {
                            input.onChange(e)
                        }
                        onSelectFile(e)
                    }}
                />
                {fieldState?.error?.message && (
                    <Text className={"mt-1 line-clamp-1 !text-[#E53E3E] text-sm"}>{fieldState?.error?.message}</Text>
                )}
            </label>
            {files?.length > 0 && (
                <SimpleGrid columns={isMobile ? 1 : 2} spacing={2} className={`border border-gray-300 rounded-md p-2 ${labelClassName ? labelClassName : ``} ${fieldState?.error && `!border-red-500`}`}>
                    {files?.map((file, key) => {
                        return (
                            <Box key={key}
                                 className={`bg-gray-300 dark:bg-neutral-800 rounded-md flex justify-center relative`}>
                                <Flex className={`items-center gap-2 p-2`}>
                                    <FaFileAlt size={30} />
                                    <Text className={`!text-sm`}>{file.file?.name ?? file?.name}</Text>
                                </Flex>
                                <Flex gap={2} className={`absolute right-2 top-2`}>
                                        <AiFillCloseCircle className={`cursor-pointer`} onClick={() => handleRemoveFile(file.uuid)}/>
                                </Flex>
                            </Box>
                        )
                    })
                    }
                </SimpleGrid>
            )}
        </>
    )
}