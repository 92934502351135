import {useNavigate, useParams} from "react-router-dom";
import {Accordion} from "../../../components/Accordion";
import {Control, useForm} from "react-hook-form";
import {AffiliateBox} from "../../../components/FormBox/AffiliateBox";
import React, {useEffect, useState} from "react";
import {AddressProps, AffiliateProps} from "../../../types";
import {createAddress, getAddressById} from "../../../services/address";
import {createAffiliate, getAffiliateById, updateAffiliate} from "../../../services/affiliates";
import {Box, Flex, Spinner, useDisclosure, useToast} from "@chakra-ui/react";
import {CancelItemBox} from "../../../components/CancelItemBox";
import {Dialog} from "../../../components/Dialog";
import {Container} from "../../../components/Container";
import {Button} from "../../../components/Button";
import {PasswordBox} from "../../../components/FormBox/PasswordBox";
import {formatSelectsToPayload} from "../../../helpers";
import {useUser} from "../../../hooks/useUser";

export function AffiliateCreateAndEdit() {
    const [isSaving, setIsSaving] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const {user, storeUser} = useUser()

    const {onOpen: saveDialogOnOpen, isOpen: saveDialogIsOpen, onClose: saveDialogOnClose} = useDisclosure()
    const navigate = useNavigate()
    const toast = useToast()
    const {
        control: affiliateControl,
        watch: affiliateWatch,
        getValues: affiliateGetValues,
        reset: affiliateReset,
        resetField: affiliateResetField,
        trigger: affiliateTrigger,
        setError: affiliateSetError
    } = useForm<Partial<AffiliateProps>>()
    const params = useParams()
    const affiliateId = Number(params.affiliateId)

    const getAffiliateData = async () => {
        setIsLoading(true)
        const response = await getAffiliateById(affiliateId)
        setIsLoading(false)
        affiliateReset({
            ...response.data?.affiliate,
            permanence_allowance: {
                value: response.data?.affiliate?.permanence_allowance,
                label: response.data?.affiliate?.permanence_allowance === 1 ? `Sim` : `Não`
            }
        })
    }
    useEffect(() => {

        if (affiliateId) {
            console.log("user", user())
            if (!user()?.admin && Number(affiliateId !== user()?.id)) {
                navigate('/')
            }
            getAffiliateData()
        } else {
            setIsLoading(false)
        }
    }, [])


    const handleSave = async () => {
        if (!await affiliateTrigger()) {
            return
        }
        const affiliatePayload = formatSelectsToPayload(affiliateGetValues())

        if(affiliatePayload?.password !== affiliatePayload?.password_repeat) {
            affiliateSetError(`password`, {type: `value`, message: `As senhas devem ser iguais`})
            affiliateSetError(`password_repeat`, {type: `value`, message: `As senhas devem ser iguais`})
            return
        }

        let affiliateResponse: any
        setIsSaving(true)
        if (affiliateId) {
            affiliateResponse = await updateAffiliate(affiliateId, {...affiliatePayload})
        } else {
            affiliateResponse = await createAffiliate({...affiliatePayload})
        }
        if (affiliateResponse.status !== 201 && affiliateResponse.status !== 200) {
            console.log(affiliateResponse.data)
            setIsSaving(false)
            return toast({
          position: 'top-right',
                status: "error",
                title: "Erro ao salvar afiliado",
                description: affiliateResponse.data.message || "Houve um erro salvar o afiliado, por favor tente novamente",
                isClosable: true
            })
        }
        setIsSaving(false)

        if (!user()?.admin) {
            navigate(`/afiliados/${user()?.id}`)
        } else {
            navigate('/afiliados')
        }
        return toast({
          position: 'top-right',
            status: "success",
            title: "Afiliado salvo com sucesso",
            description: affiliateResponse.data.message || "O afiliado foi salvo com sucesso",
            isClosable: true
        })

    }
    const handleCancel = () => {
        return navigate("/afiliados")
    }

    return (
        <>
            <Dialog
                onAccept={handleSave}
                isOpen={saveDialogIsOpen}
                onClose={saveDialogOnClose}
                title={"Salvar afiliado"}
                description={"Ao confirmar, o afiliado será salvo"}
            />
            <CancelItemBox title={"Voltar para lista de afiliados"} onClick={() => navigate('/afiliados')} />
            <Accordion title={"Dados do afiliado"} defaultExpanded={true}>
                <AffiliateBox isLoading={isLoading} reset={affiliateReset} watch={affiliateWatch} control={affiliateControl as Control<any>} isEditing={!!affiliateId} />
            </Accordion>
            <Accordion title={"Senha do afiliado"} defaultExpanded={false}>
                <PasswordBox control={affiliateControl as Control<any>} isEditing={!!affiliateId} />
            </Accordion>
            <Container>
                <Flex className={`col-span-2 gap-2 justify-end`}>
                    <Button className={`!bg-neutral-800 !text-white`} onClick={() => handleCancel()}>Voltar</Button>
                    <Button className={`!bg-primary !text-white`} onClick={() => saveDialogOnOpen()}> Salvar {isSaving && <Spinner size={"sm"} className={`ml-1`}/>}</Button>
                </Flex>
            </Container>
        </>
    )
}