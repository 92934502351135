import {useNavigate, useParams} from "react-router-dom";
import {Accordion} from "../../../components/Accordion";
import {Control, useForm} from "react-hook-form";
import {BoardBox} from "../../../components/FormBox/BoardBox";
import React, {useEffect, useState} from "react";
import {AddressProps, BoardProps} from "../../../types";
import {createAddress, getAddressById} from "../../../services/address";
import {createBoard, getBoardById, updateBoard} from "../../../services/boards";
import {Badge, Box, Flex, Spinner, useDisclosure, useToast} from "@chakra-ui/react";
import {CancelItemBox} from "../../../components/CancelItemBox";
import {Dialog} from "../../../components/Dialog";
import {Container} from "../../../components/Container";
import {Button} from "../../../components/Button";
import {PasswordBox} from "../../../components/FormBox/PasswordBox";
import {formatSelectsToPayload} from "../../../helpers";
import {serialize} from "object-to-formdata";
import {v4 as uuid} from "uuid";
import {storageURL} from "../../../services/axios";
import {MediaProps} from "../../../components/MediaInput";

export function BoardCreateAndEdit() {
    const [isSaving, setIsSaving] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    const {onOpen: saveDialogOnOpen, isOpen: saveDialogIsOpen, onClose: saveDialogOnClose} = useDisclosure()
    const navigate = useNavigate()
    const toast = useToast()
    const {
        control: boardControl,
        watch: boardWatch,
        getValues: boardGetValues,
        reset: boardReset,
        resetField: boardResetField,
        trigger: boardTrigger
    } = useForm<Partial<BoardProps>>({
        defaultValues: {
            active: false
        }
    })
    const params = useParams()
    const boardId = Number(params.boardId)

    const getBoardData = async () => {
        setIsLoading(true)
        const response = await getBoardById(boardId)
        setIsLoading(false)
        boardReset({
            ...response.data?.board,
            image: {
                uuid: uuid(),
                url: `${storageURL}/${response.data?.board?.image}`,
                type: `image`

            } as MediaProps,
            active: !!response?.data?.board?.active
        })
    }
    useEffect(() => {

        if (boardId) {
            getBoardData()
        } else {
            setIsLoading(false)
        }
    }, [])


    const handleSave = async () => {
        if (!await boardTrigger()) {
            return
        }
        let boardPayload = formatSelectsToPayload(boardGetValues())

        if (!boardPayload?.image?.size) {
            boardPayload.image = undefined
        }

        boardPayload = serialize(boardPayload, {
            booleansAsIntegers: true
        })
        let boardResponse: any
        setIsSaving(true)
        if (boardId) {
            boardResponse = await updateBoard(boardId, boardPayload)
        } else {
            boardResponse = await createBoard(boardPayload)
        }
        if (boardResponse.status !== 201 && boardResponse.status !== 200) {
            setIsSaving(false)
            return toast({
          position: 'top-right',
                status: "error",
                title: "Erro ao salvar diretoria",
                description: boardResponse.data.message || "Houve um erro salvar diretoria, por favor tente novamente",
                isClosable: true
            })
        }
        setIsSaving(false)

        navigate('/diretoria')
        return toast({
          position: 'top-right',
            status: "success",
            title: "Diretoria salvo com sucesso",
            description: boardResponse.data.message || "O diretoria foi salvo com sucesso",
            isClosable: true
        })

    }
    const handleCancel = () => {
        return navigate("/diretoria")
    }

    return (
        <>

            <Dialog
                onAccept={handleSave}
                isOpen={saveDialogIsOpen}
                onClose={saveDialogOnClose}
                title={"Salvar diretoria"}
                description={"Ao confirmar, o diretoria será salvo"}
            />
            <CancelItemBox title={"Voltar para lista de diretorias"} onClick={() => navigate('/diretoria')} />
            <Accordion title={`Dados do diretoria`} defaultExpanded={true}>
                <BoardBox control={boardControl as Control<any>} watch={boardWatch} reset={boardReset} isLoading={isLoading} isEditing={!!boardId} />
            </Accordion>
            <Container>
                <Flex className={`col-span-2 gap-2 justify-end`}>
                    <Button className={`!bg-neutral-800 !text-white`} onClick={() => handleCancel()}>Voltar</Button>
                    <Button className={`!bg-primary !text-white`} onClick={() => saveDialogOnOpen()}> Salvar {isSaving && <Spinner size={"sm"} className={`ml-1`}/>}</Button>
                </Flex>
            </Container>
        </>
    )
}