import {
	Popover as ChakraUiIPopover,
	PopoverTrigger,
	PopoverContent,
	PopoverHeader,
	PopoverBody,
	PopoverFooter,
	PopoverArrow,
	PopoverCloseButton,
	PopoverAnchor, PopoverProps as ChakraUiPopoverProps,
} from '@chakra-ui/react'
import {ReactNode} from "react";

type PopoverProps = ChakraUiPopoverProps & {
    header: ReactNode
    body: ReactNode
    children: ReactNode
    triggerClassName?: string
}
export function Popover({children, header, body, ...rest}: PopoverProps) {
	return (
		<ChakraUiIPopover size={"xl"}>
			<PopoverTrigger>
				{children}
			</PopoverTrigger>
			<PopoverContent
				_active={{outline: `none !important`}}
				_focus={{outline: `none !important`}}
				_focusVisible={{outline: `none !important`}}
				className={`bg-gray-100 dark:bg-neutral-900 p-1 rounded-[16px] min-w-[250px] relative drop-shadow `}>
				<PopoverHeader className={`bg-blue_primary h3-semibold-uppercase text-center p-1 text-black rounded-full !border-none`}>{header}</PopoverHeader>
				<PopoverBody className={`p-3`}>{body}</PopoverBody>
				<PopoverArrow className={`!bg-white dark:!bg-neutral-900`} />
			</PopoverContent>
		</ChakraUiIPopover>
	)
}