import {api} from "./axios";

export const getBalanceSheets = async (params?: any) => {
    return await api.get(`/balance-sheet-list`, {
        params
    }).catch(({response}) => {
        return response
    })
}
export const createBalanceSheet = async (payload?: any) => {
    return await api.post(`/balance-sheet-register`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const deleteBalanceSheet = async (balanceSheetId: number) => {
    return await api.delete(`/balance-sheet-delete/${balanceSheetId}`, {
    }).catch(({response}) => {
        return response
    })
}