import {Login} from "../views/Login";
import {RouteProps} from "../types/router";
import {Users} from "../views/Users";
import {UserCreateAndEdit} from "../templates/Users/CreateAndEdit";
import {Notices} from "../views/Notices";
import {Banners} from "../views/Banners";
import {Events} from "../views/Events";
import {Agreements} from "../views/Agreements";
import {Boards} from "../views/Boards";
import {Affiliates} from "../views/Affiliates";
import {Assemblies} from "../views/Assemblies";
import {Home} from "../views/Home";
import {BalanceSheets} from "../views/BalanceSheets";

export const routes: RouteProps[] = [
    {
        path: "/",
        element: <Home />
    },
    {
        path: "inicio",
        element: <Home />
    },
    {
        path: "login",
        element: <Login />,
        empty: true
    },
    {
        path: "usuarios",
        element: <Users />
    },
    {
        path: "usuarios/cadastro",
        element: <Users />
    },
    {
        path: "usuarios/:userId",
        element: <Users />
    },
    {
        path: "noticias",
        element: <Notices />
    },
    {
        path: "noticias/cadastro",
        element: <Notices />
    },
    {
        path: "noticias/:noticeId",
        element: <Notices />
    },
    {
        path: "banners",
        element: <Banners />
    },
    {
        path: "banners/cadastro",
        element: <Banners />
    },
    {
        path: "banners/:bannerId",
        element: <Banners />
    },
    {
        path: "eventos",
        element: <Events />
    },
    {
        path: "eventos/cadastro",
        element: <Events />
    },
    {
        path: "eventos/:eventId",
        element: <Events />
    },
    {
        path: "eventos/:eventId/convidados",
        element: <Events />
    },
    {
        path: "cliente/eventos/:eventSlug",
        element: <Events />
    },
    {
        path: "convenios",
        element: <Agreements />
    },
    {
        path: "convenios/cadastro",
        element: <Agreements />
    },
    {
        path: "convenios/:agreementId",
        element: <Agreements />
    },
    {
        path: "diretoria",
        element: <Boards />
    },
    {
        path: "diretoria/cadastro",
        element: <Boards />
    },
    {
        path: "diretoria/:boardId",
        element: <Boards />
    },
    {
        path: "afiliados",
        element: <Affiliates />
    },
    {
        path: "afiliados/cadastro",
        element: <Affiliates />
    },
    {
        path: "afiliados/:affiliateId",
        element: <Affiliates />
    },
    {
        path: "assembleias",
        element: <Assemblies />
    },
    {
        path: "assembleias/cadastro",
        element: <Assemblies />
    },
    {
        path: "assembleias/:assemblyId",
        element: <Assemblies />
    },
    {
        path: "cliente/prestacao-de-contas",
        element: <BalanceSheets />
    },
    {
        path: "cliente/assembleias",
        element: <Assemblies />
    },
    {
        path: "cliente/assembleias/:assemblySlug",
        element: <Assemblies />
    },
    {
        path: "cliente/assembleias",
        element: <Assemblies />
    },
    {
        path: "prestacao-de-contas",
        element: <BalanceSheets />
    },
    {
        path: "prestacao-de-contas/cadastro",
        element: <BalanceSheets />
    },
]
