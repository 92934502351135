import { EditorState as TextEditorState, ContentState, convertFromHTML, convertFromRaw, convertToRaw,  } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs'
import { Editor as ReactDraftTextEditor } from 'react-draft-wysiwyg';
import {Control, useController, UseControllerProps, useForm} from "react-hook-form";
import {Text} from "../Text"
import {useEffect, useState} from "react";
import './styles.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
type TextEditorProps = any & {
    label?: string
    labelClassName?: string
    name?: string
    format?: 'html' | 'text'
    onChange?: (value: string) => void

}
export function TextEditor({label, labelClassName, name = "", format = "html", onChange, ...textEditor}: TextEditorProps) {
    const {control: controlDefault} = useForm()
    const [editorState, setEditorState] = useState(TextEditorState.createEmpty())
    const [defaultValue, setDefaultValue] = useState(false)
    const {fieldState, field} = useController({
        control: textEditor.control || controlDefault,
        rules: textEditor.rules,
        name: name,
        defaultValue: textEditor.defaultValue
    })

    useEffect(() => {
        if (field.value) {
            const draft = htmlToDraft(field.value)
            const content = ContentState.createFromBlockArray(draft?.contentBlocks)
            const state = TextEditorState.createWithContent(content);
            if (!defaultValue) {
                setDefaultValue(true)
                setEditorState(state)
            }
            console.log({state})
        }


    }, [field.value])

    return (
        <>
            <label className={`flex flex-col ${labelClassName ? labelClassName : ``}`}>
                {label && (
                    <Text className={"mb-2 whitespace-nowrap overflow-ellipsis overflow-hidden max-w-full"}>{label}</Text>
                )}
                <ReactDraftTextEditor
                    {...textEditor}
                    {...field}
                    // value={}
                    editorState={editorState}
                    onEditorStateChange={(e) => {
                        setEditorState(e)
                        const rawContentState = convertToRaw(e.getCurrentContent());

                        const html = draftToHtml(
                            rawContentState
                        )
                        const virtualDiv = document.createElement('div')
                        virtualDiv.innerHTML = html
                        const text = virtualDiv.innerText
                        virtualDiv.remove()

                        const formattedValue = format === "html" ? html : text
                        if (textEditor.onChange) {
                            textEditor.onChange({
                                target: {
                                    value: formattedValue
                                }
                            })
                        }
                        field.onChange({
                            target: {
                                value: formattedValue
                            }
                        })
                        if (onChange) {
                            onChange(formattedValue)
                        }
                    }}
                    className={`!border-gray-300 p-1 ${!!textEditor.className && textEditor.className} placeholder:text-black dark:placeholder:text-white`}
                    wrapperClassName={`!flex !flex-col !border-gray-300 border !rounded-md [&>*]:!h-auto !cursor-auto`}
                    editorClassName={`!mb-0 px-4 !text-black min-h-[250px] max-h-[400px] bg-white rounded-b-md`}
                    toolbarClassName={`!mb-0 [span]:!text-black !rounded-t-md !border-0 !border-b `}
                    onChange={(e) => {
                        // if (textEditor.onChange) {
                        //     textEditor.onChange(e)
                        // }
                        // field.onChange(e)
                        // const state = convertFromRaw(e)
                        //doesnt remove this on change call
                    }}
                    {...(fieldState?.error ? {isInvalid: true} : {})}
                />
            </label>
        </>
    )
}