import {useNavigate, useParams} from "react-router-dom";
import {Accordion} from "../../../components/Accordion";
import {Control, useForm} from "react-hook-form";
import {BalanceSheetBox} from "../../../components/FormBox/BalanceSheetBox";
import React, {useEffect, useState} from "react";
import {AddressProps, BalanceSheetProps} from "../../../types";
import {createAddress, getAddressById} from "../../../services/address";
import {createBalanceSheet} from "../../../services/balance-sheets";
import {Badge, Box, Flex, Spinner, useDisclosure, useToast} from "@chakra-ui/react";
import {CancelItemBox} from "../../../components/CancelItemBox";
import {Dialog} from "../../../components/Dialog";
import {Container} from "../../../components/Container";
import {Button} from "../../../components/Button";
import {PasswordBox} from "../../../components/FormBox/PasswordBox";
import {serialize} from "object-to-formdata";
import {formatSelectsToPayload} from "../../../helpers";
import {v4 as uuid} from "uuid";
import {storageURL} from "../../../services/axios";
import {MediaProps} from "../../../components/MediaInput";

export function BalanceSheetCreateAndEdit() {
    const [isSaving, setIsSaving] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    const {onOpen: saveDialogOnOpen, isOpen: saveDialogIsOpen, onClose: saveDialogOnClose} = useDisclosure()
    const navigate = useNavigate()
    const toast = useToast()
    const {
        control: balanceSheetControl,
        watch: balanceSheetWatch,
        getValues: balanceSheetGetValues,
        reset: balanceSheetReset,
        resetField: balanceSheetResetField,
        trigger: balanceSheetTrigger
    } = useForm<Partial<BalanceSheetProps>>({
        defaultValues: {
            active: false,
            highlight: false,
        }
    })
    const params = useParams()

    useEffect(() => {
            setIsLoading(false)
    }, [])


    const handleSave = async () => {
        if (!await balanceSheetTrigger()) {
            return
        }
        let balanceSheetPayload = balanceSheetGetValues()

        balanceSheetPayload.date = balanceSheetPayload.year.value

        balanceSheetPayload = serialize(balanceSheetPayload, {
            booleansAsIntegers: true
        })
        setIsSaving(true)
        const balanceSheetResponse = await createBalanceSheet(balanceSheetPayload)
        if (balanceSheetResponse.status !== 201 && balanceSheetResponse.status !== 200) {
            setIsSaving(false)
            return toast({
          position: 'top-right',
                status: "error",
                title: "Erro ao salvar prestação de conta",
                description: balanceSheetResponse.data.message || "Houve um erro salvar prestação de conta, por favor tente novamente",
                isClosable: true
            })
        }
        setIsSaving(false)

        navigate('/prestacao-de-contas')
        return toast({
          position: 'top-right',
            status: "success",
            title: "Prestação de contas salva com sucesso",
            description: balanceSheetResponse.data.message || "A prestação de conta foi salva com sucesso",
            isClosable: true
        })

    }
    const handleCancel = () => {
        return navigate("/convenios")
    }

    return (
        <>

            <Dialog
                onAccept={handleSave}
                isOpen={saveDialogIsOpen}
                onClose={saveDialogOnClose}
                title={"Salvar prestação de conta"}
                description={"Ao confirmar, o prestação de conta será salvo"}
            />
            <CancelItemBox title={"Voltar para lista de prestação de contas"} onClick={() => navigate('/prestacao-de-contas')} />
            <Accordion title={`Dados do prestação de conta`} defaultExpanded={true}>
                <BalanceSheetBox isLoading={isLoading} reset={balanceSheetReset} watch={balanceSheetWatch} control={balanceSheetControl as Control<any>} />
            </Accordion>
            <Container>
                <Flex className={`col-span-2 gap-2 justify-end`}>
                    <Button className={`!bg-neutral-800 !text-white`} onClick={() => handleCancel()}>Voltar</Button>
                    <Button className={`!bg-primary !text-white`} onClick={() => saveDialogOnOpen()}> Salvar {isSaving && <Spinner size={"sm"} className={`ml-1`}/>}</Button>
                </Flex>
            </Container>
        </>
    )
}