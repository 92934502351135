import {useNavigate, useParams} from "react-router-dom";
import {Accordion} from "../../../components/Accordion";
import {Control, useForm} from "react-hook-form";
import {EventBox} from "../../../components/FormBox/EventBox";
import React, {useEffect, useState} from "react";
import {AddressProps, EventProps} from "../../../types";
import {createAddress, getAddressById} from "../../../services/address";
import {createEvent, getEventById, updateEvent} from "../../../services/events";
import {Badge, Box, Flex, Spinner, useDisclosure, useToast} from "@chakra-ui/react";
import {CancelItemBox} from "../../../components/CancelItemBox";
import {Dialog} from "../../../components/Dialog";
import {Container} from "../../../components/Container";
import {Button} from "../../../components/Button";
import {PasswordBox} from "../../../components/FormBox/PasswordBox";
import {serialize} from "object-to-formdata";
import {v4 as uuid} from "uuid";
import {storageURL} from "../../../services/axios";
import {MediaProps} from "../../../components/MediaInput";

export function EventCreateAndEdit() {
    const [isSaving, setIsSaving] = useState(false)

    const {onOpen: saveDialogOnOpen, isOpen: saveDialogIsOpen, onClose: saveDialogOnClose} = useDisclosure()
    const navigate = useNavigate()
    const toast = useToast()
    const {
        control: eventControl,
        watch: eventWatch,
        getValues: eventGetValues,
        reset: eventReset,
        resetField: eventResetField,
        trigger: eventTrigger
    } = useForm<Partial<EventProps>>({
        defaultValues: {
            active: false
        }
    })
    const params = useParams()
    const eventId = Number(params.eventId)

    const getEventData = async () => {
        const response = await getEventById(eventId)

        const tables: any[] = []

        let lastChairsNumber = 0
        let firstInteraction = false
        let i = 0

        for (const table of response?.data?.event?.event_tables) {
            if (!firstInteraction) {
                lastChairsNumber = table.chairs
                firstInteraction = true
            }
            if (lastChairsNumber !== table?.chairs) {
                lastChairsNumber = table.chairs
                i++
            }
            console.log(tables,33, table?.chairs, lastChairsNumber, i)
            if (tables[i]?.tables?.length > 0) {
                tables[i] = {
                    chairs: table?.chairs,
                    tables: [
                        ...tables[i]?.tables,
                        {
                            number: table.number
                        }
                    ]
                }
            } else {
                tables[i] = {
                    chairs: table?.chairs,
                    tables: [
                        {
                            number: table.number
                        }
                    ]
                }
            }

        }
        eventReset({
            ...response.data?.event,
            image: {
                uuid: uuid(),
                url: `${storageURL}/${response.data?.event?.image}`,
                type: `image`

            } as MediaProps,
            active: !!response?.data?.event?.active,
            tables: tables?.map(item => {
                return {
                    ...item,
                    numbers: `${item?.tables[0]?.number} - ${item?.tables[item?.tables?.length - 1]?.number}`,
                }
            })
        })
    }
    useEffect(() => {
        if (eventId) {
            getEventData()
        }
    }, [])


    const handleSave = async () => {
        if (!await eventTrigger()) {
            return
        }
        let eventPayload = eventGetValues()

        if (eventPayload?.image?.file) {
            eventPayload.image = eventPayload?.image?.file
        } else {
            eventPayload.image = undefined
        }

        eventPayload = serialize(eventPayload, {
            booleansAsIntegers: true,
            indices: true
        })
        let eventResponse: any
        setIsSaving(true)
        if (eventId) {
            eventResponse = await updateEvent(eventId, eventPayload)
        } else {
            eventResponse = await createEvent(eventPayload)
        }
        if (eventResponse.status !== 201 && eventResponse.status !== 200) {
            setIsSaving(false)
            return toast({
          position: 'top-right',
                status: "error",
                title: "Erro ao salvar evento",
                description: eventResponse.data.message || "Houve um erro salvar evento, por favor tente novamente",
                isClosable: true
            })
        }
        setIsSaving(false)

        navigate('/eventos')
        return toast({
          position: 'top-right',
            status: "success",
            title: "Evento salvo com sucesso",
            description: eventResponse.data.message || "O evento foi salvo com sucesso",
            isClosable: true
        })

    }
    const handleCancel = () => {
        return navigate("/eventos")
    }

    return (
        <>

            <Dialog
                onAccept={handleSave}
                isOpen={saveDialogIsOpen}
                onClose={saveDialogOnClose}
                title={"Salvar evento"}
                description={"Ao confirmar, o evento será salvo"}
            />
            <CancelItemBox title={"Voltar para lista de eventos"} onClick={() => navigate('/eventos')} />
            <Accordion title={<>Dados do evento <Badge>Obs: Uma pasta será criada no servidor para armazenamento da galeria</Badge></> as any} defaultExpanded={true}>
                <EventBox watch={eventWatch} resetField={eventResetField} reset={eventReset} control={eventControl as Control<any>} isEditing={!!eventId} />
            </Accordion>
            <Container>
                <Flex className={`col-span-2 gap-2 justify-end`}>
                    <Button className={`!bg-neutral-800 !text-white`} onClick={() => handleCancel()}>Voltar</Button>
                    <Button className={`!bg-primary !text-white`} onClick={() => saveDialogOnOpen()}> Salvar {isSaving && <Spinner size={"sm"} className={`ml-1`}/>}</Button>
                </Flex>
            </Container>
        </>
    )
}